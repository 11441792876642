.editButton {
  background: none;
  border: none;
  cursor: pointer;
}

.editButton svg {
  width: 20px;
  height: 20px;
}

.editButton svg:hover {
  width: 20px;
  height: 20px;
  filter: brightness(0.5);
}