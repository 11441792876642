.portfolioElement {
  position: relative;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: var(--text-m);
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--border-radius-md);
  transition: background 0.4s ease, border-color 0.4s ease;
}
.drag{
    color: #58585894;
    cursor: grab;
    margin-right: 5px;
  }

.portfolioElement > * {
  font-size: var(--text-xs);
  font-weight: 400;
  margin: 0;
  min-width: max-content;
  overflow: hidden;
}
.disabledEditIcon{
  color: #949494;
  cursor: none;
}
.deleteIcon {
  cursor: pointer;
  background: white;
}

.IconsWrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 5px;
  width: 25%;
  background-color: white;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  justify-content: space-evenly;
}

.portfolioElement:hover .deleteIcon {
  display: block;
}

.portfolioElement.selected {
  border-color: var(--color-primary);
  background: rgb(255, 127, 0);
  background: linear-gradient(90deg, rgba(255, 127, 0, 0.75) 0%, rgba(255, 255, 255, 1) 85%);
}