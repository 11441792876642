.container{
   overflow-y: scroll;
    height: calc(75vh - 65px);
    padding: var(--space-m);
}

.elementWrapper {
  position: relative;
  padding:0 10px;
  margin-bottom: 10px;
  border-radius: var(--border-radius-lg);
  transition: box-shadow 0.3s ease;
   border: 1px solid transparent;
}

.elementWrapper:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
.deleteIconWrapper{
   position: absolute;
  top: -10px;
  right: 10px;
  background-color: #f0f0f0;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.3s;
  box-shadow: 0px 0px 10px rgba(255, 128, 0, 0.5);
  border: solid 0.5px var(--color-primary);
}
.deleteIconWrapper:hover {
  background-color: rgba(255, 128, 0, 1);
}
.deleteIconWrapper:hover > .deleteIcon {
  color: #fff;
}
.editIconWrapper {
  z-index: 3;
  position: absolute;
  top: -10px;
  right: 40px;
  background-color: #f0f0f0;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.3s;
  box-shadow: 0px 0px 10px rgba(255, 128, 0, 0.5);
  border: solid 0.5px var(--color-primary);
}

.editIconWrapper:hover {
  background-color: rgba(255, 128, 0, 1);
}
.editIconWrapper:hover > .editIcon {
  color: #fff;
}

.editIcon {
  color: #333;
  font-size: 12px;
}

.deleteIcon {
  color: #333;
  font-size: 12px;
}
.selected {
  border-color: var(--color-primary);
  box-shadow: 0px 0px 10px rgba(255, 128, 0, 0.5);
}