.container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .detail {
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .cost, .timeline, .workers {
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
  }
  
  .cost {
    background-color: #e0f7fa;
    color: #00695c;
  }
  
  .timeline {
    background-color: #fff3e0;
    color: #ff6f00;
  }
  
  .workers {
    background-color: #ffebee;
    color: #c62828;
  }
  
  .detailsButton {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .detailsButton:hover {
    background-color: #e68900;
  }
  