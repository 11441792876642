.inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-md);
  }
  
  .label {
    margin-bottom: var(--spacing-xs);
    font-size: var(--font-size-md);
    color: var(--color-text);
  }
  
  .input {
    padding: var(--spacing-sm);
    border: 1px solid transparent;
    background-color: var(--color-primary--light)!important;
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-md);
    color: var(--color-text);
    background-color: var(--color-white);
    transition: border-color var(--transition-fast);
  }
  
  .input:focus {
    border-color: var(--color-primary);
    outline: none;
  }
  .errorMessage {
    font-size: 12px;
    color: red;
  }