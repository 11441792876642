.wrapper {
    border: solid 1px var(--color-primary--light);
    border-radius: var(--border-radius-lg);
    width: -webkit-fill-available;
    outline-color: var(--color-primary--medium);
    padding: var(--spacing-md);
}

.wrapper:focus-visible {
    color: var(--color-text);           
}
.wrapper:focus-within::placeholder {
    color: var(--color-text--medium);
}



.save {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding:  calc(var(--space-xs) * 0.6) var(--space-xs);
    width: 150px;
    border-radius: var(--border-radius-md);
    height: fit-content;
    transition: background-color 0.3s;
    font-size: var(--text-s);
}

.save:hover {
    background-color: var(--color-primary--medium);
}