.name{
    margin: var(--space-s) 0;
    border: none;
    font-size: var(--text-l);
    width: inherit;
    text-align: center;
}
.load {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.name:focus-visible{
    outline: none;
}

.skip {
    color: var(--text-base);
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    font-size: var(--text-s);
}