.container {
  padding: var(--space-xs);
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
  width: 100%;
}

  .load {
    padding: var(--space-xs);
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 10px;
    text-align: left;
  }
  
  .table th {
    color: var(--color-text-medium);
    font-size: 14px;
    font-weight: 700;
  }
  
  .actions {
    display: flex;
    gap: 10px;
  }
  
  .empty{
    text-align: center!important;
    padding: var(--space-l) 0!important;
    font-size: 16px;
    font-weight: 600;
  }