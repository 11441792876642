.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-md);
}

.label {
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-md);
  color: var(--color-text);
}

.input {
  padding: var(--spacing-sm);
  border: 1px solid transparent;
  border-radius: var(--border-radius-md);
      width: 100%;
  font-size: var(--font-size-md);
  color: var(--color-text);
  background-color: var(--color-primary--light);
  transition: border-color var(--transition-fast);
}

.input:focus {
  border-color: var(--color-primary);
  outline: none;
}

.errorMessage {
  font-size: 12px;
  color: red;
  margin-top: var(--spacing-xs);
}

.passwordField {
  position: relative;
  display: flex;
  align-items: center;
}

.eyeIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

.iconBefore .eyeIcon {
  left: 10px;
  right: auto;
}

.iconBefore .input {
  padding-left: 2.5rem; /* Adjust for the icon on the left */
}

.iconAfter .input {
  padding-right: 2.5rem; /* Adjust for the icon on the right */
}
