.formOptions {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.formOptions > a{
    display: flex;
    align-items: end;
    justify-content: flex-end;
    text-decoration: none;
    color: var(--coloe-text);
    cursor: pointer;
}
.submit {
    margin-top: var(--space-xs);
}