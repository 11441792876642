/* src/styles/utility.css */
@import './variables.css';



/* Grid Container */
.grid {
  display: grid;
}

.width--full {
  inline-size: 100%;
  max-inline-size: 100%;
}

.pad--xs {
  padding: 1.896rem;
  padding: calc(1.0666666667rem + (0.2666666667 * ((100vw - 32rem) / 112)));
  padding: clamp(1.0666666667rem, calc(0.2380952381vw + 0.9904761905rem), 1.3333333333rem);
}
.pad--s {
  padding: 2.133rem;
  padding: calc(1.6rem + (0.4 * ((100vw - 32rem) / 112)));
  padding: clamp(1.6rem, calc(0.3571428571vw + 1.4857142857rem), 2rem);
}
.pad--m {
  padding: 2.4rem;
  padding: calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.pad--l {
  padding: 2.7rem;
  padding: calc(3.1992rem + (1.3008 * ((100vw - 32rem) / 112)));
  padding: clamp(3.1992rem, calc(1.1614285714vw + 2.8275428571rem), 4.5rem);
}
.pad--xl {
  padding: 3.038rem;
  padding: calc(4.2645336rem + (2.4854664 * ((100vw - 32rem) / 112)));
  padding: clamp(4.2645336rem, calc(2.2191664286vw + 3.5544003429rem), 6.75rem);
}
.pad--xxl {
  padding: 3.42rem;
  padding: calc(5.6846232888rem + (4.4403767112 * ((100vw - 32rem) / 112)));
  padding: clamp(5.6846232888rem, calc(3.9646220636vw + 4.4159442285rem), 10.125rem);
}
.pad--none {
  padding: 0 !important;
}

/* Basic Grid Layouts */
.grid--1 { grid-template-columns: repeat(1, 1fr); }
.grid--2 { grid-template-columns: repeat(2, 1fr); }
.grid--3 { grid-template-columns: repeat(3, 1fr); }
.grid--4 { grid-template-columns: repeat(4, 1fr); }
.grid--5 { grid-template-columns: repeat(5, 1fr); }
.grid--6 { grid-template-columns: repeat(6, 1fr); }
.grid--7 { grid-template-columns: repeat(7, 1fr); }
.grid--8 { grid-template-columns: repeat(8, 1fr); }
.grid--9 { grid-template-columns: repeat(9, 1fr); }
.grid--10 { grid-template-columns: repeat(10, 1fr); }
.grid--11 { grid-template-columns: repeat(11, 1fr); }
.grid--12 { grid-template-columns: repeat(12, 1fr); }

/* Grid Layouts with Column Spans */
.grid--1-2 { grid-template-columns: repeat(1, 1fr) repeat(2, 1fr); }
.grid--1-3 { grid-template-columns: repeat(1, 1fr) repeat(3, 1fr); }
.grid--2-3 { grid-template-columns: repeat(2, 1fr) repeat(3, 1fr); }
.grid--2-4 { grid-template-columns: repeat(2, 1fr) repeat(4, 1fr); }
.grid--3-4 { grid-template-columns: repeat(3, 1fr) repeat(4, 1fr); }
.grid--4-6 { grid-template-columns: repeat(4, 1fr) repeat(6, 1fr); }

/* Grid Gaps */
.grid-gap-xs { gap: var(--spacing-xs); }
.grid-gap-sm { gap: var(--spacing-sm); }
.grid-gap-md { gap: var(--spacing-md); }
.grid-gap-lg { gap: var(--spacing-lg); }
.grid-gap-xl { gap: var(--spacing-xl); }
.grid-gap-xxl { gap: var(--spacing-xxl); }

/* Responsive Grid Utilities */
@media (max-width: 576px) { /* XS */
  .grid--xs-1 { grid-template-columns: repeat(1, 1fr); }
  .grid--xs-2 { grid-template-columns: repeat(2, 1fr); }
  .grid--xs-3 { grid-template-columns: repeat(3, 1fr); }
  .grid--xs-4 { grid-template-columns: repeat(4, 1fr); }
  .grid--xs-5 { grid-template-columns: repeat(5, 1fr); }
  .grid--xs-6 { grid-template-columns: repeat(6, 1fr); }
  .grid--xs-1-2 { grid-template-columns: repeat(1, 1fr) repeat(2, 1fr); }
  .grid--xs-2-3 { grid-template-columns: repeat(2, 1fr) repeat(3, 1fr); }
}

@media (min-width: 576px) and (max-width: 768px) { /* SM */
  .grid--s-1 { grid-template-columns: repeat(1, 1fr); }
  .grid--s-2 { grid-template-columns: repeat(2, 1fr); }
  .grid--s-3 { grid-template-columns: repeat(3, 1fr); }
  .grid--s-4 { grid-template-columns: repeat(4, 1fr); }
  .grid--s-5 { grid-template-columns: repeat(5, 1fr); }
  .grid--s-6 { grid-template-columns: repeat(6, 1fr); }
  .grid--s-1-2 { grid-template-columns: repeat(1, 1fr) repeat(2, 1fr); }
  .grid--s-2-3 { grid-template-columns: repeat(2, 1fr) repeat(3, 1fr); }
}

@media (min-width: 768px) and (max-width: 992px) { /* MD */
  .grid--m-1 { grid-template-columns: repeat(1, 1fr); }
  .grid--m-2 { grid-template-columns: repeat(2, 1fr); }
  .grid--m-3 { grid-template-columns: repeat(3, 1fr); }
  .grid--m-4 { grid-template-columns: repeat(4, 1fr); }
  .grid--m-5 { grid-template-columns: repeat(5, 1fr); }
  .grid--m-6 { grid-template-columns: repeat(6, 1fr); }
  .grid--m-1-2 { grid-template-columns: repeat(1, 1fr) repeat(2, 1fr); }
  .grid--m-2-3 { grid-template-columns: repeat(2, 1fr) repeat(3, 1fr); }
}

@media (min-width: 992px) { /* LG */
  .grid--l-1 { grid-template-columns: repeat(1, 1fr); }
  .grid--l-2 { grid-template-columns: repeat(2, 1fr); }
  .grid--l-3 { grid-template-columns: repeat(3, 1fr); }
  .grid--l-4 { grid-template-columns: repeat(4, 1fr); }
  .grid--l-5 { grid-template-columns: repeat(5, 1fr); }
  .grid--l-6 { grid-template-columns: repeat(6, 1fr); }
  .grid--l-1-2 { grid-template-columns: repeat(1, 1fr) repeat(2, 1fr); }
  .grid--l-2-3 { grid-template-columns: repeat(2, 1fr) repeat(3, 1fr); }
}


/* File: automatic-oxygen.css - Version: 2.6.2 - Generated: 2023-12-27 14:39:24 */
body {
  font-size: 1.8remrem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
  line-height: 1.5;
  margin-inline: auto;
}
.ct-section-inner-wrap {
  padding: 6rem 2.4rem;
  padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.oxy-header-container {
  padding-inline: clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
*:focus, button:focus {
  outline-style: solid;
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
}
*:focus:not(:focus-visible), button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}
/*Disables focus on finger touch for mobile devices*/
:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}
*:focus.focus--action {
  --focus-color: var(--action);
}
.focus--action *:focus {
  --focus-color: var(--action);
}
*:focus.focus--action-hover {
  --focus-color: var(--action-hover);
}
.focus--action-hover *:focus {
  --focus-color: var(--action-hover);
}
*:focus.focus--action-ultra-light {
  --focus-color: var(--action-ultra-light);
}
.focus--action-ultra-light *:focus {
  --focus-color: var(--action-ultra-light);
}
*:focus.focus--action-light {
  --focus-color: var(--action-light);
}
.focus--action-light *:focus {
  --focus-color: var(--action-light);
}
*:focus.focus--action-medium {
  --focus-color: var(--action-medium);
}
.focus--action-medium *:focus {
  --focus-color: var(--action-medium);
}
*:focus.focus--action-dark {
  --focus-color: var(--action-dark);
}
.focus--action-dark *:focus {
  --focus-color: var(--action-dark);
}
*:focus.focus--action-ultra-dark {
  --focus-color: var(--action-ultra-dark);
}
.focus--action-ultra-dark *:focus {
  --focus-color: var(--action-ultra-dark);
}
*:focus.focus--action-comp {
  --focus-color: var(--action-comp);
}
.focus--action-comp *:focus {
  --focus-color: var(--action-comp);
}
*:focus.focus--primary {
  --focus-color: var(--primary);
}
.focus--primary *:focus {
  --focus-color: var(--primary);
}
*:focus.focus--primary-hover {
  --focus-color: var(--primary-hover);
}
.focus--primary-hover *:focus {
  --focus-color: var(--primary-hover);
}
*:focus.focus--primary-ultra-light {
  --focus-color: var(--primary-ultra-light);
}
.focus--primary-ultra-light *:focus {
  --focus-color: var(--primary-ultra-light);
}
*:focus.focus--primary-light {
  --focus-color: var(--primary-light);
}
.focus--primary-light *:focus {
  --focus-color: var(--primary-light);
}
*:focus.focus--primary-medium {
  --focus-color: var(--primary-medium);
}
.focus--primary-medium *:focus {
  --focus-color: var(--primary-medium);
}
*:focus.focus--primary-dark {
  --focus-color: var(--primary-dark);
}
.focus--primary-dark *:focus {
  --focus-color: var(--primary-dark);
}
*:focus.focus--primary-ultra-dark {
  --focus-color: var(--primary-ultra-dark);
}
.focus--primary-ultra-dark *:focus {
  --focus-color: var(--primary-ultra-dark);
}
*:focus.focus--primary-comp {
  --focus-color: var(--primary-comp);
}
.focus--primary-comp *:focus {
  --focus-color: var(--primary-comp);
}
*:focus.focus--secondary {
  --focus-color: var(--secondary);
}
.focus--secondary *:focus {
  --focus-color: var(--secondary);
}
*:focus.focus--secondary-hover {
  --focus-color: var(--secondary-hover);
}
.focus--secondary-hover *:focus {
  --focus-color: var(--secondary-hover);
}
*:focus.focus--secondary-ultra-light {
  --focus-color: var(--secondary-ultra-light);
}
.focus--secondary-ultra-light *:focus {
  --focus-color: var(--secondary-ultra-light);
}
*:focus.focus--secondary-light {
  --focus-color: var(--secondary-light);
}
.focus--secondary-light *:focus {
  --focus-color: var(--secondary-light);
}
*:focus.focus--secondary-medium {
  --focus-color: var(--secondary-medium);
}
.focus--secondary-medium *:focus {
  --focus-color: var(--secondary-medium);
}
*:focus.focus--secondary-dark {
  --focus-color: var(--secondary-dark);
}
.focus--secondary-dark *:focus {
  --focus-color: var(--secondary-dark);
}
*:focus.focus--secondary-ultra-dark {
  --focus-color: var(--secondary-ultra-dark);
}
.focus--secondary-ultra-dark *:focus {
  --focus-color: var(--secondary-ultra-dark);
}
*:focus.focus--secondary-comp {
  --focus-color: var(--secondary-comp);
}
.focus--secondary-comp *:focus {
  --focus-color: var(--secondary-comp);
}
*:focus.focus--accent {
  --focus-color: var(--accent);
}
.focus--accent *:focus {
  --focus-color: var(--accent);
}
*:focus.focus--accent-hover {
  --focus-color: var(--accent-hover);
}
.focus--accent-hover *:focus {
  --focus-color: var(--accent-hover);
}
*:focus.focus--accent-ultra-light {
  --focus-color: var(--accent-ultra-light);
}
.focus--accent-ultra-light *:focus {
  --focus-color: var(--accent-ultra-light);
}
*:focus.focus--accent-light {
  --focus-color: var(--accent-light);
}
.focus--accent-light *:focus {
  --focus-color: var(--accent-light);
}
*:focus.focus--accent-medium {
  --focus-color: var(--accent-medium);
}
.focus--accent-medium *:focus {
  --focus-color: var(--accent-medium);
}
*:focus.focus--accent-dark {
  --focus-color: var(--accent-dark);
}
.focus--accent-dark *:focus {
  --focus-color: var(--accent-dark);
}
*:focus.focus--accent-ultra-dark {
  --focus-color: var(--accent-ultra-dark);
}
.focus--accent-ultra-dark *:focus {
  --focus-color: var(--accent-ultra-dark);
}
*:focus.focus--accent-comp {
  --focus-color: var(--accent-comp);
}
.focus--accent-comp *:focus {
  --focus-color: var(--accent-comp);
}
*:focus.focus--base {
  --focus-color: var(--base);
}
.focus--base *:focus {
  --focus-color: var(--base);
}
*:focus.focus--base-hover {
  --focus-color: var(--base-hover);
}
.focus--base-hover *:focus {
  --focus-color: var(--base-hover);
}
*:focus.focus--base-ultra-light {
  --focus-color: var(--base-ultra-light);
}
.focus--base-ultra-light *:focus {
  --focus-color: var(--base-ultra-light);
}
*:focus.focus--base-light {
  --focus-color: var(--base-light);
}
.focus--base-light *:focus {
  --focus-color: var(--base-light);
}
*:focus.focus--base-medium {
  --focus-color: var(--base-medium);
}
.focus--base-medium *:focus {
  --focus-color: var(--base-medium);
}
*:focus.focus--base-dark {
  --focus-color: var(--base-dark);
}
.focus--base-dark *:focus {
  --focus-color: var(--base-dark);
}
*:focus.focus--base-ultra-dark {
  --focus-color: var(--base-ultra-dark);
}
.focus--base-ultra-dark *:focus {
  --focus-color: var(--base-ultra-dark);
}
*:focus.focus--base-comp {
  --focus-color: var(--base-comp);
}
.focus--base-comp *:focus {
  --focus-color: var(--base-comp);
}
*:focus.focus--shade {
  --focus-color: var(--shade);
}
.focus--shade *:focus {
  --focus-color: var(--shade);
}
*:focus.focus--shade-hover {
  --focus-color: var(--shade-hover);
}
.focus--shade-hover *:focus {
  --focus-color: var(--shade-hover);
}
*:focus.focus--shade-ultra-light {
  --focus-color: var(--shade-ultra-light);
}
.focus--shade-ultra-light *:focus {
  --focus-color: var(--shade-ultra-light);
}
*:focus.focus--shade-light {
  --focus-color: var(--shade-light);
}
.focus--shade-light *:focus {
  --focus-color: var(--shade-light);
}
*:focus.focus--shade-medium {
  --focus-color: var(--shade-medium);
}
.focus--shade-medium *:focus {
  --focus-color: var(--shade-medium);
}
*:focus.focus--shade-dark {
  --focus-color: var(--shade-dark);
}
.focus--shade-dark *:focus {
  --focus-color: var(--shade-dark);
}
*:focus.focus--shade-ultra-dark {
  --focus-color: var(--shade-ultra-dark);
}
.focus--shade-ultra-dark *:focus {
  --focus-color: var(--shade-ultra-dark);
}
*:focus.focus--shade-comp {
  --focus-color: var(--shade-comp);
}
.focus--shade-comp *:focus {
  --focus-color: var(--shade-comp);
}
*:focus.focus--white {
  --focus-color: var(--white);
}
.focus--white *:focus {
  --focus-color: var(--white);
}
*:focus.focus--black {
  --focus-color: var(--black);
}
.focus--black *:focus {
  --focus-color: var(--black);
}
body.ct_inner div#ct-builder div.ct-component.ct-inner-content-workarea .hidden-accessible {
  position: relative !important;
  inline-size: 20px !important;
  block-size: auto !important;
  margin: 0;
  line-height: 1;
  visibility: hidden;
}
body.ct_inner div#ct-builder div.ct-component.ct-inner-content-workarea .hidden-accessible::before {
  content: "A";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background: #111;
  color: #f5f5f5;
  font-size: max(60%, 13px);
  padding: 0.5em;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 20px;
  block-size: 20px;
  visibility: visible;
  border-radius: 50%;
}
.link--skip, .ct-link-text.link--skip {
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-start: 1rem;
  transform: translateY(-250%);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
  background: var(--action);
  color: var(--action-ultra-light);
  text-align: center;
}
.link--skip:focus, .ct-link-text.link--skip:focus {
  transform: translateY(0);
}
.clickable-parent:not(a) {
  position: static;
}
.clickable-parent:not(a) a {
  position: static;
}
.clickable-parent:not(a) > a::after {
  content: "";
  position: absolute;
  inset: 0;
  cursor: pointer !important;
  display: flex;
}
button.clickable-parent, a.clickable-parent {
  position: static;
}
button.clickable-parent::after, a.clickable-parent::after {
  content: "";
  position: absolute;
  inset: 0;
  cursor: pointer !important;
  display: flex;
}
#ct-builder a.clickable-parent::after {
  content: '';
  position: relative;
}
.text--action {
  color: var(--action);
}
.text--action-hover {
  color: var(--action-hover);
}
.text--action-ultra-light {
  color: var(--action-ultra-light);
}
.text--action-light {
  color: var(--action-light);
}
.text--action-medium {
  color: var(--action-medium);
}
.text--action-dark {
  color: var(--action-dark);
}
.text--action-ultra-dark {
  color: var(--action-ultra-dark);
}
.text--action-comp {
  color: var(--action-comp);
}
.text--primary {
  color: var(--primary);
}
.text--primary-hover {
  color: var(--primary-hover);
}
.text--primary-ultra-light {
  color: var(--primary-ultra-light);
}
.text--primary-light {
  color: var(--primary-light);
}
.text--primary-medium {
  color: var(--primary-medium);
}
.text--primary-dark {
  color: var(--primary-dark);
}
.text--primary-ultra-dark {
  color: var(--primary-ultra-dark);
}
.text--primary-comp {
  color: var(--primary-comp);
}
.text--secondary {
  color: var(--secondary);
}
.text--secondary-hover {
  color: var(--secondary-hover);
}
.text--secondary-ultra-light {
  color: var(--secondary-ultra-light);
}
.text--secondary-light {
  color: var(--secondary-light);
}
.text--secondary-medium {
  color: var(--secondary-medium);
}
.text--secondary-dark {
  color: var(--secondary-dark);
}
.text--secondary-ultra-dark {
  color: var(--secondary-ultra-dark);
}
.text--secondary-comp {
  color: var(--secondary-comp);
}
.text--accent {
  color: var(--accent);
}
.text--accent-hover {
  color: var(--accent-hover);
}
.text--accent-ultra-light {
  color: var(--accent-ultra-light);
}
.text--accent-light {
  color: var(--accent-light);
}
.text--accent-medium {
  color: var(--accent-medium);
}
.text--accent-dark {
  color: var(--accent-dark);
}
.text--accent-ultra-dark {
  color: var(--accent-ultra-dark);
}
.text--accent-comp {
  color: var(--accent-comp);
}
.text--base {
  color: var(--base);
}
.text--base-hover {
  color: var(--base-hover);
}
.text--base-ultra-light {
  color: var(--base-ultra-light);
}
.text--base-light {
  color: var(--base-light);
}
.text--base-medium {
  color: var(--base-medium);
}
.text--base-dark {
  color: var(--base-dark);
}
.text--base-ultra-dark {
  color: var(--base-ultra-dark);
}
.text--base-comp {
  color: var(--base-comp);
}
.text--shade {
  color: var(--shade);
}
.text--shade-hover {
  color: var(--shade-hover);
}
.text--shade-ultra-light {
  color: var(--shade-ultra-light);
}
.text--shade-light {
  color: var(--shade-light);
}
.text--shade-medium {
  color: var(--shade-medium);
}
.text--shade-dark {
  color: var(--shade-dark);
}
.text--shade-ultra-dark {
  color: var(--shade-ultra-dark);
}
.text--shade-comp {
  color: var(--shade-comp);
}
.text--white {
  color: var(--white);
}
.text--black {
  color: var(--black);
}
.text--xs {
  font-size: 1.26rem;
  font-size: calc(1.1rem + (0 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.1rem, calc(0vw + 1.1rem), 1.1rem);
}
.text--s {
  font-size: 1.42rem;
  font-size: calc(1.2rem + (0 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.2rem, calc(0vw + 1.2rem), 1.2rem);
}
.text--m {
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.text--l {
  font-size: 2.025rem;
  font-size: calc(1.92rem + (0.4794 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.92rem, calc(0.4280357143vw + 1.7830285714rem), 2.3994rem);
}
.text--xl {
  font-size: 2.28rem;
  font-size: calc(2.304rem + (0.8944002 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.304rem, calc(0.7985716071vw + 2.0484570857rem), 3.1984002rem);
}
.text--xxl {
  font-size: 2.565rem;
  font-size: calc(2.7648rem + (1.4986674666 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.7648rem, calc(1.3380959523vw + 2.3366092953rem), 4.2634674666rem);
}
.text--xs.text--larger {
  font-size: 1.56rem;
  font-size: calc(1.32rem + (0.33 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.32rem, calc(0.2946428571vw + 1.2257142857rem), 1.65rem);
}
.text--s.text--larger {
  font-size: 1.68rem;
  font-size: calc(1.44rem + (0.36 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.44rem, calc(0.3214285714vw + 1.3371428571rem), 1.8rem);
}
.text--m.text--larger {
  font-size: 2.16rem;
  font-size: calc(1.92rem + (0.78 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.92rem, calc(0.6964285714vw + 1.6971428571rem), 2.7rem);
}
.text--l.text--larger {
  font-size: 2.4rem;
  font-size: calc(2.304rem + (1.2951 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.304rem, calc(1.1563392857vw + 1.9339714286rem), 3.5991rem);
}
.text--xl.text--larger {
  font-size: 2.76rem;
  font-size: calc(2.7648rem + (2.0328003 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.7648rem, calc(1.8150002679vw + 2.1839999143rem), 4.7976003rem);
}
.text--xxl.text--larger {
  font-size: 3.12rem;
  font-size: calc(3.31776rem + (3.0774411999 * ((100vw - 32rem) / 112)));
  font-size: clamp(3.31776rem, calc(2.7477153571vw + 2.4384910857rem), 6.3952011999rem);
}
h1.text--larger {
  font-size: 3.12rem;
  font-size: calc(3.31776rem + (3.0774411999 * ((100vw - 32rem) / 112)));
  font-size: clamp(3.31776rem, calc(2.7477153571vw + 2.4384910857rem), 6.3952011999rem);
}
h2.text--larger {
  font-size: 2.76rem;
  font-size: calc(2.7648rem + (2.0328003 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.7648rem, calc(1.8150002679vw + 2.1839999143rem), 4.7976003rem);
}
h3.text--larger {
  font-size: 2.4rem;
  font-size: calc(2.304rem + (1.2951 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.304rem, calc(1.1563392857vw + 1.9339714286rem), 3.5991rem);
}
h4.text--larger {
  font-size: 2.16rem;
  font-size: calc(1.92rem + (0.78 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.92rem, calc(0.6964285714vw + 1.6971428571rem), 2.7rem);
}
h5.text--larger {
  font-size: 1.68rem;
  font-size: calc(1.44rem + (0.36 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.44rem, calc(0.3214285714vw + 1.3371428571rem), 1.8rem);
}
h6.text--larger {
  font-size: 1.56rem;
  font-size: calc(1.32rem + (0.33 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.32rem, calc(0.2946428571vw + 1.2257142857rem), 1.65rem);
}
.text--100 {
  font-weight: 100;
}
.text--200 {
  font-weight: 200;
}
.text--300 {
  font-weight: 300;
}
.text--400 {
  font-weight: 400;
}
.text--500 {
  font-weight: 500;
}
.text--600 {
  font-weight: 600;
}
.text--700 {
  font-weight: 700;
}
.text--800 {
  font-weight: 800;
}
.text--900 {
  font-weight: 900;
}
.text--bold {
  font-weight: bold;
}
.text--italic {
  font-style: italic;
}
.text--oblique {
  font-style: oblique;
}
.text--decoration-none {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.text--underline {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.text--underline-wavy {
  -webkit-text-decoration: underline wavy;
  text-decoration: underline wavy;
}
.text--underline-dotted {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.text--underline-double {
  -webkit-text-decoration: underline double;
  text-decoration: underline double;
}
.text--underline-dashed {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
}
.text--overline {
  -webkit-text-decoration: overline;
  text-decoration: overline;
}
.text--line-through {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}
.text--transform-none {
  text-transform: none;
}
.text--uppercase {
  text-transform: uppercase;
}
.text--lowercase {
  text-transform: lowercase;
}
.text--capitalize {
  text-transform: capitalize;
}
.text--left {
  text-align: left;
}
.text--center {
  text-align: center;
}
.text--right {
  text-align: right;
}
.text--justify {
  text-align: justify;
}
.ct-span.text--100 {
  font-weight: 100;
}
.ct-span.text--200 {
  font-weight: 200;
}
.ct-span.text--300 {
  font-weight: 300;
}
.ct-span.text--400 {
  font-weight: 400;
}
.ct-span.text--500 {
  font-weight: 500;
}
.ct-span.text--600 {
  font-weight: 600;
}
.ct-span.text--700 {
  font-weight: 700;
}
.ct-span.text--800 {
  font-weight: 800;
}
.ct-span.text--900 {
  font-weight: 900;
}
.ct-span.text--bold {
  font-weight: bold;
}
.ct-span.text--italic {
  font-style: italic;
}
.ct-span.text--oblique {
  font-style: oblique;
}
.ct-span.text--decoration-none {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.ct-span.text--underline {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.ct-span.text--underline-wavy {
  -webkit-text-decoration: underline wavy;
  text-decoration: underline wavy;
}
.ct-span.text--underline-dotted {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
.ct-span.text--underline-double {
  -webkit-text-decoration: underline double;
  text-decoration: underline double;
}
.ct-span.text--underline-dashed {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
}
.ct-span.text--overline {
  -webkit-text-decoration: overline;
  text-decoration: overline;
}
.ct-span.text--line-through {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}
.ct-span.text--transform-none {
  text-transform: none;
}
.ct-span.text--uppercase {
  text-transform: uppercase;
}
.ct-span.text--lowercase {
  text-transform: lowercase;
}
.ct-span.text--capitalize {
  text-transform: capitalize;
}
.ct-span.text--left {
  text-align: left;
}
.ct-span.text--center {
  text-align: center;
}
.ct-span.text--right {
  text-align: right;
}
.ct-span.text--justify {
  text-align: justify;
}
.center--self {
  margin-left: auto;
  margin-right: auto;
}
.center--all , .ct-section.center--all  {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
}
.center--y , .ct-section.center--y  {
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: flex-start;
}
.center--x , .ct-section.center--x  {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: center;
}
.center--left , .ct-section.center--left  {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
}
.center--right , .ct-section.center--right  {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.center--top , .ct-section.center--top  {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: center;
}
.center--bottom , .ct-section.center--bottom  {
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1439px) {
  .center--all-xxl , .ct-section.center--all-xxl  {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-xxl , .ct-section.center--y-xxl  {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-xxl , .ct-section.center--x-xxl   {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-xxl , .ct-section.center--left-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-xxl , .ct-section.center--right-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-xxl , .ct-section.center--top-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-xxl , .ct-section.center--bottom-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 1279px) {
  .center--all-xl , .ct-section.center--all-xl    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-xl , .ct-section.center--y-xl    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-xl , .ct-section.center--x-xl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-xl , .ct-section.center--left-xl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-xl , .ct-section.center--right-xl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-xl , .ct-section.center--top-xl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-xl , .ct-section.center--bottom-xl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .center--all-l , .ct-section.center--all-l    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-l , .ct-section.center--y-l    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-l , .ct-section.center--x-l    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-l , .ct-section.center--left-l    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-l , .ct-section.center--right-l    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-l , .ct-section.center--top-l    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-l , .ct-section.center--bottom-l    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .center--all-m , .ct-section.center--all-m    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-m , .ct-section.center--y-m    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-m , .ct-section.center--x-m    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-m , .ct-section.center--left-m    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-m , .ct-section.center--right-m    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-m , .ct-section.center--top-m    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-m , .ct-section.center--bottom-m    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 479px) {
  .center--all-s , .ct-section.center--all-s    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-s , .ct-section.center--y-s    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-s , .ct-section.center--x-s    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-s , .ct-section.center--left-s    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-s , .ct-section.center--right-s    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-s , .ct-section.center--top-s    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-s , .ct-section.center--bottom-s    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 359px) {
  .center--all-xs , .ct-section.center--all-xs    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-xs , .ct-section.center--y-xs    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-xs , .ct-section.center--x-xs    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-xs , .ct-section.center--left-xs    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-xs , .ct-section.center--right-xs    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-xs , .ct-section.center--top-xs    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-xs , .ct-section.center--bottom-xs    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
.btn--action, .ct-link-button.btn--action {
  background-color: var(--action);
  color: var(--action-ultra-light);
  cursor: pointer;
  border-style: solid;
  border-color: var(--action);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.btn--action:hover, .ct-link-button.btn--action:hover {
  background-color: var(--action-hover);
  color: var(--action-ultra-light);
  border-color: var(--action-hover);
  cursor: pointer;
}
.btn--action.btn--outline, .ct-link-button.btn--action.btn--outline {
  background-color: transparent;
  border-style: solid;
  cursor: pointer;
  color: var(--action);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-width: var(--outline-btn-border-size);
  border-radius: var(--btn-radius);
  text-transform: var(--btn-text-transform);
  font-weight: var(--btn-weight);
}
.btn--action.btn--outline:hover, .ct-link-button.btn--action.btn--outline:hover {
  background-color: var(--action-hover);
  color: var(--action-ultra-light);
}
.btn--primary, .ct-link-button.btn--primary {
  background-color: var(--primary);
  color: var(--primary-ultra-light);
  cursor: pointer;
  border-style: solid;
  border-color: var(--primary);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.btn--primary:hover, .ct-link-button.btn--primary:hover {
  background-color: var(--primary-hover);
  color: var(--primary-ultra-light);
  border-color: var(--primary-hover);
  cursor: pointer;
}
.btn--primary.btn--outline, .ct-link-button.btn--primary.btn--outline {
  background-color: transparent;
  border-style: solid;
  cursor: pointer;
  color: var(--primary);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-width: var(--outline-btn-border-size);
  border-radius: var(--btn-radius);
  text-transform: var(--btn-text-transform);
  font-weight: var(--btn-weight);
}
.btn--primary.btn--outline:hover, .ct-link-button.btn--primary.btn--outline:hover {
  background-color: var(--primary-hover);
  color: var(--primary-ultra-light);
}
.btn--secondary, .ct-link-button.btn--secondary {
  background-color: var(--secondary);
  color: var(--secondary-ultra-light);
  cursor: pointer;
  border-style: solid;
  border-color: var(--secondary);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.btn--secondary:hover, .ct-link-button.btn--secondary:hover {
  background-color: var(--secondary-hover);
  color: var(--secondary-ultra-light);
  border-color: var(--secondary-hover);
  cursor: pointer;
}
.btn--secondary.btn--outline, .ct-link-button.btn--secondary.btn--outline {
  background-color: transparent;
  border-style: solid;
  cursor: pointer;
  color: var(--secondary);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-width: var(--outline-btn-border-size);
  border-radius: var(--btn-radius);
  text-transform: var(--btn-text-transform);
  font-weight: var(--btn-weight);
}
.btn--secondary.btn--outline:hover, .ct-link-button.btn--secondary.btn--outline:hover {
  background-color: var(--secondary-hover);
  color: var(--secondary-ultra-light);
}
.btn--accent, .ct-link-button.btn--accent {
  background-color: var(--accent);
  color: var(--accent-ultra-light);
  cursor: pointer;
  border-style: solid;
  border-color: var(--accent);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.btn--accent:hover, .ct-link-button.btn--accent:hover {
  background-color: var(--accent-hover);
  color: var(--accent-ultra-light);
  border-color: var(--accent-hover);
  cursor: pointer;
}
.btn--accent.btn--outline, .ct-link-button.btn--accent.btn--outline {
  background-color: transparent;
  border-style: solid;
  cursor: pointer;
  color: var(--accent);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-width: var(--outline-btn-border-size);
  border-radius: var(--btn-radius);
  text-transform: var(--btn-text-transform);
  font-weight: var(--btn-weight);
}
.btn--accent.btn--outline:hover, .ct-link-button.btn--accent.btn--outline:hover {
  background-color: var(--accent-hover);
  color: var(--accent-ultra-light);
}
.btn--base, .ct-link-button.btn--base {
  background-color: var(--base);
  color: var(--base-ultra-light);
  cursor: pointer;
  border-style: solid;
  border-color: var(--base);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.btn--base:hover, .ct-link-button.btn--base:hover {
  background-color: var(--base-hover);
  color: var(--base-ultra-light);
  border-color: var(--base-hover);
  cursor: pointer;
}
.btn--base.btn--outline, .ct-link-button.btn--base.btn--outline {
  background-color: transparent;
  border-style: solid;
  cursor: pointer;
  color: var(--base);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-width: var(--outline-btn-border-size);
  border-radius: var(--btn-radius);
  text-transform: var(--btn-text-transform);
  font-weight: var(--btn-weight);
}
.btn--base.btn--outline:hover, .ct-link-button.btn--base.btn--outline:hover {
  background-color: var(--base-hover);
  color: var(--base-ultra-light);
}
.btn--black, .ct-link-button.btn--black {
  background-color: var(--shade-black);
  color: var(--shade-white);
  cursor: pointer;
  border-style: solid;
  border-color: var(--shade-black);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.btn--black:hover, .ct-link-button.btn--black:hover {
  background-color: var(--action-hover);
  color: var(--shade-white);
  border-color: var(--shade-white);
  cursor: pointer;
}
.btn--black.btn--outline, .ct-link-button.btn--black.btn--outline {
  background-color: transparent;
  border-style: solid;
  cursor: pointer;
  color: var(--shade-black);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-width: var(--outline-btn-border-size);
  border-radius: var(--btn-radius);
  text-transform: var(--btn-text-transform);
  font-weight: var(--btn-weight);
}
.btn--black.btn--outline:hover, .ct-link-button.btn--black.btn--outline:hover {
  background-color: var(--action-hover);
  color: var(--action-ultra-light);
}
.btn--white, .ct-link-button.btn--white {
  background-color: var(--shade-white);
  color: var(--shade-black);
  cursor: pointer;
  border-style: solid;
  border-color: var(--shade-white);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-radius: var(--btn-radius);
  border-style: solid;
  text-transform: var(--btn-text-transform);
  -webkit-text-decoration: var(--btn-text-decoration);
  text-decoration: var(--btn-text-decoration);
  letter-spacing: var(--btn-letter-spacing);
  font-weight: var(--btn-weight);
  font-style: var(--btn-text-style);
  min-inline-size: var(--btn-width);
  line-height: var(--btn-line-height);
  justify-content: center;
  text-align: center;
  border-width: var(--btn-border-size);
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
}
.btn--white:hover, .ct-link-button.btn--white:hover {
  background-color: var(--action-hover);
  color: var(--shade-white);
  border-color: var(--shade-black);
  cursor: pointer;
}
.btn--white.btn--outline, .ct-link-button.btn--white.btn--outline {
  background-color: transparent;
  border-style: solid;
  cursor: pointer;
  color: var(--shade-white);
  padding: var(--btn-pad-y) var(--btn-pad-x);
  border-width: var(--outline-btn-border-size);
  border-radius: var(--btn-radius);
  text-transform: var(--btn-text-transform);
  font-weight: var(--btn-weight);
}
.btn--white.btn--outline:hover, .ct-link-button.btn--white.btn--outline:hover {
  background-color: var(--action-hover);
  color: var(--action-ultra-light);
}
.btn--xs, .ct-link-button.btn--xs {
  font-size: 1.26rem;
  font-size: calc(1.1rem + (0 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.1rem, calc(0vw + 1.1rem), 1.1rem);
  cursor: pointer;
}
.btn--s, .ct-link-button.btn--s {
  font-size: 1.42rem;
  font-size: calc(1.2rem + (0 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.2rem, calc(0vw + 1.2rem), 1.2rem);
  cursor: pointer;
}
.btn--m, .ct-link-button.btn--m {
  font-size: 1.8rem;
  font-size: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
  cursor: pointer;
}
.btn--l, .ct-link-button.btn--l {
  font-size: 2.025rem;
  font-size: calc(1.92rem + (0.4794 * ((100vw - 32rem) / 112)));
  font-size: clamp(1.92rem, calc(0.4280357143vw + 1.7830285714rem), 2.3994rem);
  cursor: pointer;
}
.btn--xl, .ct-link-button.btn--xl {
  font-size: 2.28rem;
  font-size: calc(2.304rem + (0.8944002 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.304rem, calc(0.7985716071vw + 2.0484570857rem), 3.1984002rem);
  cursor: pointer;
}
.btn--xxl, .ct-link-button.btn--xxl {
  font-size: 2.565rem;
  font-size: calc(2.7648rem + (1.4986674666 * ((100vw - 32rem) / 112)));
  font-size: clamp(2.7648rem, calc(1.3380959523vw + 2.3366092953rem), 4.2634674666rem);
  cursor: pointer;
}
.ct-video.aspect--1-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--1-1 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--1-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--1-2 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--2-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--2-1 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--2-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--2-3 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--3-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--3-2 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--3-4 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--3-4 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--4-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--4-3 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--16-9 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--16-9 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
.ct-video.aspect--9-16 > .oxygen-vsb-responsive-video-wrapper > iframe {
  position: relative;
}
.ct-video.aspect--9-16 > .oxygen-vsb-responsive-video-wrapper {
  padding-block-end: 0 !important;
}
@media (max-width: 1439px) {
  .aspect--xxl-1-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-1-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-1-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-1-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-2-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-2-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-2-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-2-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-3-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-3-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-3-4 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-3-4 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-4-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-4-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-16-9 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-16-9 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xxl-9-16 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xxl-9-16 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
}
@media (max-width: 1279px) {
  .aspect--xl-1-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-1-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-1-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-1-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-2-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-2-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-2-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-2-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-3-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-3-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-3-4 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-3-4 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-4-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-4-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-16-9 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-16-9 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xl-9-16 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xl-9-16 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
}
@media (max-width: 991px) {
  .aspect--l-1-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-1-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-1-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-1-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-2-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-2-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-2-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-2-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-3-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-3-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-3-4 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-3-4 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-4-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-4-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-16-9 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-16-9 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--l-9-16 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--l-9-16 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
}
@media (max-width: 767px) {
  .aspect--m-1-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-1-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-1-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-1-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-2-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-2-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-2-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-2-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-3-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-3-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-3-4 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-3-4 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-4-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-4-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-16-9 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-16-9 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--m-9-16 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--m-9-16 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
}
@media (max-width: 479px) {
  .aspect--s-1-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-1-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-1-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-1-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-2-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-2-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-2-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-2-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-3-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-3-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-3-4 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-3-4 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-4-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-4-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-16-9 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-16-9 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--s-9-16 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--s-9-16 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
}
@media (max-width: 359px) {
  .aspect--xs-1-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-1-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-1-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-1-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-2-1 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-2-1 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-2-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-2-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-3-2 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-3-2 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-3-4 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-3-4 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-4-3 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-4-3 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-16-9 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-16-9 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
  .aspect--xs-9-16 > .oxygen-vsb-responsive-video-wrapper > iframe {
    position: relative;
  }
  .aspect--xs-9-16 > .oxygen-vsb-responsive-video-wrapper {
    padding-block-end: 0 !important;
  }
}
@media (min-width: 1279px) {
  .breakout--s {
    inline-size: 60vw;
    max-inline-size: 60vw !important;
    margin-inline: calc(-30vw + 50%);
  }
  .ct-image.breakout--s {
    inline-size: 60vw;
    max-inline-size: 60vw !important;
    margin-inline: calc(-30vw + 50%);
  }
  .breakout--m {
    inline-size: 70vw;
    max-inline-size: 70vw !important;
    margin-inline: calc(-35vw + 50%);
  }
  .ct-image.breakout--m {
    inline-size: 70vw;
    max-inline-size: 70vw !important;
    margin-inline: calc(-35vw + 50%);
  }
  .breakout--l {
    inline-size: 80vw;
    max-inline-size: 80vw !important;
    margin-inline: calc(-40vw + 50%);
  }
  .ct-image.breakout--l {
    inline-size: 80vw;
    max-inline-size: 80vw !important;
    margin-inline: calc(-40vw + 50%);
  }
  .breakout--xl {
    inline-size: 90vw;
    max-inline-size: 90vw !important;
    margin-inline: calc(-45vw + 50%);
  }
  .ct-image.breakout--xl {
    inline-size: 90vw;
    max-inline-size: 90vw !important;
    margin-inline: calc(-45vw + 50%);
  }
}
.breakout--full {
  inline-size: 100vw !important;
  max-inline-size: 100vw !important;
  margin-inline: calc(-50vw + 50%) !important;
  align-self: flex-start;
}
.ct-image.breakout--full {
  inline-size: 100vw !important;
  max-inline-size: 100vw !important;
  margin-inline: calc(-50vw + 50%) !important;
  align-self: flex-start;
}
@media (max-width: 1279px) {
  [class*="breakout--"]:not(.breakout--full) {
    inline-size: 100% !important;
    max-inline-size: 100% !important;
    margin-inline: 0;
  }
}
.flex-grid--1:not(section), section.flex-grid--1    {
  --gap: var(--grid-gap);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.flex-grid--1:not(section) > *, section.flex-grid--1    > * {
  width: 100%;
  max-width: 100%;
  flex-basis: auto;
}
.flex-grid--2:not(section), section.flex-grid--2    {
  --gap: var(--grid-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}
.flex-grid--2:not(section).gap--xs, section.flex-grid--2   .gap--xs {
  --gap: var(--space-xs);
}
.flex-grid--2:not(section).gap--s, section.flex-grid--2   .gap--s {
  --gap: var(--space-s);
}
.flex-grid--2:not(section).gap--m, section.flex-grid--2   .gap--m {
  --gap: var(--space-m);
}
.flex-grid--2:not(section).gap--l, section.flex-grid--2   .gap--l {
  --gap: var(--space-l);
}
.flex-grid--2:not(section).gap--xl, section.flex-grid--2   .gap--xl {
  --gap: var(--space-xl);
}
.flex-grid--2:not(section).gap--xxl, section.flex-grid--2   .gap--xxl {
  --gap: var(--space-xxl);
}
.flex-grid--2:not(section) > *, section.flex-grid--2    > * {
  width: 100%;
  max-width: calc((100% - (var(--gap) * 1)) / 2);
}
@media (max-width: 1439px) {
  .flex-grid--xxl-1:not(section), section.flex-grid--xxl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xxl-1:not(section) > *, section.flex-grid--xxl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xxl-2:not(section), section.flex-grid--xxl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xs, section.flex-grid--xxl-2   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-s, section.flex-grid--xxl-2   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-m, section.flex-grid--xxl-2   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-l, section.flex-grid--xxl-2   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xl, section.flex-grid--xxl-2   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xxl, section.flex-grid--xxl-2   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-2:not(section) > *, section.flex-grid--xxl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xxl-3:not(section), section.flex-grid--xxl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xs, section.flex-grid--xxl-3   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-s, section.flex-grid--xxl-3   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-m, section.flex-grid--xxl-3   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-l, section.flex-grid--xxl-3   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xl, section.flex-grid--xxl-3   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xxl, section.flex-grid--xxl-3   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-3:not(section) > *, section.flex-grid--xxl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xxl-4:not(section), section.flex-grid--xxl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xs, section.flex-grid--xxl-4   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-s, section.flex-grid--xxl-4   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-m, section.flex-grid--xxl-4   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-l, section.flex-grid--xxl-4   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xl, section.flex-grid--xxl-4   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xxl, section.flex-grid--xxl-4   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-4:not(section) > *, section.flex-grid--xxl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xxl-5:not(section), section.flex-grid--xxl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xs, section.flex-grid--xxl-5   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-s, section.flex-grid--xxl-5   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-m, section.flex-grid--xxl-5   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-l, section.flex-grid--xxl-5   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xl, section.flex-grid--xxl-5   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xxl, section.flex-grid--xxl-5   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-5:not(section) > *, section.flex-grid--xxl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xxl-6:not(section), section.flex-grid--xxl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xs, section.flex-grid--xxl-6   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-s, section.flex-grid--xxl-6   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-m, section.flex-grid--xxl-6   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-l, section.flex-grid--xxl-6   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xl, section.flex-grid--xxl-6   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xxl, section.flex-grid--xxl-6   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-6:not(section) > *, section.flex-grid--xxl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 1279px) {
  .flex-grid--xl-1:not(section), section.flex-grid--xl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xl-1:not(section) > *, section.flex-grid--xl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xl-2:not(section), section.flex-grid--xl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-2:not(section).gap--xl-xs, section.flex-grid--xl-2   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-2:not(section).gap--xl-s, section.flex-grid--xl-2   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-2:not(section).gap--xl-m, section.flex-grid--xl-2   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-2:not(section).gap--xl-l, section.flex-grid--xl-2   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-2:not(section).gap--xl-xl, section.flex-grid--xl-2   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-2:not(section).gap--xl-xxl, section.flex-grid--xl-2   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-2:not(section) > *, section.flex-grid--xl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xl-3:not(section), section.flex-grid--xl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-3:not(section).gap--xl-xs, section.flex-grid--xl-3   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-3:not(section).gap--xl-s, section.flex-grid--xl-3   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-3:not(section).gap--xl-m, section.flex-grid--xl-3   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-3:not(section).gap--xl-l, section.flex-grid--xl-3   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-3:not(section).gap--xl-xl, section.flex-grid--xl-3   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-3:not(section).gap--xl-xxl, section.flex-grid--xl-3   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-3:not(section) > *, section.flex-grid--xl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xl-4:not(section), section.flex-grid--xl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-4:not(section).gap--xl-xs, section.flex-grid--xl-4   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-4:not(section).gap--xl-s, section.flex-grid--xl-4   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-4:not(section).gap--xl-m, section.flex-grid--xl-4   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-4:not(section).gap--xl-l, section.flex-grid--xl-4   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-4:not(section).gap--xl-xl, section.flex-grid--xl-4   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-4:not(section).gap--xl-xxl, section.flex-grid--xl-4   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-4:not(section) > *, section.flex-grid--xl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xl-5:not(section), section.flex-grid--xl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-5:not(section).gap--xl-xs, section.flex-grid--xl-5   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-5:not(section).gap--xl-s, section.flex-grid--xl-5   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-5:not(section).gap--xl-m, section.flex-grid--xl-5   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-5:not(section).gap--xl-l, section.flex-grid--xl-5   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-5:not(section).gap--xl-xl, section.flex-grid--xl-5   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-5:not(section).gap--xl-xxl, section.flex-grid--xl-5   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-5:not(section) > *, section.flex-grid--xl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xl-6:not(section), section.flex-grid--xl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-6:not(section).gap--xl-xs, section.flex-grid--xl-6   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-6:not(section).gap--xl-s, section.flex-grid--xl-6   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-6:not(section).gap--xl-m, section.flex-grid--xl-6   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-6:not(section).gap--xl-l, section.flex-grid--xl-6   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-6:not(section).gap--xl-xl, section.flex-grid--xl-6   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-6:not(section).gap--xl-xxl, section.flex-grid--xl-6   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-6:not(section) > *, section.flex-grid--xl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 991px) {
  .flex-grid--l-1:not(section), section.flex-grid--l-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--l-1:not(section) > *, section.flex-grid--l-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--l-2:not(section), section.flex-grid--l-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-2:not(section).gap--l-xs, section.flex-grid--l-2   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-2:not(section).gap--l-s, section.flex-grid--l-2   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-2:not(section).gap--l-m, section.flex-grid--l-2   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-2:not(section).gap--l-l, section.flex-grid--l-2   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-2:not(section).gap--l-xl, section.flex-grid--l-2   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-2:not(section).gap--l-xxl, section.flex-grid--l-2   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-2:not(section) > *, section.flex-grid--l-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--l-3:not(section), section.flex-grid--l-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-3:not(section).gap--l-xs, section.flex-grid--l-3   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-3:not(section).gap--l-s, section.flex-grid--l-3   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-3:not(section).gap--l-m, section.flex-grid--l-3   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-3:not(section).gap--l-l, section.flex-grid--l-3   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-3:not(section).gap--l-xl, section.flex-grid--l-3   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-3:not(section).gap--l-xxl, section.flex-grid--l-3   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-3:not(section) > *, section.flex-grid--l-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--l-4:not(section), section.flex-grid--l-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-4:not(section).gap--l-xs, section.flex-grid--l-4   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-4:not(section).gap--l-s, section.flex-grid--l-4   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-4:not(section).gap--l-m, section.flex-grid--l-4   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-4:not(section).gap--l-l, section.flex-grid--l-4   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-4:not(section).gap--l-xl, section.flex-grid--l-4   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-4:not(section).gap--l-xxl, section.flex-grid--l-4   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-4:not(section) > *, section.flex-grid--l-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--l-5:not(section), section.flex-grid--l-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-5:not(section).gap--l-xs, section.flex-grid--l-5   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-5:not(section).gap--l-s, section.flex-grid--l-5   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-5:not(section).gap--l-m, section.flex-grid--l-5   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-5:not(section).gap--l-l, section.flex-grid--l-5   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-5:not(section).gap--l-xl, section.flex-grid--l-5   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-5:not(section).gap--l-xxl, section.flex-grid--l-5   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-5:not(section) > *, section.flex-grid--l-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--l-6:not(section), section.flex-grid--l-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-6:not(section).gap--l-xs, section.flex-grid--l-6   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-6:not(section).gap--l-s, section.flex-grid--l-6   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-6:not(section).gap--l-m, section.flex-grid--l-6   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-6:not(section).gap--l-l, section.flex-grid--l-6   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-6:not(section).gap--l-xl, section.flex-grid--l-6   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-6:not(section).gap--l-xxl, section.flex-grid--l-6   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-6:not(section) > *, section.flex-grid--l-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 767px) {
  .flex-grid--m-1:not(section), section.flex-grid--m-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--m-1:not(section) > *, section.flex-grid--m-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--m-2:not(section), section.flex-grid--m-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-2:not(section).gap--m-xs, section.flex-grid--m-2   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-2:not(section).gap--m-s, section.flex-grid--m-2   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-2:not(section).gap--m-m, section.flex-grid--m-2   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-2:not(section).gap--m-l, section.flex-grid--m-2   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-2:not(section).gap--m-xl, section.flex-grid--m-2   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-2:not(section).gap--m-xxl, section.flex-grid--m-2   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-2:not(section) > *, section.flex-grid--m-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--m-3:not(section), section.flex-grid--m-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-3:not(section).gap--m-xs, section.flex-grid--m-3   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-3:not(section).gap--m-s, section.flex-grid--m-3   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-3:not(section).gap--m-m, section.flex-grid--m-3   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-3:not(section).gap--m-l, section.flex-grid--m-3   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-3:not(section).gap--m-xl, section.flex-grid--m-3   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-3:not(section).gap--m-xxl, section.flex-grid--m-3   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-3:not(section) > *, section.flex-grid--m-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--m-4:not(section), section.flex-grid--m-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-4:not(section).gap--m-xs, section.flex-grid--m-4   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-4:not(section).gap--m-s, section.flex-grid--m-4   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-4:not(section).gap--m-m, section.flex-grid--m-4   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-4:not(section).gap--m-l, section.flex-grid--m-4   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-4:not(section).gap--m-xl, section.flex-grid--m-4   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-4:not(section).gap--m-xxl, section.flex-grid--m-4   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-4:not(section) > *, section.flex-grid--m-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--m-5:not(section), section.flex-grid--m-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-5:not(section).gap--m-xs, section.flex-grid--m-5   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-5:not(section).gap--m-s, section.flex-grid--m-5   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-5:not(section).gap--m-m, section.flex-grid--m-5   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-5:not(section).gap--m-l, section.flex-grid--m-5   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-5:not(section).gap--m-xl, section.flex-grid--m-5   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-5:not(section).gap--m-xxl, section.flex-grid--m-5   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-5:not(section) > *, section.flex-grid--m-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--m-6:not(section), section.flex-grid--m-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-6:not(section).gap--m-xs, section.flex-grid--m-6   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-6:not(section).gap--m-s, section.flex-grid--m-6   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-6:not(section).gap--m-m, section.flex-grid--m-6   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-6:not(section).gap--m-l, section.flex-grid--m-6   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-6:not(section).gap--m-xl, section.flex-grid--m-6   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-6:not(section).gap--m-xxl, section.flex-grid--m-6   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-6:not(section) > *, section.flex-grid--m-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 479px) {
  .flex-grid--s-1:not(section), section.flex-grid--s-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--s-1:not(section) > *, section.flex-grid--s-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--s-2:not(section), section.flex-grid--s-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-2:not(section).gap--s-xs, section.flex-grid--s-2   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-2:not(section).gap--s-s, section.flex-grid--s-2   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-2:not(section).gap--s-m, section.flex-grid--s-2   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-2:not(section).gap--s-l, section.flex-grid--s-2   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-2:not(section).gap--s-xl, section.flex-grid--s-2   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-2:not(section).gap--s-xxl, section.flex-grid--s-2   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-2:not(section) > *, section.flex-grid--s-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--s-3:not(section), section.flex-grid--s-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-3:not(section).gap--s-xs, section.flex-grid--s-3   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-3:not(section).gap--s-s, section.flex-grid--s-3   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-3:not(section).gap--s-m, section.flex-grid--s-3   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-3:not(section).gap--s-l, section.flex-grid--s-3   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-3:not(section).gap--s-xl, section.flex-grid--s-3   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-3:not(section).gap--s-xxl, section.flex-grid--s-3   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-3:not(section) > *, section.flex-grid--s-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--s-4:not(section), section.flex-grid--s-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-4:not(section).gap--s-xs, section.flex-grid--s-4   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-4:not(section).gap--s-s, section.flex-grid--s-4   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-4:not(section).gap--s-m, section.flex-grid--s-4   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-4:not(section).gap--s-l, section.flex-grid--s-4   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-4:not(section).gap--s-xl, section.flex-grid--s-4   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-4:not(section).gap--s-xxl, section.flex-grid--s-4   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-4:not(section) > *, section.flex-grid--s-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--s-5:not(section), section.flex-grid--s-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-5:not(section).gap--s-xs, section.flex-grid--s-5   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-5:not(section).gap--s-s, section.flex-grid--s-5   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-5:not(section).gap--s-m, section.flex-grid--s-5   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-5:not(section).gap--s-l, section.flex-grid--s-5   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-5:not(section).gap--s-xl, section.flex-grid--s-5   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-5:not(section).gap--s-xxl, section.flex-grid--s-5   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-5:not(section) > *, section.flex-grid--s-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--s-6:not(section), section.flex-grid--s-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-6:not(section).gap--s-xs, section.flex-grid--s-6   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-6:not(section).gap--s-s, section.flex-grid--s-6   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-6:not(section).gap--s-m, section.flex-grid--s-6   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-6:not(section).gap--s-l, section.flex-grid--s-6   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-6:not(section).gap--s-xl, section.flex-grid--s-6   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-6:not(section).gap--s-xxl, section.flex-grid--s-6   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-6:not(section) > *, section.flex-grid--s-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 359px) {
  .flex-grid--xs-1:not(section), section.flex-grid--xs-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xs-1:not(section) > *, section.flex-grid--xs-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xs-2:not(section), section.flex-grid--xs-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-2:not(section).gap--xs-xs, section.flex-grid--xs-2   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-2:not(section).gap--xs-s, section.flex-grid--xs-2   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-2:not(section).gap--xs-m, section.flex-grid--xs-2   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-2:not(section).gap--xs-l, section.flex-grid--xs-2   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-2:not(section).gap--xs-xl, section.flex-grid--xs-2   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-2:not(section).gap--xs-xxl, section.flex-grid--xs-2   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-2:not(section) > *, section.flex-grid--xs-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xs-3:not(section), section.flex-grid--xs-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-3:not(section).gap--xs-xs, section.flex-grid--xs-3   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-3:not(section).gap--xs-s, section.flex-grid--xs-3   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-3:not(section).gap--xs-m, section.flex-grid--xs-3   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-3:not(section).gap--xs-l, section.flex-grid--xs-3   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-3:not(section).gap--xs-xl, section.flex-grid--xs-3   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-3:not(section).gap--xs-xxl, section.flex-grid--xs-3   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-3:not(section) > *, section.flex-grid--xs-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xs-4:not(section), section.flex-grid--xs-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-4:not(section).gap--xs-xs, section.flex-grid--xs-4   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-4:not(section).gap--xs-s, section.flex-grid--xs-4   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-4:not(section).gap--xs-m, section.flex-grid--xs-4   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-4:not(section).gap--xs-l, section.flex-grid--xs-4   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-4:not(section).gap--xs-xl, section.flex-grid--xs-4   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-4:not(section).gap--xs-xxl, section.flex-grid--xs-4   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-4:not(section) > *, section.flex-grid--xs-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xs-5:not(section), section.flex-grid--xs-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-5:not(section).gap--xs-xs, section.flex-grid--xs-5   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-5:not(section).gap--xs-s, section.flex-grid--xs-5   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-5:not(section).gap--xs-m, section.flex-grid--xs-5   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-5:not(section).gap--xs-l, section.flex-grid--xs-5   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-5:not(section).gap--xs-xl, section.flex-grid--xs-5   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-5:not(section).gap--xs-xxl, section.flex-grid--xs-5   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-5:not(section) > *, section.flex-grid--xs-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xs-6:not(section), section.flex-grid--xs-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-6:not(section).gap--xs-xs, section.flex-grid--xs-6   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-6:not(section).gap--xs-s, section.flex-grid--xs-6   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-6:not(section).gap--xs-m, section.flex-grid--xs-6   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-6:not(section).gap--xs-l, section.flex-grid--xs-6   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-6:not(section).gap--xs-xl, section.flex-grid--xs-6   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-6:not(section).gap--xs-xxl, section.flex-grid--xs-6   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-6:not(section) > *, section.flex-grid--xs-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
.flex-grid--3:not(section), section.flex-grid--3    {
  --gap: var(--grid-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}
.flex-grid--3:not(section).gap--xs, section.flex-grid--3   .gap--xs {
  --gap: var(--space-xs);
}
.flex-grid--3:not(section).gap--s, section.flex-grid--3   .gap--s {
  --gap: var(--space-s);
}
.flex-grid--3:not(section).gap--m, section.flex-grid--3   .gap--m {
  --gap: var(--space-m);
}
.flex-grid--3:not(section).gap--l, section.flex-grid--3   .gap--l {
  --gap: var(--space-l);
}
.flex-grid--3:not(section).gap--xl, section.flex-grid--3   .gap--xl {
  --gap: var(--space-xl);
}
.flex-grid--3:not(section).gap--xxl, section.flex-grid--3   .gap--xxl {
  --gap: var(--space-xxl);
}
.flex-grid--3:not(section) > *, section.flex-grid--3    > * {
  width: 100%;
  max-width: calc((100% - (var(--gap) * 2)) / 3);
}
@media (max-width: 1439px) {
  .flex-grid--xxl-1:not(section), section.flex-grid--xxl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xxl-1:not(section) > *, section.flex-grid--xxl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xxl-2:not(section), section.flex-grid--xxl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xs, section.flex-grid--xxl-2   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-s, section.flex-grid--xxl-2   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-m, section.flex-grid--xxl-2   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-l, section.flex-grid--xxl-2   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xl, section.flex-grid--xxl-2   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xxl, section.flex-grid--xxl-2   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-2:not(section) > *, section.flex-grid--xxl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xxl-3:not(section), section.flex-grid--xxl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xs, section.flex-grid--xxl-3   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-s, section.flex-grid--xxl-3   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-m, section.flex-grid--xxl-3   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-l, section.flex-grid--xxl-3   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xl, section.flex-grid--xxl-3   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xxl, section.flex-grid--xxl-3   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-3:not(section) > *, section.flex-grid--xxl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xxl-4:not(section), section.flex-grid--xxl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xs, section.flex-grid--xxl-4   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-s, section.flex-grid--xxl-4   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-m, section.flex-grid--xxl-4   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-l, section.flex-grid--xxl-4   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xl, section.flex-grid--xxl-4   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xxl, section.flex-grid--xxl-4   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-4:not(section) > *, section.flex-grid--xxl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xxl-5:not(section), section.flex-grid--xxl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xs, section.flex-grid--xxl-5   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-s, section.flex-grid--xxl-5   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-m, section.flex-grid--xxl-5   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-l, section.flex-grid--xxl-5   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xl, section.flex-grid--xxl-5   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xxl, section.flex-grid--xxl-5   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-5:not(section) > *, section.flex-grid--xxl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xxl-6:not(section), section.flex-grid--xxl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xs, section.flex-grid--xxl-6   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-s, section.flex-grid--xxl-6   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-m, section.flex-grid--xxl-6   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-l, section.flex-grid--xxl-6   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xl, section.flex-grid--xxl-6   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xxl, section.flex-grid--xxl-6   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-6:not(section) > *, section.flex-grid--xxl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 1279px) {
  .flex-grid--xl-1:not(section), section.flex-grid--xl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xl-1:not(section) > *, section.flex-grid--xl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xl-2:not(section), section.flex-grid--xl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-2:not(section).gap--xl-xs, section.flex-grid--xl-2   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-2:not(section).gap--xl-s, section.flex-grid--xl-2   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-2:not(section).gap--xl-m, section.flex-grid--xl-2   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-2:not(section).gap--xl-l, section.flex-grid--xl-2   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-2:not(section).gap--xl-xl, section.flex-grid--xl-2   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-2:not(section).gap--xl-xxl, section.flex-grid--xl-2   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-2:not(section) > *, section.flex-grid--xl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xl-3:not(section), section.flex-grid--xl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-3:not(section).gap--xl-xs, section.flex-grid--xl-3   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-3:not(section).gap--xl-s, section.flex-grid--xl-3   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-3:not(section).gap--xl-m, section.flex-grid--xl-3   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-3:not(section).gap--xl-l, section.flex-grid--xl-3   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-3:not(section).gap--xl-xl, section.flex-grid--xl-3   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-3:not(section).gap--xl-xxl, section.flex-grid--xl-3   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-3:not(section) > *, section.flex-grid--xl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xl-4:not(section), section.flex-grid--xl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-4:not(section).gap--xl-xs, section.flex-grid--xl-4   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-4:not(section).gap--xl-s, section.flex-grid--xl-4   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-4:not(section).gap--xl-m, section.flex-grid--xl-4   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-4:not(section).gap--xl-l, section.flex-grid--xl-4   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-4:not(section).gap--xl-xl, section.flex-grid--xl-4   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-4:not(section).gap--xl-xxl, section.flex-grid--xl-4   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-4:not(section) > *, section.flex-grid--xl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xl-5:not(section), section.flex-grid--xl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-5:not(section).gap--xl-xs, section.flex-grid--xl-5   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-5:not(section).gap--xl-s, section.flex-grid--xl-5   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-5:not(section).gap--xl-m, section.flex-grid--xl-5   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-5:not(section).gap--xl-l, section.flex-grid--xl-5   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-5:not(section).gap--xl-xl, section.flex-grid--xl-5   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-5:not(section).gap--xl-xxl, section.flex-grid--xl-5   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-5:not(section) > *, section.flex-grid--xl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xl-6:not(section), section.flex-grid--xl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-6:not(section).gap--xl-xs, section.flex-grid--xl-6   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-6:not(section).gap--xl-s, section.flex-grid--xl-6   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-6:not(section).gap--xl-m, section.flex-grid--xl-6   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-6:not(section).gap--xl-l, section.flex-grid--xl-6   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-6:not(section).gap--xl-xl, section.flex-grid--xl-6   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-6:not(section).gap--xl-xxl, section.flex-grid--xl-6   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-6:not(section) > *, section.flex-grid--xl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 991px) {
  .flex-grid--l-1:not(section), section.flex-grid--l-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--l-1:not(section) > *, section.flex-grid--l-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--l-2:not(section), section.flex-grid--l-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-2:not(section).gap--l-xs, section.flex-grid--l-2   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-2:not(section).gap--l-s, section.flex-grid--l-2   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-2:not(section).gap--l-m, section.flex-grid--l-2   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-2:not(section).gap--l-l, section.flex-grid--l-2   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-2:not(section).gap--l-xl, section.flex-grid--l-2   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-2:not(section).gap--l-xxl, section.flex-grid--l-2   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-2:not(section) > *, section.flex-grid--l-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--l-3:not(section), section.flex-grid--l-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-3:not(section).gap--l-xs, section.flex-grid--l-3   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-3:not(section).gap--l-s, section.flex-grid--l-3   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-3:not(section).gap--l-m, section.flex-grid--l-3   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-3:not(section).gap--l-l, section.flex-grid--l-3   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-3:not(section).gap--l-xl, section.flex-grid--l-3   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-3:not(section).gap--l-xxl, section.flex-grid--l-3   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-3:not(section) > *, section.flex-grid--l-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--l-4:not(section), section.flex-grid--l-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-4:not(section).gap--l-xs, section.flex-grid--l-4   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-4:not(section).gap--l-s, section.flex-grid--l-4   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-4:not(section).gap--l-m, section.flex-grid--l-4   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-4:not(section).gap--l-l, section.flex-grid--l-4   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-4:not(section).gap--l-xl, section.flex-grid--l-4   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-4:not(section).gap--l-xxl, section.flex-grid--l-4   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-4:not(section) > *, section.flex-grid--l-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--l-5:not(section), section.flex-grid--l-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-5:not(section).gap--l-xs, section.flex-grid--l-5   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-5:not(section).gap--l-s, section.flex-grid--l-5   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-5:not(section).gap--l-m, section.flex-grid--l-5   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-5:not(section).gap--l-l, section.flex-grid--l-5   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-5:not(section).gap--l-xl, section.flex-grid--l-5   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-5:not(section).gap--l-xxl, section.flex-grid--l-5   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-5:not(section) > *, section.flex-grid--l-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--l-6:not(section), section.flex-grid--l-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-6:not(section).gap--l-xs, section.flex-grid--l-6   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-6:not(section).gap--l-s, section.flex-grid--l-6   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-6:not(section).gap--l-m, section.flex-grid--l-6   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-6:not(section).gap--l-l, section.flex-grid--l-6   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-6:not(section).gap--l-xl, section.flex-grid--l-6   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-6:not(section).gap--l-xxl, section.flex-grid--l-6   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-6:not(section) > *, section.flex-grid--l-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 767px) {
  .flex-grid--m-1:not(section), section.flex-grid--m-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--m-1:not(section) > *, section.flex-grid--m-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--m-2:not(section), section.flex-grid--m-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-2:not(section).gap--m-xs, section.flex-grid--m-2   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-2:not(section).gap--m-s, section.flex-grid--m-2   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-2:not(section).gap--m-m, section.flex-grid--m-2   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-2:not(section).gap--m-l, section.flex-grid--m-2   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-2:not(section).gap--m-xl, section.flex-grid--m-2   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-2:not(section).gap--m-xxl, section.flex-grid--m-2   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-2:not(section) > *, section.flex-grid--m-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--m-3:not(section), section.flex-grid--m-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-3:not(section).gap--m-xs, section.flex-grid--m-3   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-3:not(section).gap--m-s, section.flex-grid--m-3   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-3:not(section).gap--m-m, section.flex-grid--m-3   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-3:not(section).gap--m-l, section.flex-grid--m-3   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-3:not(section).gap--m-xl, section.flex-grid--m-3   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-3:not(section).gap--m-xxl, section.flex-grid--m-3   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-3:not(section) > *, section.flex-grid--m-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--m-4:not(section), section.flex-grid--m-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-4:not(section).gap--m-xs, section.flex-grid--m-4   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-4:not(section).gap--m-s, section.flex-grid--m-4   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-4:not(section).gap--m-m, section.flex-grid--m-4   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-4:not(section).gap--m-l, section.flex-grid--m-4   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-4:not(section).gap--m-xl, section.flex-grid--m-4   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-4:not(section).gap--m-xxl, section.flex-grid--m-4   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-4:not(section) > *, section.flex-grid--m-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--m-5:not(section), section.flex-grid--m-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-5:not(section).gap--m-xs, section.flex-grid--m-5   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-5:not(section).gap--m-s, section.flex-grid--m-5   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-5:not(section).gap--m-m, section.flex-grid--m-5   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-5:not(section).gap--m-l, section.flex-grid--m-5   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-5:not(section).gap--m-xl, section.flex-grid--m-5   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-5:not(section).gap--m-xxl, section.flex-grid--m-5   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-5:not(section) > *, section.flex-grid--m-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--m-6:not(section), section.flex-grid--m-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-6:not(section).gap--m-xs, section.flex-grid--m-6   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-6:not(section).gap--m-s, section.flex-grid--m-6   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-6:not(section).gap--m-m, section.flex-grid--m-6   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-6:not(section).gap--m-l, section.flex-grid--m-6   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-6:not(section).gap--m-xl, section.flex-grid--m-6   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-6:not(section).gap--m-xxl, section.flex-grid--m-6   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-6:not(section) > *, section.flex-grid--m-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 479px) {
  .flex-grid--s-1:not(section), section.flex-grid--s-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--s-1:not(section) > *, section.flex-grid--s-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--s-2:not(section), section.flex-grid--s-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-2:not(section).gap--s-xs, section.flex-grid--s-2   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-2:not(section).gap--s-s, section.flex-grid--s-2   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-2:not(section).gap--s-m, section.flex-grid--s-2   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-2:not(section).gap--s-l, section.flex-grid--s-2   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-2:not(section).gap--s-xl, section.flex-grid--s-2   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-2:not(section).gap--s-xxl, section.flex-grid--s-2   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-2:not(section) > *, section.flex-grid--s-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--s-3:not(section), section.flex-grid--s-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-3:not(section).gap--s-xs, section.flex-grid--s-3   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-3:not(section).gap--s-s, section.flex-grid--s-3   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-3:not(section).gap--s-m, section.flex-grid--s-3   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-3:not(section).gap--s-l, section.flex-grid--s-3   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-3:not(section).gap--s-xl, section.flex-grid--s-3   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-3:not(section).gap--s-xxl, section.flex-grid--s-3   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-3:not(section) > *, section.flex-grid--s-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--s-4:not(section), section.flex-grid--s-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-4:not(section).gap--s-xs, section.flex-grid--s-4   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-4:not(section).gap--s-s, section.flex-grid--s-4   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-4:not(section).gap--s-m, section.flex-grid--s-4   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-4:not(section).gap--s-l, section.flex-grid--s-4   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-4:not(section).gap--s-xl, section.flex-grid--s-4   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-4:not(section).gap--s-xxl, section.flex-grid--s-4   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-4:not(section) > *, section.flex-grid--s-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--s-5:not(section), section.flex-grid--s-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-5:not(section).gap--s-xs, section.flex-grid--s-5   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-5:not(section).gap--s-s, section.flex-grid--s-5   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-5:not(section).gap--s-m, section.flex-grid--s-5   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-5:not(section).gap--s-l, section.flex-grid--s-5   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-5:not(section).gap--s-xl, section.flex-grid--s-5   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-5:not(section).gap--s-xxl, section.flex-grid--s-5   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-5:not(section) > *, section.flex-grid--s-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--s-6:not(section), section.flex-grid--s-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-6:not(section).gap--s-xs, section.flex-grid--s-6   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-6:not(section).gap--s-s, section.flex-grid--s-6   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-6:not(section).gap--s-m, section.flex-grid--s-6   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-6:not(section).gap--s-l, section.flex-grid--s-6   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-6:not(section).gap--s-xl, section.flex-grid--s-6   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-6:not(section).gap--s-xxl, section.flex-grid--s-6   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-6:not(section) > *, section.flex-grid--s-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 359px) {
  .flex-grid--xs-1:not(section), section.flex-grid--xs-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xs-1:not(section) > *, section.flex-grid--xs-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xs-2:not(section), section.flex-grid--xs-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-2:not(section).gap--xs-xs, section.flex-grid--xs-2   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-2:not(section).gap--xs-s, section.flex-grid--xs-2   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-2:not(section).gap--xs-m, section.flex-grid--xs-2   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-2:not(section).gap--xs-l, section.flex-grid--xs-2   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-2:not(section).gap--xs-xl, section.flex-grid--xs-2   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-2:not(section).gap--xs-xxl, section.flex-grid--xs-2   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-2:not(section) > *, section.flex-grid--xs-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xs-3:not(section), section.flex-grid--xs-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-3:not(section).gap--xs-xs, section.flex-grid--xs-3   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-3:not(section).gap--xs-s, section.flex-grid--xs-3   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-3:not(section).gap--xs-m, section.flex-grid--xs-3   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-3:not(section).gap--xs-l, section.flex-grid--xs-3   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-3:not(section).gap--xs-xl, section.flex-grid--xs-3   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-3:not(section).gap--xs-xxl, section.flex-grid--xs-3   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-3:not(section) > *, section.flex-grid--xs-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xs-4:not(section), section.flex-grid--xs-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-4:not(section).gap--xs-xs, section.flex-grid--xs-4   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-4:not(section).gap--xs-s, section.flex-grid--xs-4   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-4:not(section).gap--xs-m, section.flex-grid--xs-4   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-4:not(section).gap--xs-l, section.flex-grid--xs-4   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-4:not(section).gap--xs-xl, section.flex-grid--xs-4   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-4:not(section).gap--xs-xxl, section.flex-grid--xs-4   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-4:not(section) > *, section.flex-grid--xs-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xs-5:not(section), section.flex-grid--xs-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-5:not(section).gap--xs-xs, section.flex-grid--xs-5   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-5:not(section).gap--xs-s, section.flex-grid--xs-5   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-5:not(section).gap--xs-m, section.flex-grid--xs-5   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-5:not(section).gap--xs-l, section.flex-grid--xs-5   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-5:not(section).gap--xs-xl, section.flex-grid--xs-5   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-5:not(section).gap--xs-xxl, section.flex-grid--xs-5   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-5:not(section) > *, section.flex-grid--xs-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xs-6:not(section), section.flex-grid--xs-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-6:not(section).gap--xs-xs, section.flex-grid--xs-6   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-6:not(section).gap--xs-s, section.flex-grid--xs-6   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-6:not(section).gap--xs-m, section.flex-grid--xs-6   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-6:not(section).gap--xs-l, section.flex-grid--xs-6   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-6:not(section).gap--xs-xl, section.flex-grid--xs-6   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-6:not(section).gap--xs-xxl, section.flex-grid--xs-6   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-6:not(section) > *, section.flex-grid--xs-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
.flex-grid--4:not(section), section.flex-grid--4    {
  --gap: var(--grid-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}
.flex-grid--4:not(section).gap--xs, section.flex-grid--4   .gap--xs {
  --gap: var(--space-xs);
}
.flex-grid--4:not(section).gap--s, section.flex-grid--4   .gap--s {
  --gap: var(--space-s);
}
.flex-grid--4:not(section).gap--m, section.flex-grid--4   .gap--m {
  --gap: var(--space-m);
}
.flex-grid--4:not(section).gap--l, section.flex-grid--4   .gap--l {
  --gap: var(--space-l);
}
.flex-grid--4:not(section).gap--xl, section.flex-grid--4   .gap--xl {
  --gap: var(--space-xl);
}
.flex-grid--4:not(section).gap--xxl, section.flex-grid--4   .gap--xxl {
  --gap: var(--space-xxl);
}
.flex-grid--4:not(section) > *, section.flex-grid--4    > * {
  width: 100%;
  max-width: calc((100% - (var(--gap) * 3)) / 4);
}
@media (max-width: 1439px) {
  .flex-grid--xxl-1:not(section), section.flex-grid--xxl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xxl-1:not(section) > *, section.flex-grid--xxl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xxl-2:not(section), section.flex-grid--xxl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xs, section.flex-grid--xxl-2   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-s, section.flex-grid--xxl-2   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-m, section.flex-grid--xxl-2   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-l, section.flex-grid--xxl-2   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xl, section.flex-grid--xxl-2   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xxl, section.flex-grid--xxl-2   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-2:not(section) > *, section.flex-grid--xxl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xxl-3:not(section), section.flex-grid--xxl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xs, section.flex-grid--xxl-3   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-s, section.flex-grid--xxl-3   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-m, section.flex-grid--xxl-3   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-l, section.flex-grid--xxl-3   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xl, section.flex-grid--xxl-3   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xxl, section.flex-grid--xxl-3   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-3:not(section) > *, section.flex-grid--xxl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xxl-4:not(section), section.flex-grid--xxl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xs, section.flex-grid--xxl-4   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-s, section.flex-grid--xxl-4   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-m, section.flex-grid--xxl-4   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-l, section.flex-grid--xxl-4   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xl, section.flex-grid--xxl-4   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xxl, section.flex-grid--xxl-4   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-4:not(section) > *, section.flex-grid--xxl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xxl-5:not(section), section.flex-grid--xxl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xs, section.flex-grid--xxl-5   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-s, section.flex-grid--xxl-5   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-m, section.flex-grid--xxl-5   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-l, section.flex-grid--xxl-5   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xl, section.flex-grid--xxl-5   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xxl, section.flex-grid--xxl-5   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-5:not(section) > *, section.flex-grid--xxl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xxl-6:not(section), section.flex-grid--xxl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xs, section.flex-grid--xxl-6   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-s, section.flex-grid--xxl-6   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-m, section.flex-grid--xxl-6   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-l, section.flex-grid--xxl-6   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xl, section.flex-grid--xxl-6   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xxl, section.flex-grid--xxl-6   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-6:not(section) > *, section.flex-grid--xxl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 1279px) {
  .flex-grid--xl-1:not(section), section.flex-grid--xl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xl-1:not(section) > *, section.flex-grid--xl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xl-2:not(section), section.flex-grid--xl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-2:not(section).gap--xl-xs, section.flex-grid--xl-2   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-2:not(section).gap--xl-s, section.flex-grid--xl-2   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-2:not(section).gap--xl-m, section.flex-grid--xl-2   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-2:not(section).gap--xl-l, section.flex-grid--xl-2   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-2:not(section).gap--xl-xl, section.flex-grid--xl-2   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-2:not(section).gap--xl-xxl, section.flex-grid--xl-2   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-2:not(section) > *, section.flex-grid--xl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xl-3:not(section), section.flex-grid--xl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-3:not(section).gap--xl-xs, section.flex-grid--xl-3   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-3:not(section).gap--xl-s, section.flex-grid--xl-3   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-3:not(section).gap--xl-m, section.flex-grid--xl-3   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-3:not(section).gap--xl-l, section.flex-grid--xl-3   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-3:not(section).gap--xl-xl, section.flex-grid--xl-3   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-3:not(section).gap--xl-xxl, section.flex-grid--xl-3   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-3:not(section) > *, section.flex-grid--xl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xl-4:not(section), section.flex-grid--xl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-4:not(section).gap--xl-xs, section.flex-grid--xl-4   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-4:not(section).gap--xl-s, section.flex-grid--xl-4   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-4:not(section).gap--xl-m, section.flex-grid--xl-4   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-4:not(section).gap--xl-l, section.flex-grid--xl-4   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-4:not(section).gap--xl-xl, section.flex-grid--xl-4   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-4:not(section).gap--xl-xxl, section.flex-grid--xl-4   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-4:not(section) > *, section.flex-grid--xl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xl-5:not(section), section.flex-grid--xl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-5:not(section).gap--xl-xs, section.flex-grid--xl-5   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-5:not(section).gap--xl-s, section.flex-grid--xl-5   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-5:not(section).gap--xl-m, section.flex-grid--xl-5   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-5:not(section).gap--xl-l, section.flex-grid--xl-5   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-5:not(section).gap--xl-xl, section.flex-grid--xl-5   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-5:not(section).gap--xl-xxl, section.flex-grid--xl-5   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-5:not(section) > *, section.flex-grid--xl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xl-6:not(section), section.flex-grid--xl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-6:not(section).gap--xl-xs, section.flex-grid--xl-6   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-6:not(section).gap--xl-s, section.flex-grid--xl-6   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-6:not(section).gap--xl-m, section.flex-grid--xl-6   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-6:not(section).gap--xl-l, section.flex-grid--xl-6   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-6:not(section).gap--xl-xl, section.flex-grid--xl-6   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-6:not(section).gap--xl-xxl, section.flex-grid--xl-6   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-6:not(section) > *, section.flex-grid--xl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 991px) {
  .flex-grid--l-1:not(section), section.flex-grid--l-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--l-1:not(section) > *, section.flex-grid--l-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--l-2:not(section), section.flex-grid--l-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-2:not(section).gap--l-xs, section.flex-grid--l-2   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-2:not(section).gap--l-s, section.flex-grid--l-2   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-2:not(section).gap--l-m, section.flex-grid--l-2   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-2:not(section).gap--l-l, section.flex-grid--l-2   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-2:not(section).gap--l-xl, section.flex-grid--l-2   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-2:not(section).gap--l-xxl, section.flex-grid--l-2   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-2:not(section) > *, section.flex-grid--l-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--l-3:not(section), section.flex-grid--l-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-3:not(section).gap--l-xs, section.flex-grid--l-3   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-3:not(section).gap--l-s, section.flex-grid--l-3   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-3:not(section).gap--l-m, section.flex-grid--l-3   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-3:not(section).gap--l-l, section.flex-grid--l-3   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-3:not(section).gap--l-xl, section.flex-grid--l-3   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-3:not(section).gap--l-xxl, section.flex-grid--l-3   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-3:not(section) > *, section.flex-grid--l-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--l-4:not(section), section.flex-grid--l-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-4:not(section).gap--l-xs, section.flex-grid--l-4   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-4:not(section).gap--l-s, section.flex-grid--l-4   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-4:not(section).gap--l-m, section.flex-grid--l-4   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-4:not(section).gap--l-l, section.flex-grid--l-4   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-4:not(section).gap--l-xl, section.flex-grid--l-4   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-4:not(section).gap--l-xxl, section.flex-grid--l-4   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-4:not(section) > *, section.flex-grid--l-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--l-5:not(section), section.flex-grid--l-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-5:not(section).gap--l-xs, section.flex-grid--l-5   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-5:not(section).gap--l-s, section.flex-grid--l-5   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-5:not(section).gap--l-m, section.flex-grid--l-5   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-5:not(section).gap--l-l, section.flex-grid--l-5   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-5:not(section).gap--l-xl, section.flex-grid--l-5   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-5:not(section).gap--l-xxl, section.flex-grid--l-5   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-5:not(section) > *, section.flex-grid--l-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--l-6:not(section), section.flex-grid--l-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-6:not(section).gap--l-xs, section.flex-grid--l-6   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-6:not(section).gap--l-s, section.flex-grid--l-6   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-6:not(section).gap--l-m, section.flex-grid--l-6   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-6:not(section).gap--l-l, section.flex-grid--l-6   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-6:not(section).gap--l-xl, section.flex-grid--l-6   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-6:not(section).gap--l-xxl, section.flex-grid--l-6   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-6:not(section) > *, section.flex-grid--l-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 767px) {
  .flex-grid--m-1:not(section), section.flex-grid--m-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--m-1:not(section) > *, section.flex-grid--m-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--m-2:not(section), section.flex-grid--m-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-2:not(section).gap--m-xs, section.flex-grid--m-2   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-2:not(section).gap--m-s, section.flex-grid--m-2   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-2:not(section).gap--m-m, section.flex-grid--m-2   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-2:not(section).gap--m-l, section.flex-grid--m-2   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-2:not(section).gap--m-xl, section.flex-grid--m-2   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-2:not(section).gap--m-xxl, section.flex-grid--m-2   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-2:not(section) > *, section.flex-grid--m-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--m-3:not(section), section.flex-grid--m-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-3:not(section).gap--m-xs, section.flex-grid--m-3   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-3:not(section).gap--m-s, section.flex-grid--m-3   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-3:not(section).gap--m-m, section.flex-grid--m-3   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-3:not(section).gap--m-l, section.flex-grid--m-3   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-3:not(section).gap--m-xl, section.flex-grid--m-3   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-3:not(section).gap--m-xxl, section.flex-grid--m-3   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-3:not(section) > *, section.flex-grid--m-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--m-4:not(section), section.flex-grid--m-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-4:not(section).gap--m-xs, section.flex-grid--m-4   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-4:not(section).gap--m-s, section.flex-grid--m-4   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-4:not(section).gap--m-m, section.flex-grid--m-4   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-4:not(section).gap--m-l, section.flex-grid--m-4   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-4:not(section).gap--m-xl, section.flex-grid--m-4   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-4:not(section).gap--m-xxl, section.flex-grid--m-4   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-4:not(section) > *, section.flex-grid--m-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--m-5:not(section), section.flex-grid--m-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-5:not(section).gap--m-xs, section.flex-grid--m-5   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-5:not(section).gap--m-s, section.flex-grid--m-5   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-5:not(section).gap--m-m, section.flex-grid--m-5   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-5:not(section).gap--m-l, section.flex-grid--m-5   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-5:not(section).gap--m-xl, section.flex-grid--m-5   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-5:not(section).gap--m-xxl, section.flex-grid--m-5   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-5:not(section) > *, section.flex-grid--m-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--m-6:not(section), section.flex-grid--m-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-6:not(section).gap--m-xs, section.flex-grid--m-6   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-6:not(section).gap--m-s, section.flex-grid--m-6   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-6:not(section).gap--m-m, section.flex-grid--m-6   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-6:not(section).gap--m-l, section.flex-grid--m-6   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-6:not(section).gap--m-xl, section.flex-grid--m-6   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-6:not(section).gap--m-xxl, section.flex-grid--m-6   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-6:not(section) > *, section.flex-grid--m-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 479px) {
  .flex-grid--s-1:not(section), section.flex-grid--s-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--s-1:not(section) > *, section.flex-grid--s-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--s-2:not(section), section.flex-grid--s-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-2:not(section).gap--s-xs, section.flex-grid--s-2   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-2:not(section).gap--s-s, section.flex-grid--s-2   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-2:not(section).gap--s-m, section.flex-grid--s-2   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-2:not(section).gap--s-l, section.flex-grid--s-2   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-2:not(section).gap--s-xl, section.flex-grid--s-2   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-2:not(section).gap--s-xxl, section.flex-grid--s-2   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-2:not(section) > *, section.flex-grid--s-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--s-3:not(section), section.flex-grid--s-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-3:not(section).gap--s-xs, section.flex-grid--s-3   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-3:not(section).gap--s-s, section.flex-grid--s-3   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-3:not(section).gap--s-m, section.flex-grid--s-3   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-3:not(section).gap--s-l, section.flex-grid--s-3   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-3:not(section).gap--s-xl, section.flex-grid--s-3   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-3:not(section).gap--s-xxl, section.flex-grid--s-3   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-3:not(section) > *, section.flex-grid--s-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--s-4:not(section), section.flex-grid--s-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-4:not(section).gap--s-xs, section.flex-grid--s-4   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-4:not(section).gap--s-s, section.flex-grid--s-4   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-4:not(section).gap--s-m, section.flex-grid--s-4   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-4:not(section).gap--s-l, section.flex-grid--s-4   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-4:not(section).gap--s-xl, section.flex-grid--s-4   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-4:not(section).gap--s-xxl, section.flex-grid--s-4   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-4:not(section) > *, section.flex-grid--s-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--s-5:not(section), section.flex-grid--s-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-5:not(section).gap--s-xs, section.flex-grid--s-5   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-5:not(section).gap--s-s, section.flex-grid--s-5   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-5:not(section).gap--s-m, section.flex-grid--s-5   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-5:not(section).gap--s-l, section.flex-grid--s-5   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-5:not(section).gap--s-xl, section.flex-grid--s-5   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-5:not(section).gap--s-xxl, section.flex-grid--s-5   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-5:not(section) > *, section.flex-grid--s-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--s-6:not(section), section.flex-grid--s-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-6:not(section).gap--s-xs, section.flex-grid--s-6   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-6:not(section).gap--s-s, section.flex-grid--s-6   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-6:not(section).gap--s-m, section.flex-grid--s-6   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-6:not(section).gap--s-l, section.flex-grid--s-6   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-6:not(section).gap--s-xl, section.flex-grid--s-6   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-6:not(section).gap--s-xxl, section.flex-grid--s-6   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-6:not(section) > *, section.flex-grid--s-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 359px) {
  .flex-grid--xs-1:not(section), section.flex-grid--xs-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xs-1:not(section) > *, section.flex-grid--xs-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xs-2:not(section), section.flex-grid--xs-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-2:not(section).gap--xs-xs, section.flex-grid--xs-2   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-2:not(section).gap--xs-s, section.flex-grid--xs-2   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-2:not(section).gap--xs-m, section.flex-grid--xs-2   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-2:not(section).gap--xs-l, section.flex-grid--xs-2   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-2:not(section).gap--xs-xl, section.flex-grid--xs-2   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-2:not(section).gap--xs-xxl, section.flex-grid--xs-2   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-2:not(section) > *, section.flex-grid--xs-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xs-3:not(section), section.flex-grid--xs-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-3:not(section).gap--xs-xs, section.flex-grid--xs-3   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-3:not(section).gap--xs-s, section.flex-grid--xs-3   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-3:not(section).gap--xs-m, section.flex-grid--xs-3   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-3:not(section).gap--xs-l, section.flex-grid--xs-3   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-3:not(section).gap--xs-xl, section.flex-grid--xs-3   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-3:not(section).gap--xs-xxl, section.flex-grid--xs-3   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-3:not(section) > *, section.flex-grid--xs-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xs-4:not(section), section.flex-grid--xs-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-4:not(section).gap--xs-xs, section.flex-grid--xs-4   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-4:not(section).gap--xs-s, section.flex-grid--xs-4   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-4:not(section).gap--xs-m, section.flex-grid--xs-4   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-4:not(section).gap--xs-l, section.flex-grid--xs-4   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-4:not(section).gap--xs-xl, section.flex-grid--xs-4   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-4:not(section).gap--xs-xxl, section.flex-grid--xs-4   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-4:not(section) > *, section.flex-grid--xs-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xs-5:not(section), section.flex-grid--xs-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-5:not(section).gap--xs-xs, section.flex-grid--xs-5   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-5:not(section).gap--xs-s, section.flex-grid--xs-5   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-5:not(section).gap--xs-m, section.flex-grid--xs-5   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-5:not(section).gap--xs-l, section.flex-grid--xs-5   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-5:not(section).gap--xs-xl, section.flex-grid--xs-5   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-5:not(section).gap--xs-xxl, section.flex-grid--xs-5   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-5:not(section) > *, section.flex-grid--xs-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xs-6:not(section), section.flex-grid--xs-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-6:not(section).gap--xs-xs, section.flex-grid--xs-6   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-6:not(section).gap--xs-s, section.flex-grid--xs-6   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-6:not(section).gap--xs-m, section.flex-grid--xs-6   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-6:not(section).gap--xs-l, section.flex-grid--xs-6   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-6:not(section).gap--xs-xl, section.flex-grid--xs-6   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-6:not(section).gap--xs-xxl, section.flex-grid--xs-6   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-6:not(section) > *, section.flex-grid--xs-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
.flex-grid--5:not(section), section.flex-grid--5    {
  --gap: var(--grid-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}
.flex-grid--5:not(section).gap--xs, section.flex-grid--5   .gap--xs {
  --gap: var(--space-xs);
}
.flex-grid--5:not(section).gap--s, section.flex-grid--5   .gap--s {
  --gap: var(--space-s);
}
.flex-grid--5:not(section).gap--m, section.flex-grid--5   .gap--m {
  --gap: var(--space-m);
}
.flex-grid--5:not(section).gap--l, section.flex-grid--5   .gap--l {
  --gap: var(--space-l);
}
.flex-grid--5:not(section).gap--xl, section.flex-grid--5   .gap--xl {
  --gap: var(--space-xl);
}
.flex-grid--5:not(section).gap--xxl, section.flex-grid--5   .gap--xxl {
  --gap: var(--space-xxl);
}
.flex-grid--5:not(section) > *, section.flex-grid--5    > * {
  width: 100%;
  max-width: calc((100% - (var(--gap) * 4)) / 5);
}
@media (max-width: 1439px) {
  .flex-grid--xxl-1:not(section), section.flex-grid--xxl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xxl-1:not(section) > *, section.flex-grid--xxl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xxl-2:not(section), section.flex-grid--xxl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xs, section.flex-grid--xxl-2   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-s, section.flex-grid--xxl-2   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-m, section.flex-grid--xxl-2   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-l, section.flex-grid--xxl-2   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xl, section.flex-grid--xxl-2   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xxl, section.flex-grid--xxl-2   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-2:not(section) > *, section.flex-grid--xxl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xxl-3:not(section), section.flex-grid--xxl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xs, section.flex-grid--xxl-3   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-s, section.flex-grid--xxl-3   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-m, section.flex-grid--xxl-3   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-l, section.flex-grid--xxl-3   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xl, section.flex-grid--xxl-3   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xxl, section.flex-grid--xxl-3   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-3:not(section) > *, section.flex-grid--xxl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xxl-4:not(section), section.flex-grid--xxl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xs, section.flex-grid--xxl-4   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-s, section.flex-grid--xxl-4   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-m, section.flex-grid--xxl-4   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-l, section.flex-grid--xxl-4   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xl, section.flex-grid--xxl-4   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xxl, section.flex-grid--xxl-4   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-4:not(section) > *, section.flex-grid--xxl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xxl-5:not(section), section.flex-grid--xxl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xs, section.flex-grid--xxl-5   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-s, section.flex-grid--xxl-5   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-m, section.flex-grid--xxl-5   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-l, section.flex-grid--xxl-5   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xl, section.flex-grid--xxl-5   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xxl, section.flex-grid--xxl-5   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-5:not(section) > *, section.flex-grid--xxl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xxl-6:not(section), section.flex-grid--xxl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xs, section.flex-grid--xxl-6   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-s, section.flex-grid--xxl-6   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-m, section.flex-grid--xxl-6   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-l, section.flex-grid--xxl-6   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xl, section.flex-grid--xxl-6   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xxl, section.flex-grid--xxl-6   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-6:not(section) > *, section.flex-grid--xxl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 1279px) {
  .flex-grid--xl-1:not(section), section.flex-grid--xl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xl-1:not(section) > *, section.flex-grid--xl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xl-2:not(section), section.flex-grid--xl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-2:not(section).gap--xl-xs, section.flex-grid--xl-2   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-2:not(section).gap--xl-s, section.flex-grid--xl-2   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-2:not(section).gap--xl-m, section.flex-grid--xl-2   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-2:not(section).gap--xl-l, section.flex-grid--xl-2   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-2:not(section).gap--xl-xl, section.flex-grid--xl-2   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-2:not(section).gap--xl-xxl, section.flex-grid--xl-2   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-2:not(section) > *, section.flex-grid--xl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xl-3:not(section), section.flex-grid--xl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-3:not(section).gap--xl-xs, section.flex-grid--xl-3   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-3:not(section).gap--xl-s, section.flex-grid--xl-3   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-3:not(section).gap--xl-m, section.flex-grid--xl-3   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-3:not(section).gap--xl-l, section.flex-grid--xl-3   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-3:not(section).gap--xl-xl, section.flex-grid--xl-3   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-3:not(section).gap--xl-xxl, section.flex-grid--xl-3   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-3:not(section) > *, section.flex-grid--xl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xl-4:not(section), section.flex-grid--xl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-4:not(section).gap--xl-xs, section.flex-grid--xl-4   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-4:not(section).gap--xl-s, section.flex-grid--xl-4   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-4:not(section).gap--xl-m, section.flex-grid--xl-4   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-4:not(section).gap--xl-l, section.flex-grid--xl-4   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-4:not(section).gap--xl-xl, section.flex-grid--xl-4   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-4:not(section).gap--xl-xxl, section.flex-grid--xl-4   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-4:not(section) > *, section.flex-grid--xl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xl-5:not(section), section.flex-grid--xl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-5:not(section).gap--xl-xs, section.flex-grid--xl-5   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-5:not(section).gap--xl-s, section.flex-grid--xl-5   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-5:not(section).gap--xl-m, section.flex-grid--xl-5   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-5:not(section).gap--xl-l, section.flex-grid--xl-5   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-5:not(section).gap--xl-xl, section.flex-grid--xl-5   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-5:not(section).gap--xl-xxl, section.flex-grid--xl-5   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-5:not(section) > *, section.flex-grid--xl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xl-6:not(section), section.flex-grid--xl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-6:not(section).gap--xl-xs, section.flex-grid--xl-6   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-6:not(section).gap--xl-s, section.flex-grid--xl-6   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-6:not(section).gap--xl-m, section.flex-grid--xl-6   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-6:not(section).gap--xl-l, section.flex-grid--xl-6   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-6:not(section).gap--xl-xl, section.flex-grid--xl-6   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-6:not(section).gap--xl-xxl, section.flex-grid--xl-6   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-6:not(section) > *, section.flex-grid--xl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 991px) {
  .flex-grid--l-1:not(section), section.flex-grid--l-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--l-1:not(section) > *, section.flex-grid--l-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--l-2:not(section), section.flex-grid--l-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-2:not(section).gap--l-xs, section.flex-grid--l-2   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-2:not(section).gap--l-s, section.flex-grid--l-2   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-2:not(section).gap--l-m, section.flex-grid--l-2   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-2:not(section).gap--l-l, section.flex-grid--l-2   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-2:not(section).gap--l-xl, section.flex-grid--l-2   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-2:not(section).gap--l-xxl, section.flex-grid--l-2   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-2:not(section) > *, section.flex-grid--l-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--l-3:not(section), section.flex-grid--l-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-3:not(section).gap--l-xs, section.flex-grid--l-3   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-3:not(section).gap--l-s, section.flex-grid--l-3   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-3:not(section).gap--l-m, section.flex-grid--l-3   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-3:not(section).gap--l-l, section.flex-grid--l-3   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-3:not(section).gap--l-xl, section.flex-grid--l-3   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-3:not(section).gap--l-xxl, section.flex-grid--l-3   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-3:not(section) > *, section.flex-grid--l-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--l-4:not(section), section.flex-grid--l-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-4:not(section).gap--l-xs, section.flex-grid--l-4   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-4:not(section).gap--l-s, section.flex-grid--l-4   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-4:not(section).gap--l-m, section.flex-grid--l-4   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-4:not(section).gap--l-l, section.flex-grid--l-4   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-4:not(section).gap--l-xl, section.flex-grid--l-4   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-4:not(section).gap--l-xxl, section.flex-grid--l-4   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-4:not(section) > *, section.flex-grid--l-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--l-5:not(section), section.flex-grid--l-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-5:not(section).gap--l-xs, section.flex-grid--l-5   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-5:not(section).gap--l-s, section.flex-grid--l-5   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-5:not(section).gap--l-m, section.flex-grid--l-5   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-5:not(section).gap--l-l, section.flex-grid--l-5   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-5:not(section).gap--l-xl, section.flex-grid--l-5   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-5:not(section).gap--l-xxl, section.flex-grid--l-5   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-5:not(section) > *, section.flex-grid--l-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--l-6:not(section), section.flex-grid--l-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-6:not(section).gap--l-xs, section.flex-grid--l-6   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-6:not(section).gap--l-s, section.flex-grid--l-6   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-6:not(section).gap--l-m, section.flex-grid--l-6   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-6:not(section).gap--l-l, section.flex-grid--l-6   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-6:not(section).gap--l-xl, section.flex-grid--l-6   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-6:not(section).gap--l-xxl, section.flex-grid--l-6   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-6:not(section) > *, section.flex-grid--l-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 767px) {
  .flex-grid--m-1:not(section), section.flex-grid--m-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--m-1:not(section) > *, section.flex-grid--m-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--m-2:not(section), section.flex-grid--m-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-2:not(section).gap--m-xs, section.flex-grid--m-2   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-2:not(section).gap--m-s, section.flex-grid--m-2   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-2:not(section).gap--m-m, section.flex-grid--m-2   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-2:not(section).gap--m-l, section.flex-grid--m-2   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-2:not(section).gap--m-xl, section.flex-grid--m-2   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-2:not(section).gap--m-xxl, section.flex-grid--m-2   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-2:not(section) > *, section.flex-grid--m-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--m-3:not(section), section.flex-grid--m-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-3:not(section).gap--m-xs, section.flex-grid--m-3   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-3:not(section).gap--m-s, section.flex-grid--m-3   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-3:not(section).gap--m-m, section.flex-grid--m-3   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-3:not(section).gap--m-l, section.flex-grid--m-3   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-3:not(section).gap--m-xl, section.flex-grid--m-3   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-3:not(section).gap--m-xxl, section.flex-grid--m-3   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-3:not(section) > *, section.flex-grid--m-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--m-4:not(section), section.flex-grid--m-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-4:not(section).gap--m-xs, section.flex-grid--m-4   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-4:not(section).gap--m-s, section.flex-grid--m-4   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-4:not(section).gap--m-m, section.flex-grid--m-4   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-4:not(section).gap--m-l, section.flex-grid--m-4   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-4:not(section).gap--m-xl, section.flex-grid--m-4   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-4:not(section).gap--m-xxl, section.flex-grid--m-4   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-4:not(section) > *, section.flex-grid--m-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--m-5:not(section), section.flex-grid--m-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-5:not(section).gap--m-xs, section.flex-grid--m-5   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-5:not(section).gap--m-s, section.flex-grid--m-5   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-5:not(section).gap--m-m, section.flex-grid--m-5   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-5:not(section).gap--m-l, section.flex-grid--m-5   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-5:not(section).gap--m-xl, section.flex-grid--m-5   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-5:not(section).gap--m-xxl, section.flex-grid--m-5   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-5:not(section) > *, section.flex-grid--m-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--m-6:not(section), section.flex-grid--m-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-6:not(section).gap--m-xs, section.flex-grid--m-6   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-6:not(section).gap--m-s, section.flex-grid--m-6   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-6:not(section).gap--m-m, section.flex-grid--m-6   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-6:not(section).gap--m-l, section.flex-grid--m-6   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-6:not(section).gap--m-xl, section.flex-grid--m-6   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-6:not(section).gap--m-xxl, section.flex-grid--m-6   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-6:not(section) > *, section.flex-grid--m-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 479px) {
  .flex-grid--s-1:not(section), section.flex-grid--s-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--s-1:not(section) > *, section.flex-grid--s-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--s-2:not(section), section.flex-grid--s-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-2:not(section).gap--s-xs, section.flex-grid--s-2   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-2:not(section).gap--s-s, section.flex-grid--s-2   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-2:not(section).gap--s-m, section.flex-grid--s-2   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-2:not(section).gap--s-l, section.flex-grid--s-2   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-2:not(section).gap--s-xl, section.flex-grid--s-2   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-2:not(section).gap--s-xxl, section.flex-grid--s-2   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-2:not(section) > *, section.flex-grid--s-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--s-3:not(section), section.flex-grid--s-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-3:not(section).gap--s-xs, section.flex-grid--s-3   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-3:not(section).gap--s-s, section.flex-grid--s-3   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-3:not(section).gap--s-m, section.flex-grid--s-3   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-3:not(section).gap--s-l, section.flex-grid--s-3   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-3:not(section).gap--s-xl, section.flex-grid--s-3   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-3:not(section).gap--s-xxl, section.flex-grid--s-3   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-3:not(section) > *, section.flex-grid--s-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--s-4:not(section), section.flex-grid--s-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-4:not(section).gap--s-xs, section.flex-grid--s-4   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-4:not(section).gap--s-s, section.flex-grid--s-4   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-4:not(section).gap--s-m, section.flex-grid--s-4   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-4:not(section).gap--s-l, section.flex-grid--s-4   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-4:not(section).gap--s-xl, section.flex-grid--s-4   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-4:not(section).gap--s-xxl, section.flex-grid--s-4   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-4:not(section) > *, section.flex-grid--s-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--s-5:not(section), section.flex-grid--s-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-5:not(section).gap--s-xs, section.flex-grid--s-5   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-5:not(section).gap--s-s, section.flex-grid--s-5   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-5:not(section).gap--s-m, section.flex-grid--s-5   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-5:not(section).gap--s-l, section.flex-grid--s-5   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-5:not(section).gap--s-xl, section.flex-grid--s-5   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-5:not(section).gap--s-xxl, section.flex-grid--s-5   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-5:not(section) > *, section.flex-grid--s-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--s-6:not(section), section.flex-grid--s-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-6:not(section).gap--s-xs, section.flex-grid--s-6   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-6:not(section).gap--s-s, section.flex-grid--s-6   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-6:not(section).gap--s-m, section.flex-grid--s-6   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-6:not(section).gap--s-l, section.flex-grid--s-6   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-6:not(section).gap--s-xl, section.flex-grid--s-6   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-6:not(section).gap--s-xxl, section.flex-grid--s-6   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-6:not(section) > *, section.flex-grid--s-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 359px) {
  .flex-grid--xs-1:not(section), section.flex-grid--xs-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xs-1:not(section) > *, section.flex-grid--xs-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xs-2:not(section), section.flex-grid--xs-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-2:not(section).gap--xs-xs, section.flex-grid--xs-2   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-2:not(section).gap--xs-s, section.flex-grid--xs-2   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-2:not(section).gap--xs-m, section.flex-grid--xs-2   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-2:not(section).gap--xs-l, section.flex-grid--xs-2   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-2:not(section).gap--xs-xl, section.flex-grid--xs-2   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-2:not(section).gap--xs-xxl, section.flex-grid--xs-2   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-2:not(section) > *, section.flex-grid--xs-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xs-3:not(section), section.flex-grid--xs-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-3:not(section).gap--xs-xs, section.flex-grid--xs-3   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-3:not(section).gap--xs-s, section.flex-grid--xs-3   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-3:not(section).gap--xs-m, section.flex-grid--xs-3   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-3:not(section).gap--xs-l, section.flex-grid--xs-3   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-3:not(section).gap--xs-xl, section.flex-grid--xs-3   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-3:not(section).gap--xs-xxl, section.flex-grid--xs-3   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-3:not(section) > *, section.flex-grid--xs-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xs-4:not(section), section.flex-grid--xs-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-4:not(section).gap--xs-xs, section.flex-grid--xs-4   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-4:not(section).gap--xs-s, section.flex-grid--xs-4   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-4:not(section).gap--xs-m, section.flex-grid--xs-4   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-4:not(section).gap--xs-l, section.flex-grid--xs-4   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-4:not(section).gap--xs-xl, section.flex-grid--xs-4   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-4:not(section).gap--xs-xxl, section.flex-grid--xs-4   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-4:not(section) > *, section.flex-grid--xs-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xs-5:not(section), section.flex-grid--xs-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-5:not(section).gap--xs-xs, section.flex-grid--xs-5   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-5:not(section).gap--xs-s, section.flex-grid--xs-5   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-5:not(section).gap--xs-m, section.flex-grid--xs-5   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-5:not(section).gap--xs-l, section.flex-grid--xs-5   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-5:not(section).gap--xs-xl, section.flex-grid--xs-5   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-5:not(section).gap--xs-xxl, section.flex-grid--xs-5   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-5:not(section) > *, section.flex-grid--xs-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xs-6:not(section), section.flex-grid--xs-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-6:not(section).gap--xs-xs, section.flex-grid--xs-6   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-6:not(section).gap--xs-s, section.flex-grid--xs-6   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-6:not(section).gap--xs-m, section.flex-grid--xs-6   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-6:not(section).gap--xs-l, section.flex-grid--xs-6   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-6:not(section).gap--xs-xl, section.flex-grid--xs-6   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-6:not(section).gap--xs-xxl, section.flex-grid--xs-6   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-6:not(section) > *, section.flex-grid--xs-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
.flex-grid--6:not(section), section.flex-grid--6    {
  --gap: var(--grid-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}
.flex-grid--6:not(section).gap--xs, section.flex-grid--6   .gap--xs {
  --gap: var(--space-xs);
}
.flex-grid--6:not(section).gap--s, section.flex-grid--6   .gap--s {
  --gap: var(--space-s);
}
.flex-grid--6:not(section).gap--m, section.flex-grid--6   .gap--m {
  --gap: var(--space-m);
}
.flex-grid--6:not(section).gap--l, section.flex-grid--6   .gap--l {
  --gap: var(--space-l);
}
.flex-grid--6:not(section).gap--xl, section.flex-grid--6   .gap--xl {
  --gap: var(--space-xl);
}
.flex-grid--6:not(section).gap--xxl, section.flex-grid--6   .gap--xxl {
  --gap: var(--space-xxl);
}
.flex-grid--6:not(section) > *, section.flex-grid--6    > * {
  width: 100%;
  max-width: calc((100% - (var(--gap) * 5)) / 6);
}
@media (max-width: 1439px) {
  .flex-grid--xxl-1:not(section), section.flex-grid--xxl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xxl-1:not(section) > *, section.flex-grid--xxl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xxl-2:not(section), section.flex-grid--xxl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xs, section.flex-grid--xxl-2   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-s, section.flex-grid--xxl-2   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-m, section.flex-grid--xxl-2   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-l, section.flex-grid--xxl-2   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xl, section.flex-grid--xxl-2   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-2:not(section).gap--xxl-xxl, section.flex-grid--xxl-2   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-2:not(section) > *, section.flex-grid--xxl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xxl-3:not(section), section.flex-grid--xxl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xs, section.flex-grid--xxl-3   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-s, section.flex-grid--xxl-3   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-m, section.flex-grid--xxl-3   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-l, section.flex-grid--xxl-3   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xl, section.flex-grid--xxl-3   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-3:not(section).gap--xxl-xxl, section.flex-grid--xxl-3   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-3:not(section) > *, section.flex-grid--xxl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xxl-4:not(section), section.flex-grid--xxl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xs, section.flex-grid--xxl-4   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-s, section.flex-grid--xxl-4   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-m, section.flex-grid--xxl-4   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-l, section.flex-grid--xxl-4   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xl, section.flex-grid--xxl-4   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-4:not(section).gap--xxl-xxl, section.flex-grid--xxl-4   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-4:not(section) > *, section.flex-grid--xxl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xxl-5:not(section), section.flex-grid--xxl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xs, section.flex-grid--xxl-5   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-s, section.flex-grid--xxl-5   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-m, section.flex-grid--xxl-5   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-l, section.flex-grid--xxl-5   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xl, section.flex-grid--xxl-5   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-5:not(section).gap--xxl-xxl, section.flex-grid--xxl-5   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-5:not(section) > *, section.flex-grid--xxl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xxl-6:not(section), section.flex-grid--xxl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xs, section.flex-grid--xxl-6   .gap--xxl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-s, section.flex-grid--xxl-6   .gap--xxl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-m, section.flex-grid--xxl-6   .gap--xxl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-l, section.flex-grid--xxl-6   .gap--xxl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xl, section.flex-grid--xxl-6   .gap--xxl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xxl-6:not(section).gap--xxl-xxl, section.flex-grid--xxl-6   .gap--xxl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xxl-6:not(section) > *, section.flex-grid--xxl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 1279px) {
  .flex-grid--xl-1:not(section), section.flex-grid--xl-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xl-1:not(section) > *, section.flex-grid--xl-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xl-2:not(section), section.flex-grid--xl-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-2:not(section).gap--xl-xs, section.flex-grid--xl-2   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-2:not(section).gap--xl-s, section.flex-grid--xl-2   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-2:not(section).gap--xl-m, section.flex-grid--xl-2   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-2:not(section).gap--xl-l, section.flex-grid--xl-2   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-2:not(section).gap--xl-xl, section.flex-grid--xl-2   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-2:not(section).gap--xl-xxl, section.flex-grid--xl-2   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-2:not(section) > *, section.flex-grid--xl-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xl-3:not(section), section.flex-grid--xl-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-3:not(section).gap--xl-xs, section.flex-grid--xl-3   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-3:not(section).gap--xl-s, section.flex-grid--xl-3   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-3:not(section).gap--xl-m, section.flex-grid--xl-3   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-3:not(section).gap--xl-l, section.flex-grid--xl-3   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-3:not(section).gap--xl-xl, section.flex-grid--xl-3   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-3:not(section).gap--xl-xxl, section.flex-grid--xl-3   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-3:not(section) > *, section.flex-grid--xl-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xl-4:not(section), section.flex-grid--xl-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-4:not(section).gap--xl-xs, section.flex-grid--xl-4   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-4:not(section).gap--xl-s, section.flex-grid--xl-4   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-4:not(section).gap--xl-m, section.flex-grid--xl-4   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-4:not(section).gap--xl-l, section.flex-grid--xl-4   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-4:not(section).gap--xl-xl, section.flex-grid--xl-4   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-4:not(section).gap--xl-xxl, section.flex-grid--xl-4   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-4:not(section) > *, section.flex-grid--xl-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xl-5:not(section), section.flex-grid--xl-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-5:not(section).gap--xl-xs, section.flex-grid--xl-5   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-5:not(section).gap--xl-s, section.flex-grid--xl-5   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-5:not(section).gap--xl-m, section.flex-grid--xl-5   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-5:not(section).gap--xl-l, section.flex-grid--xl-5   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-5:not(section).gap--xl-xl, section.flex-grid--xl-5   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-5:not(section).gap--xl-xxl, section.flex-grid--xl-5   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-5:not(section) > *, section.flex-grid--xl-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xl-6:not(section), section.flex-grid--xl-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xl-6:not(section).gap--xl-xs, section.flex-grid--xl-6   .gap--xl-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xl-6:not(section).gap--xl-s, section.flex-grid--xl-6   .gap--xl-s {
    --gap: var(--space-s);
  }
  .flex-grid--xl-6:not(section).gap--xl-m, section.flex-grid--xl-6   .gap--xl-m {
    --gap: var(--space-m);
  }
  .flex-grid--xl-6:not(section).gap--xl-l, section.flex-grid--xl-6   .gap--xl-l {
    --gap: var(--space-l);
  }
  .flex-grid--xl-6:not(section).gap--xl-xl, section.flex-grid--xl-6   .gap--xl-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xl-6:not(section).gap--xl-xxl, section.flex-grid--xl-6   .gap--xl-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xl-6:not(section) > *, section.flex-grid--xl-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 991px) {
  .flex-grid--l-1:not(section), section.flex-grid--l-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--l-1:not(section) > *, section.flex-grid--l-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--l-2:not(section), section.flex-grid--l-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-2:not(section).gap--l-xs, section.flex-grid--l-2   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-2:not(section).gap--l-s, section.flex-grid--l-2   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-2:not(section).gap--l-m, section.flex-grid--l-2   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-2:not(section).gap--l-l, section.flex-grid--l-2   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-2:not(section).gap--l-xl, section.flex-grid--l-2   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-2:not(section).gap--l-xxl, section.flex-grid--l-2   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-2:not(section) > *, section.flex-grid--l-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--l-3:not(section), section.flex-grid--l-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-3:not(section).gap--l-xs, section.flex-grid--l-3   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-3:not(section).gap--l-s, section.flex-grid--l-3   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-3:not(section).gap--l-m, section.flex-grid--l-3   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-3:not(section).gap--l-l, section.flex-grid--l-3   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-3:not(section).gap--l-xl, section.flex-grid--l-3   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-3:not(section).gap--l-xxl, section.flex-grid--l-3   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-3:not(section) > *, section.flex-grid--l-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--l-4:not(section), section.flex-grid--l-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-4:not(section).gap--l-xs, section.flex-grid--l-4   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-4:not(section).gap--l-s, section.flex-grid--l-4   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-4:not(section).gap--l-m, section.flex-grid--l-4   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-4:not(section).gap--l-l, section.flex-grid--l-4   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-4:not(section).gap--l-xl, section.flex-grid--l-4   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-4:not(section).gap--l-xxl, section.flex-grid--l-4   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-4:not(section) > *, section.flex-grid--l-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--l-5:not(section), section.flex-grid--l-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-5:not(section).gap--l-xs, section.flex-grid--l-5   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-5:not(section).gap--l-s, section.flex-grid--l-5   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-5:not(section).gap--l-m, section.flex-grid--l-5   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-5:not(section).gap--l-l, section.flex-grid--l-5   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-5:not(section).gap--l-xl, section.flex-grid--l-5   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-5:not(section).gap--l-xxl, section.flex-grid--l-5   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-5:not(section) > *, section.flex-grid--l-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--l-6:not(section), section.flex-grid--l-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--l-6:not(section).gap--l-xs, section.flex-grid--l-6   .gap--l-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--l-6:not(section).gap--l-s, section.flex-grid--l-6   .gap--l-s {
    --gap: var(--space-s);
  }
  .flex-grid--l-6:not(section).gap--l-m, section.flex-grid--l-6   .gap--l-m {
    --gap: var(--space-m);
  }
  .flex-grid--l-6:not(section).gap--l-l, section.flex-grid--l-6   .gap--l-l {
    --gap: var(--space-l);
  }
  .flex-grid--l-6:not(section).gap--l-xl, section.flex-grid--l-6   .gap--l-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--l-6:not(section).gap--l-xxl, section.flex-grid--l-6   .gap--l-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--l-6:not(section) > *, section.flex-grid--l-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 767px) {
  .flex-grid--m-1:not(section), section.flex-grid--m-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--m-1:not(section) > *, section.flex-grid--m-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--m-2:not(section), section.flex-grid--m-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-2:not(section).gap--m-xs, section.flex-grid--m-2   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-2:not(section).gap--m-s, section.flex-grid--m-2   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-2:not(section).gap--m-m, section.flex-grid--m-2   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-2:not(section).gap--m-l, section.flex-grid--m-2   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-2:not(section).gap--m-xl, section.flex-grid--m-2   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-2:not(section).gap--m-xxl, section.flex-grid--m-2   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-2:not(section) > *, section.flex-grid--m-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--m-3:not(section), section.flex-grid--m-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-3:not(section).gap--m-xs, section.flex-grid--m-3   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-3:not(section).gap--m-s, section.flex-grid--m-3   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-3:not(section).gap--m-m, section.flex-grid--m-3   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-3:not(section).gap--m-l, section.flex-grid--m-3   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-3:not(section).gap--m-xl, section.flex-grid--m-3   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-3:not(section).gap--m-xxl, section.flex-grid--m-3   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-3:not(section) > *, section.flex-grid--m-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--m-4:not(section), section.flex-grid--m-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-4:not(section).gap--m-xs, section.flex-grid--m-4   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-4:not(section).gap--m-s, section.flex-grid--m-4   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-4:not(section).gap--m-m, section.flex-grid--m-4   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-4:not(section).gap--m-l, section.flex-grid--m-4   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-4:not(section).gap--m-xl, section.flex-grid--m-4   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-4:not(section).gap--m-xxl, section.flex-grid--m-4   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-4:not(section) > *, section.flex-grid--m-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--m-5:not(section), section.flex-grid--m-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-5:not(section).gap--m-xs, section.flex-grid--m-5   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-5:not(section).gap--m-s, section.flex-grid--m-5   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-5:not(section).gap--m-m, section.flex-grid--m-5   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-5:not(section).gap--m-l, section.flex-grid--m-5   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-5:not(section).gap--m-xl, section.flex-grid--m-5   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-5:not(section).gap--m-xxl, section.flex-grid--m-5   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-5:not(section) > *, section.flex-grid--m-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--m-6:not(section), section.flex-grid--m-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--m-6:not(section).gap--m-xs, section.flex-grid--m-6   .gap--m-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--m-6:not(section).gap--m-s, section.flex-grid--m-6   .gap--m-s {
    --gap: var(--space-s);
  }
  .flex-grid--m-6:not(section).gap--m-m, section.flex-grid--m-6   .gap--m-m {
    --gap: var(--space-m);
  }
  .flex-grid--m-6:not(section).gap--m-l, section.flex-grid--m-6   .gap--m-l {
    --gap: var(--space-l);
  }
  .flex-grid--m-6:not(section).gap--m-xl, section.flex-grid--m-6   .gap--m-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--m-6:not(section).gap--m-xxl, section.flex-grid--m-6   .gap--m-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--m-6:not(section) > *, section.flex-grid--m-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 479px) {
  .flex-grid--s-1:not(section), section.flex-grid--s-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--s-1:not(section) > *, section.flex-grid--s-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--s-2:not(section), section.flex-grid--s-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-2:not(section).gap--s-xs, section.flex-grid--s-2   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-2:not(section).gap--s-s, section.flex-grid--s-2   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-2:not(section).gap--s-m, section.flex-grid--s-2   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-2:not(section).gap--s-l, section.flex-grid--s-2   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-2:not(section).gap--s-xl, section.flex-grid--s-2   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-2:not(section).gap--s-xxl, section.flex-grid--s-2   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-2:not(section) > *, section.flex-grid--s-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--s-3:not(section), section.flex-grid--s-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-3:not(section).gap--s-xs, section.flex-grid--s-3   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-3:not(section).gap--s-s, section.flex-grid--s-3   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-3:not(section).gap--s-m, section.flex-grid--s-3   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-3:not(section).gap--s-l, section.flex-grid--s-3   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-3:not(section).gap--s-xl, section.flex-grid--s-3   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-3:not(section).gap--s-xxl, section.flex-grid--s-3   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-3:not(section) > *, section.flex-grid--s-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--s-4:not(section), section.flex-grid--s-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-4:not(section).gap--s-xs, section.flex-grid--s-4   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-4:not(section).gap--s-s, section.flex-grid--s-4   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-4:not(section).gap--s-m, section.flex-grid--s-4   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-4:not(section).gap--s-l, section.flex-grid--s-4   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-4:not(section).gap--s-xl, section.flex-grid--s-4   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-4:not(section).gap--s-xxl, section.flex-grid--s-4   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-4:not(section) > *, section.flex-grid--s-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--s-5:not(section), section.flex-grid--s-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-5:not(section).gap--s-xs, section.flex-grid--s-5   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-5:not(section).gap--s-s, section.flex-grid--s-5   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-5:not(section).gap--s-m, section.flex-grid--s-5   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-5:not(section).gap--s-l, section.flex-grid--s-5   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-5:not(section).gap--s-xl, section.flex-grid--s-5   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-5:not(section).gap--s-xxl, section.flex-grid--s-5   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-5:not(section) > *, section.flex-grid--s-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--s-6:not(section), section.flex-grid--s-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--s-6:not(section).gap--s-xs, section.flex-grid--s-6   .gap--s-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--s-6:not(section).gap--s-s, section.flex-grid--s-6   .gap--s-s {
    --gap: var(--space-s);
  }
  .flex-grid--s-6:not(section).gap--s-m, section.flex-grid--s-6   .gap--s-m {
    --gap: var(--space-m);
  }
  .flex-grid--s-6:not(section).gap--s-l, section.flex-grid--s-6   .gap--s-l {
    --gap: var(--space-l);
  }
  .flex-grid--s-6:not(section).gap--s-xl, section.flex-grid--s-6   .gap--s-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--s-6:not(section).gap--s-xxl, section.flex-grid--s-6   .gap--s-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--s-6:not(section) > *, section.flex-grid--s-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
@media (max-width: 359px) {
  .flex-grid--xs-1:not(section), section.flex-grid--xs-1    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .flex-grid--xs-1:not(section) > *, section.flex-grid--xs-1    > * {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
  }
  .flex-grid--xs-2:not(section), section.flex-grid--xs-2    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-2:not(section).gap--xs-xs, section.flex-grid--xs-2   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-2:not(section).gap--xs-s, section.flex-grid--xs-2   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-2:not(section).gap--xs-m, section.flex-grid--xs-2   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-2:not(section).gap--xs-l, section.flex-grid--xs-2   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-2:not(section).gap--xs-xl, section.flex-grid--xs-2   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-2:not(section).gap--xs-xxl, section.flex-grid--xs-2   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-2:not(section) > *, section.flex-grid--xs-2    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 1)) / 2);
  }
  .flex-grid--xs-3:not(section), section.flex-grid--xs-3    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-3:not(section).gap--xs-xs, section.flex-grid--xs-3   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-3:not(section).gap--xs-s, section.flex-grid--xs-3   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-3:not(section).gap--xs-m, section.flex-grid--xs-3   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-3:not(section).gap--xs-l, section.flex-grid--xs-3   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-3:not(section).gap--xs-xl, section.flex-grid--xs-3   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-3:not(section).gap--xs-xxl, section.flex-grid--xs-3   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-3:not(section) > *, section.flex-grid--xs-3    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 2)) / 3);
  }
  .flex-grid--xs-4:not(section), section.flex-grid--xs-4    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-4:not(section).gap--xs-xs, section.flex-grid--xs-4   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-4:not(section).gap--xs-s, section.flex-grid--xs-4   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-4:not(section).gap--xs-m, section.flex-grid--xs-4   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-4:not(section).gap--xs-l, section.flex-grid--xs-4   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-4:not(section).gap--xs-xl, section.flex-grid--xs-4   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-4:not(section).gap--xs-xxl, section.flex-grid--xs-4   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-4:not(section) > *, section.flex-grid--xs-4    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 3)) / 4);
  }
  .flex-grid--xs-5:not(section), section.flex-grid--xs-5    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-5:not(section).gap--xs-xs, section.flex-grid--xs-5   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-5:not(section).gap--xs-s, section.flex-grid--xs-5   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-5:not(section).gap--xs-m, section.flex-grid--xs-5   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-5:not(section).gap--xs-l, section.flex-grid--xs-5   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-5:not(section).gap--xs-xl, section.flex-grid--xs-5   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-5:not(section).gap--xs-xxl, section.flex-grid--xs-5   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-5:not(section) > *, section.flex-grid--xs-5    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 4)) / 5);
  }
  .flex-grid--xs-6:not(section), section.flex-grid--xs-6    {
    --gap: var(--grid-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: center;
  }
  .flex-grid--xs-6:not(section).gap--xs-xs, section.flex-grid--xs-6   .gap--xs-xs {
    --gap: var(--space-xs);
  }
  .flex-grid--xs-6:not(section).gap--xs-s, section.flex-grid--xs-6   .gap--xs-s {
    --gap: var(--space-s);
  }
  .flex-grid--xs-6:not(section).gap--xs-m, section.flex-grid--xs-6   .gap--xs-m {
    --gap: var(--space-m);
  }
  .flex-grid--xs-6:not(section).gap--xs-l, section.flex-grid--xs-6   .gap--xs-l {
    --gap: var(--space-l);
  }
  .flex-grid--xs-6:not(section).gap--xs-xl, section.flex-grid--xs-6   .gap--xs-xl {
    --gap: var(--space-xl);
  }
  .flex-grid--xs-6:not(section).gap--xs-xxl, section.flex-grid--xs-6   .gap--xs-xxl {
    --gap: var(--space-xxl);
  }
  .flex-grid--xs-6:not(section) > *, section.flex-grid--xs-6    > * {
    width: 100%;
    max-width: calc((100% - (var(--gap) * 5)) / 6);
  }
}
.flex--col , .ct-section.flex--col    {
  display: flex;
  flex-direction: column;
}
.flex--row , .ct-section.flex--row    {
  display: flex;
  flex-direction: row;
}
.flex--col-reverse , .ct-section.flex--col-reverse    {
  display: flex;
  flex-direction: column-reverse;
}
.flex--row-reverse , .ct-section.flex--row-reverse    {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 1439px) {
  .flex--col-xxl , .ct-section.flex--col-xxl    {
    display: flex;
    flex-direction: column;
  }
  .flex--row-xxl , .ct-section.flex--row-xxl    {
    display: flex;
    flex-direction: row;
  }
  .flex--col-reverse-xxl , .ct-section.flex--col-reverse-xxl    {
    display: flex;
    flex-direction: column-reverse;
  }
  .flex--row-reverse-xxl , .ct-section.flex--row-reverse-xxl    {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (max-width: 1279px) {
  .flex--col-xl , .ct-section.flex--col-xl    {
    display: flex;
    flex-direction: column;
  }
  .flex--row-xl , .ct-section.flex--row-xl    {
    display: flex;
    flex-direction: row;
  }
  .flex--col-reverse-xl , .ct-section.flex--col-reverse-xl    {
    display: flex;
    flex-direction: column-reverse;
  }
  .flex--row-reverse-xl , .ct-section.flex--row-reverse-xl    {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (max-width: 991px) {
  .flex--col-l , .ct-section.flex--col-l    {
    display: flex;
    flex-direction: column;
  }
  .flex--row-l , .ct-section.flex--row-l    {
    display: flex;
    flex-direction: row;
  }
  .flex--col-reverse-l , .ct-section.flex--col-reverse-l    {
    display: flex;
    flex-direction: column-reverse;
  }
  .flex--row-reverse-l , .ct-section.flex--row-reverse-l    {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) {
  .flex--col-m , .ct-section.flex--col-m    {
    display: flex;
    flex-direction: column;
  }
  .flex--row-m , .ct-section.flex--row-m    {
    display: flex;
    flex-direction: row;
  }
  .flex--col-reverse-m , .ct-section.flex--col-reverse-m    {
    display: flex;
    flex-direction: column-reverse;
  }
  .flex--row-reverse-m , .ct-section.flex--row-reverse-m    {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (max-width: 479px) {
  .flex--col-s , .ct-section.flex--col-s    {
    display: flex;
    flex-direction: column;
  }
  .flex--row-s , .ct-section.flex--row-s    {
    display: flex;
    flex-direction: row;
  }
  .flex--col-reverse-s , .ct-section.flex--col-reverse-s    {
    display: flex;
    flex-direction: column-reverse;
  }
  .flex--row-reverse-s , .ct-section.flex--row-reverse-s    {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (max-width: 359px) {
  .flex--col-xs , .ct-section.flex--col-xs    {
    display: flex;
    flex-direction: column;
  }
  .flex--row-xs , .ct-section.flex--row-xs    {
    display: flex;
    flex-direction: row;
  }
  .flex--col-reverse-xs , .ct-section.flex--col-reverse-xs    {
    display: flex;
    flex-direction: column-reverse;
  }
  .flex--row-reverse-xs , .ct-section.flex--row-reverse-xs    {
    display: flex;
    flex-direction: row-reverse;
  }
}
.justify-content--start , .ct-section.justify-content--start    {
  display: flex;
  justify-content: flex-start;
}
.justify-content--end , .ct-section.justify-content--end    {
  display: flex;
  justify-content: flex-end;
}
.justify-content--center , .ct-section.justify-content--center    {
  display: flex;
  justify-content: center;
}
.justify-content--between , .ct-section.justify-content--between    {
  display: flex;
  justify-content: space-between;
}
.justify-content--around , .ct-section.justify-content--around    {
  display: flex;
  justify-content: space-around;
}
.justify-content--stretch , .ct-section.justify-content--stretch    {
  display: flex;
  justify-content: stretch;
}
.align-content--start , .ct-section.align-content--start    {
  display: flex;
  align-content: flex-start;
}
.align-content--end , .ct-section.align-content--end    {
  display: flex;
  align-content: flex-end;
}
.align-content--center , .ct-section.align-content--center    {
  display: flex;
  align-content: center;
}
.align-content--baseline , .ct-section.align-content--baseline    {
  display: flex;
  align-content: baseline;
}
.align-content--stretch , .ct-section.align-content--stretch    {
  display: flex;
  align-content: stretch;
}
.justify-items--start , .ct-section.justify-items--start    {
  display: flex;
  justify-items: flex-start;
}
.justify-items--center , .ct-section.justify-items--center    {
  display: flex;
  justify-items: center;
}
.justify-items--end , .ct-section.justify-items--end    {
  display: flex;
  justify-items: flex-end;
}
.justify-items--stretch , .ct-section.justify-items--stretch    {
  display: flex;
  justify-items: stretch;
}
.align-items--start , .ct-section.align-items--start    {
  display: flex;
  align-items: flex-start;
}
.align-items--end , .ct-section.align-items--end    {
  display: flex;
  align-items: flex-end;
}
.align-items--center , .ct-section.align-items--center    {
  display: flex;
  align-items: center;
}
.align-items--baseline , .ct-section.align-items--baseline    {
  display: flex;
  align-items: baseline;
}
.align-items--stretch , .ct-section.align-items--stretch    {
  display: flex;
  align-items: stretch;
}
.self--start , .ct-section.self--start    {
  display: flex;
  align-self: flex-start;
}
.self--end , .ct-section.self--end    {
  display: flex;
  align-self: flex-end;
}
.self--stretch , .ct-section.self--stretch    {
  display: flex;
  align-self: stretch;
}
.self--center , .ct-section.self--center    {
  display: flex;
  align-self: center;
}
.flex--wrap , .ct-section.flex--wrap    {
  flex-wrap: wrap;
}
.stretch , .ct-section.stretch    {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  justify-content: stretch;
  justify-items: stretch;
}
.flex--grow  > *, .ct-section.flex--grow    > * {
  flex-grow: 1;
}
.grid--1 , .ct-section.grid--1    {
  display: grid !important;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--1  > *, .ct-section.grid--1    > * {
  grid-column: 1 !important;
}
.grid--2 , .ct-section.grid--2    {
  display: grid !important;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--3 , .ct-section.grid--3    {
  display: grid !important;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--4 , .ct-section.grid--4    {
  display: grid !important;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--5 , .ct-section.grid--5    {
  display: grid !important;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--6 , .ct-section.grid--6    {
  display: grid !important;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--7 , .ct-section.grid--7    {
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--8 , .ct-section.grid--8    {
  display: grid !important;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--9 , .ct-section.grid--9    {
  display: grid !important;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--10 , .ct-section.grid--10    {
  display: grid !important;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--11 , .ct-section.grid--11    {
  display: grid !important;
  grid-template-columns: repeat(11, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--12 , .ct-section.grid--12    {
  display: grid !important;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  inline-size: 100%;
}
.grid--1-2 , .ct-section.grid--1-2    {
  display: grid !important;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  inline-size: 100%;
}
.grid--1-3 , .ct-section.grid--1-3    {
  display: grid !important;
  grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  inline-size: 100%;
}
.grid--2-1 , .ct-section.grid--2-1    {
  display: grid !important;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  inline-size: 100%;
}
.grid--2-3 , .ct-section.grid--2-3    {
  display: grid !important;
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  inline-size: 100%;
}
.grid--3-1 , .ct-section.grid--3-1    {
  display: grid !important;
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  inline-size: 100%;
}
.grid--3-2 , .ct-section.grid--3-2    {
  display: grid !important;
  grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  inline-size: 100%;
}
.grid--auto-2 , .ct-section.grid--auto-2    {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(46.1538461538rem, 100vw - 6rem), 1fr));
  inline-size: 100%;
}
.grid--auto-3 , .ct-section.grid--auto-3    {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
  inline-size: 100%;
}
.grid--auto-4 , .ct-section.grid--auto-4    {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(27.6553106212rem, 100vw - 6rem), 1fr));
  inline-size: 100%;
}
.grid--auto-5 , .ct-section.grid--auto-5    {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(23.0383973289rem, 100vw - 6rem), 1fr));
  inline-size: 100%;
}
.grid--auto-6 , .ct-section.grid--auto-6    {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(19.7424892704rem, 100vw - 6rem), 1fr));
  inline-size: 100%;
}
.grid--auto-1-2 , .ct-section.grid--auto-1-2    {
  display: grid !important;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  inline-size: 100%;
}
.grid--auto-1-3 , .ct-section.grid--auto-1-3    {
  display: grid !important;
  grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  inline-size: 100%;
}
.grid--auto-2-1 , .ct-section.grid--auto-2-1    {
  display: grid !important;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  inline-size: 100%;
}
.grid--auto-2-3 , .ct-section.grid--auto-2-3    {
  display: grid !important;
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  inline-size: 100%;
}
.grid--auto-3-1 , .ct-section.grid--auto-3-1    {
  display: grid !important;
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  inline-size: 100%;
}
.grid--auto-3-2 , .ct-section.grid--auto-3-2    {
  display: grid !important;
  grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  inline-size: 100%;
}
@media (max-width: 1439px) {
  .grid--xxl-1 , .ct-section.grid--xxl-1    {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--xxl-1  > .ct-section, .ct-section.grid--xxl-1    > .ct-section {
    grid-column: 1 !important;
  }
  .grid--xxl-2 , .ct-section.grid--xxl-2    {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--xxl-3 , .ct-section.grid--xxl-3    {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--xxl-4 , .ct-section.grid--xxl-4    {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--xxl-5 , .ct-section.grid--xxl-5    {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--xxl-6 , .ct-section.grid--xxl-6    {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--xxl-7 , .ct-section.grid--xxl-7    {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--xxl-8 , .ct-section.grid--xxl-8    {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid--xxl-9 , .ct-section.grid--xxl-9    {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid--xxl-10 , .ct-section.grid--xxl-10    {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid--xxl-11 , .ct-section.grid--xxl-11    {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid--xxl-12 , .ct-section.grid--xxl-12    {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (max-width: 1279px) {
  .grid--xl-1 , .ct-section.grid--xl-1    {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--xl-1  > .ct-section, .ct-section.grid--xl-1    > .ct-section {
    grid-column: 1 !important;
  }
  .grid--xl-2 , .ct-section.grid--xl-2    {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--xl-3 , .ct-section.grid--xl-3    {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--xl-4 , .ct-section.grid--xl-4    {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--xl-5 , .ct-section.grid--xl-5    {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--xl-6 , .ct-section.grid--xl-6    {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--xl-7 , .ct-section.grid--xl-7    {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--xl-8 , .ct-section.grid--xl-8    {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid--xl-9 , .ct-section.grid--xl-9    {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid--xl-10 , .ct-section.grid--xl-10    {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid--xl-11 , .ct-section.grid--xl-11    {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid--xl-12 , .ct-section.grid--xl-12    {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (max-width: 991px) {
  .grid--l-1 , .ct-section.grid--l-1    {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--l-1  > .ct-section, .ct-section.grid--l-1    > .ct-section {
    grid-column: 1 !important;
  }
  .grid--l-2 , .ct-section.grid--l-2    {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--l-3 , .ct-section.grid--l-3    {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--l-4 , .ct-section.grid--l-4    {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--l-5 , .ct-section.grid--l-5    {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--l-6 , .ct-section.grid--l-6    {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--l-7 , .ct-section.grid--l-7    {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--l-8 , .ct-section.grid--l-8    {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid--l-9 , .ct-section.grid--l-9    {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid--l-10 , .ct-section.grid--l-10    {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid--l-11 , .ct-section.grid--l-11    {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid--l-12 , .ct-section.grid--l-12    {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (max-width: 767px) {
  .grid--m-1 , .ct-section.grid--m-1    {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--m-1  > .ct-section, .ct-section.grid--m-1    > .ct-section {
    grid-column: 1 !important;
  }
  .grid--m-2 , .ct-section.grid--m-2    {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--m-3 , .ct-section.grid--m-3    {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--m-4 , .ct-section.grid--m-4    {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--m-5 , .ct-section.grid--m-5    {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--m-6 , .ct-section.grid--m-6    {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--m-7 , .ct-section.grid--m-7    {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--m-8 , .ct-section.grid--m-8    {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid--m-9 , .ct-section.grid--m-9    {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid--m-10 , .ct-section.grid--m-10    {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid--m-11 , .ct-section.grid--m-11    {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid--m-12 , .ct-section.grid--m-12    {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (max-width: 479px) {
  .grid--s-1 , .ct-section.grid--s-1    {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--s-1  > .ct-section, .ct-section.grid--s-1    > .ct-section {
    grid-column: 1 !important;
  }
  .grid--s-2 , .ct-section.grid--s-2    {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--s-3 , .ct-section.grid--s-3    {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--s-4 , .ct-section.grid--s-4    {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--s-5 , .ct-section.grid--s-5    {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--s-6 , .ct-section.grid--s-6    {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--s-7 , .ct-section.grid--s-7    {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--s-8 , .ct-section.grid--s-8    {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid--s-9 , .ct-section.grid--s-9    {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid--s-10 , .ct-section.grid--s-10    {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid--s-11 , .ct-section.grid--s-11    {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid--s-12 , .ct-section.grid--s-12    {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (max-width: 359px) {
  .grid--xs-1 , .ct-section.grid--xs-1    {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid--xs-1  > .ct-section, .ct-section.grid--xs-1    > .ct-section {
    grid-column: 1 !important;
  }
  .grid--xs-2 , .ct-section.grid--xs-2    {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid--xs-3 , .ct-section.grid--xs-3    {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid--xs-4 , .ct-section.grid--xs-4    {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid--xs-5 , .ct-section.grid--xs-5    {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid--xs-6 , .ct-section.grid--xs-6    {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid--xs-7 , .ct-section.grid--xs-7    {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid--xs-8 , .ct-section.grid--xs-8    {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid--xs-9 , .ct-section.grid--xs-9    {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid--xs-10 , .ct-section.grid--xs-10    {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid--xs-11 , .ct-section.grid--xs-11    {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid--xs-12 , .ct-section.grid--xs-12    {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (min-width: 1439px) {
  .grid--alternate-xxl  > .grid--1-2:nth-of-type(even), .grid--alternate-xxl    > .grid--1-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
  .grid--alternate-xxl  > .grid--1-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xxl    > .grid--1-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xxl  > .grid--1-3:nth-of-type(even), .grid--alternate-xxl    > .grid--1-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  }
  .grid--alternate-xxl  > .grid--1-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xxl    > .grid--1-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xxl  > .grid--2-1:nth-of-type(even), .grid--alternate-xxl    > .grid--2-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  .grid--alternate-xxl  > .grid--2-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xxl    > .grid--2-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xxl  > .grid--2-3:nth-of-type(even), .grid--alternate-xxl    > .grid--2-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
  .grid--alternate-xxl  > .grid--2-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xxl    > .grid--2-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xxl  > .grid--3-1:nth-of-type(even), .grid--alternate-xxl    > .grid--3-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  .grid--alternate-xxl  > .grid--3-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xxl    > .grid--3-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xxl  > .grid--3-2:nth-of-type(even), .grid--alternate-xxl    > .grid--3-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
  .grid--alternate-xxl  > .grid--3-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xxl    > .grid--3-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
}
@media (min-width: 1279px) {
  .grid--alternate-xl  > .grid--1-2:nth-of-type(even), .grid--alternate-xl    > .grid--1-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
  .grid--alternate-xl  > .grid--1-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xl    > .grid--1-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xl  > .grid--1-3:nth-of-type(even), .grid--alternate-xl    > .grid--1-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  }
  .grid--alternate-xl  > .grid--1-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xl    > .grid--1-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xl  > .grid--2-1:nth-of-type(even), .grid--alternate-xl    > .grid--2-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  .grid--alternate-xl  > .grid--2-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xl    > .grid--2-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xl  > .grid--2-3:nth-of-type(even), .grid--alternate-xl    > .grid--2-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
  .grid--alternate-xl  > .grid--2-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xl    > .grid--2-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xl  > .grid--3-1:nth-of-type(even), .grid--alternate-xl    > .grid--3-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  .grid--alternate-xl  > .grid--3-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xl    > .grid--3-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xl  > .grid--3-2:nth-of-type(even), .grid--alternate-xl    > .grid--3-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
  .grid--alternate-xl  > .grid--3-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xl    > .grid--3-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
}
@media (min-width: 991px) {
  .grid--alternate-l  > .grid--1-2:nth-of-type(even), .grid--alternate-l    > .grid--1-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
  .grid--alternate-l  > .grid--1-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-l    > .grid--1-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-l  > .grid--1-3:nth-of-type(even), .grid--alternate-l    > .grid--1-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  }
  .grid--alternate-l  > .grid--1-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-l    > .grid--1-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-l  > .grid--2-1:nth-of-type(even), .grid--alternate-l    > .grid--2-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  .grid--alternate-l  > .grid--2-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-l    > .grid--2-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-l  > .grid--2-3:nth-of-type(even), .grid--alternate-l    > .grid--2-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
  .grid--alternate-l  > .grid--2-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-l    > .grid--2-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-l  > .grid--3-1:nth-of-type(even), .grid--alternate-l    > .grid--3-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  .grid--alternate-l  > .grid--3-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-l    > .grid--3-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-l  > .grid--3-2:nth-of-type(even), .grid--alternate-l    > .grid--3-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
  .grid--alternate-l  > .grid--3-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-l    > .grid--3-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
}
@media (min-width: 767px) {
  .grid--alternate-m  > .grid--1-2:nth-of-type(even), .grid--alternate-m    > .grid--1-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
  .grid--alternate-m  > .grid--1-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-m    > .grid--1-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-m  > .grid--1-3:nth-of-type(even), .grid--alternate-m    > .grid--1-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  }
  .grid--alternate-m  > .grid--1-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-m    > .grid--1-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-m  > .grid--2-1:nth-of-type(even), .grid--alternate-m    > .grid--2-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  .grid--alternate-m  > .grid--2-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-m    > .grid--2-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-m  > .grid--2-3:nth-of-type(even), .grid--alternate-m    > .grid--2-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
  .grid--alternate-m  > .grid--2-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-m    > .grid--2-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-m  > .grid--3-1:nth-of-type(even), .grid--alternate-m    > .grid--3-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  .grid--alternate-m  > .grid--3-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-m    > .grid--3-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-m  > .grid--3-2:nth-of-type(even), .grid--alternate-m    > .grid--3-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
  .grid--alternate-m  > .grid--3-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-m    > .grid--3-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
}
@media (min-width: 479px) {
  .grid--alternate-s  > .grid--1-2:nth-of-type(even), .grid--alternate-s    > .grid--1-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
  .grid--alternate-s  > .grid--1-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-s    > .grid--1-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-s  > .grid--1-3:nth-of-type(even), .grid--alternate-s    > .grid--1-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  }
  .grid--alternate-s  > .grid--1-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-s    > .grid--1-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-s  > .grid--2-1:nth-of-type(even), .grid--alternate-s    > .grid--2-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  .grid--alternate-s  > .grid--2-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-s    > .grid--2-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-s  > .grid--2-3:nth-of-type(even), .grid--alternate-s    > .grid--2-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
  .grid--alternate-s  > .grid--2-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-s    > .grid--2-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-s  > .grid--3-1:nth-of-type(even), .grid--alternate-s    > .grid--3-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  .grid--alternate-s  > .grid--3-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-s    > .grid--3-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-s  > .grid--3-2:nth-of-type(even), .grid--alternate-s    > .grid--3-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
  .grid--alternate-s  > .grid--3-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-s    > .grid--3-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
}
@media (min-width: 359px) {
  .grid--alternate-xs  > .grid--1-2:nth-of-type(even), .grid--alternate-xs    > .grid--1-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
  .grid--alternate-xs  > .grid--1-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xs    > .grid--1-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xs  > .grid--1-3:nth-of-type(even), .grid--alternate-xs    > .grid--1-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  }
  .grid--alternate-xs  > .grid--1-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xs    > .grid--1-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xs  > .grid--2-1:nth-of-type(even), .grid--alternate-xs    > .grid--2-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  .grid--alternate-xs  > .grid--2-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xs    > .grid--2-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xs  > .grid--2-3:nth-of-type(even), .grid--alternate-xs    > .grid--2-3:nth-of-type(even) {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  }
  .grid--alternate-xs  > .grid--2-3:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xs    > .grid--2-3:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xs  > .grid--3-1:nth-of-type(even), .grid--alternate-xs    > .grid--3-1:nth-of-type(even) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  .grid--alternate-xs  > .grid--3-1:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xs    > .grid--3-1:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
  .grid--alternate-xs  > .grid--3-2:nth-of-type(even), .grid--alternate-xs    > .grid--3-2:nth-of-type(even) {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
  .grid--alternate-xs  > .grid--3-2:nth-of-type(even) > .ct-section:last-child, .grid--alternate-xs    > .grid--3-2:nth-of-type(even) > .ct-section:last-child {
    order: -1;
  }
}
@media (max-width: 991px) {
  .grid--auto-1-2 , .ct-section.grid--auto-1-2    {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
  }
  .grid--auto-1-3 , .ct-section.grid--auto-1-3    {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
  }
  .grid--auto-3-2 , .ct-section.grid--auto-3-2    {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
  }
  .grid--auto-2-3 , .ct-section.grid--auto-2-3    {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
  }
  .grid--auto-2-1 , .ct-section.grid--auto-2-1    {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
  }
  .grid--auto-3-1 , .ct-section.grid--auto-3-1    {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
  }
}
.gap--xs:not(section), section.gap--xs    {
  gap: var(--space-xs);
}
.gap--s:not(section), section.gap--s    {
  gap: var(--space-s);
}
.gap--m:not(section), section.gap--m    {
  gap: var(--space-m);
}
.gap--l:not(section), section.gap--l    {
  gap: var(--space-l);
}
.gap--xl:not(section), section.gap--xl    {
  gap: var(--space-xl);
}
.gap--xxl:not(section), section.gap--xxl    {
  gap: var(--space-xxl);
}
@media (max-width: 1439px) {
  .gap--xxl-xs:not(section), section.gap--xxl-xs    {
    gap: var(--space-xs);
  }
  .gap--xxl-s:not(section), section.gap--xxl-s    {
    gap: var(--space-s);
  }
  .gap--xxl-m:not(section), section.gap--xxl-m    {
    gap: var(--space-m);
  }
  .gap--xxl-l:not(section), section.gap--xxl-l    {
    gap: var(--space-l);
  }
  .gap--xxl-xl:not(section), section.gap--xxl-xl    {
    gap: var(--space-xl);
  }
  .gap--xxl-xxl:not(section), section.gap--xxl-xxl    {
    gap: var(--space-xxl);
  }
}
@media (max-width: 1279px) {
  .gap--xl-xs:not(section), section.gap--xl-xs    {
    gap: var(--space-xs);
  }
  .gap--xl-s:not(section), section.gap--xl-s    {
    gap: var(--space-s);
  }
  .gap--xl-m:not(section), section.gap--xl-m    {
    gap: var(--space-m);
  }
  .gap--xl-l:not(section), section.gap--xl-l    {
    gap: var(--space-l);
  }
  .gap--xl-xl:not(section), section.gap--xl-xl    {
    gap: var(--space-xl);
  }
  .gap--xl-xxl:not(section), section.gap--xl-xxl    {
    gap: var(--space-xxl);
  }
}
@media (max-width: 991px) {
  .gap--l-xs:not(section), section.gap--l-xs    {
    gap: var(--space-xs);
  }
  .gap--l-s:not(section), section.gap--l-s    {
    gap: var(--space-s);
  }
  .gap--l-m:not(section), section.gap--l-m    {
    gap: var(--space-m);
  }
  .gap--l-l:not(section), section.gap--l-l    {
    gap: var(--space-l);
  }
  .gap--l-xl:not(section), section.gap--l-xl    {
    gap: var(--space-xl);
  }
  .gap--l-xxl:not(section), section.gap--l-xxl    {
    gap: var(--space-xxl);
  }
}
@media (max-width: 767px) {
  .gap--m-xs:not(section), section.gap--m-xs    {
    gap: var(--space-xs);
  }
  .gap--m-s:not(section), section.gap--m-s    {
    gap: var(--space-s);
  }
  .gap--m-m:not(section), section.gap--m-m    {
    gap: var(--space-m);
  }
  .gap--m-l:not(section), section.gap--m-l    {
    gap: var(--space-l);
  }
  .gap--m-xl:not(section), section.gap--m-xl    {
    gap: var(--space-xl);
  }
  .gap--m-xxl:not(section), section.gap--m-xxl    {
    gap: var(--space-xxl);
  }
}
@media (max-width: 479px) {
  .gap--s-xs:not(section), section.gap--s-xs    {
    gap: var(--space-xs);
  }
  .gap--s-s:not(section), section.gap--s-s    {
    gap: var(--space-s);
  }
  .gap--s-m:not(section), section.gap--s-m    {
    gap: var(--space-m);
  }
  .gap--s-l:not(section), section.gap--s-l    {
    gap: var(--space-l);
  }
  .gap--s-xl:not(section), section.gap--s-xl    {
    gap: var(--space-xl);
  }
  .gap--s-xxl:not(section), section.gap--s-xxl    {
    gap: var(--space-xxl);
  }
}
@media (max-width: 359px) {
  .gap--xs-xs:not(section), section.gap--xs-xs    {
    gap: var(--space-xs);
  }
  .gap--xs-s:not(section), section.gap--xs-s    {
    gap: var(--space-s);
  }
  .gap--xs-m:not(section), section.gap--xs-m    {
    gap: var(--space-m);
  }
  .gap--xs-l:not(section), section.gap--xs-l    {
    gap: var(--space-l);
  }
  .gap--xs-xl:not(section), section.gap--xs-xl    {
    gap: var(--space-xl);
  }
  .gap--xs-xxl:not(section), section.gap--xs-xxl    {
    gap: var(--space-xxl);
  }
}
.grid-gap:not(section), section.grid-gap    {
  gap: var(--grid-gap);
}
.container-gap:not(section), section.container-gap    {
  gap: var(--container-gap);
}
.content-gap:not(section), section.content-gap    {
  gap: var(--content-gap);
}
.center--self {
  margin-left: auto;
  margin-right: auto;
}
.center--all , .ct-section.center--all    {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
}
.center--y , .ct-section.center--y    {
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: flex-start;
}
.center--x , .ct-section.center--x    {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: center;
}
.center--left , .ct-section.center--left    {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
}
.center--right , .ct-section.center--right    {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
.center--top , .ct-section.center--top    {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: center;
}
.center--bottom , .ct-section.center--bottom    {
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1439px) {
  .center--all-xxl , .ct-section.center--all-xxl    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-xxl , .ct-section.center--y-xxl    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-xxl , .ct-section.center--x-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-xxl , .ct-section.center--left-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-xxl , .ct-section.center--right-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-xxl , .ct-section.center--top-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-xxl , .ct-section.center--bottom-xxl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 1279px) {
  .center--all-xl , .ct-section.center--all-xl    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-xl , .ct-section.center--y-xl    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-xl , .ct-section.center--x-xl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-xl , .ct-section.center--left-xl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-xl , .ct-section.center--right-xl    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-xl , .ct-section.center--top-xl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-xl , .ct-section.center--bottom-xl    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .center--all-l , .ct-section.center--all-l    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-l , .ct-section.center--y-l    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-l , .ct-section.center--x-l    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-l , .ct-section.center--left-l    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-l , .ct-section.center--right-l    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-l , .ct-section.center--top-l    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-l , .ct-section.center--bottom-l    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .center--all-m , .ct-section.center--all-m    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-m , .ct-section.center--y-m    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-m , .ct-section.center--x-m    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-m , .ct-section.center--left-m    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-m , .ct-section.center--right-m    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-m , .ct-section.center--top-m    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-m , .ct-section.center--bottom-m    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 479px) {
  .center--all-s , .ct-section.center--all-s    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-s , .ct-section.center--y-s    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-s , .ct-section.center--x-s    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-s , .ct-section.center--left-s    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-s , .ct-section.center--right-s    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-s , .ct-section.center--top-s    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-s , .ct-section.center--bottom-s    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 359px) {
  .center--all-xs , .ct-section.center--all-xs    {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
  }
  .center--y-xs , .ct-section.center--y-xs    {
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  }
  .center--x-xs , .ct-section.center--x-xs    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--left-xs , .ct-section.center--left-xs    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
  }
  .center--right-xs , .ct-section.center--right-xs    {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
  }
  .center--top-xs , .ct-section.center--top-xs    {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  .center--bottom-xs , .ct-section.center--bottom-xs    {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: center;
  }
}
.height--20 , .ct-section.height--20    {
  min-block-size: 20vh;
}
.height--30 , .ct-section.height--30    {
  min-block-size: 30vh;
}
.height--40 , .ct-section.height--40    {
  min-block-size: 40vh;
}
.height--50 , .ct-section.height--50    {
  min-block-size: 50vh;
}
.height--60 , .ct-section.height--60    {
  min-block-size: 60vh;
}
.height--70 , .ct-section.height--70    {
  min-block-size: 70vh;
}
.height--80 , .ct-section.height--80    {
  min-block-size: 80vh;
}
.height--90 , .ct-section.height--90    {
  min-block-size: 90vh;
}
.height--100 , .ct-section.height--100    {
  min-block-size: 100vh;
}
.height--full , .ct-section.height--full    {
  min-block-size: 100vh;
}
@media (max-width: 1439px) {
  .height--xxl-20 , .ct-section.height--xxl-20    {
    min-block-size: 20vh;
  }
  .height--xxl-30 , .ct-section.height--xxl-30    {
    min-block-size: 30vh;
  }
  .height--xxl-40 , .ct-section.height--xxl-40    {
    min-block-size: 40vh;
  }
  .height--xxl-50 , .ct-section.height--xxl-50    {
    min-block-size: 50vh;
  }
  .height--xxl-60 , .ct-section.height--xxl-60    {
    min-block-size: 60vh;
  }
  .height--xxl-70 , .ct-section.height--xxl-70    {
    min-block-size: 70vh;
  }
  .height--xxl-80 , .ct-section.height--xxl-80    {
    min-block-size: 80vh;
  }
  .height--xxl-90 , .ct-section.height--xxl-90    {
    min-block-size: 90vh;
  }
  .height--xxl-100 , .ct-section.height--xxl-100    {
    min-block-size: 100vh;
  }
  .height--xxl-full , .ct-section.height--xxl-full    {
    min-block-size: 100vh;
  }
}
@media (max-width: 1279px) {
  .height--xl-20 , .ct-section.height--xl-20    {
    min-block-size: 20vh;
  }
  .height--xl-30 , .ct-section.height--xl-30    {
    min-block-size: 30vh;
  }
  .height--xl-40 , .ct-section.height--xl-40    {
    min-block-size: 40vh;
  }
  .height--xl-50 , .ct-section.height--xl-50    {
    min-block-size: 50vh;
  }
  .height--xl-60 , .ct-section.height--xl-60    {
    min-block-size: 60vh;
  }
  .height--xl-70 , .ct-section.height--xl-70    {
    min-block-size: 70vh;
  }
  .height--xl-80 , .ct-section.height--xl-80    {
    min-block-size: 80vh;
  }
  .height--xl-90 , .ct-section.height--xl-90    {
    min-block-size: 90vh;
  }
  .height--xl-100 , .ct-section.height--xl-100    {
    min-block-size: 100vh;
  }
  .height--xl-full , .ct-section.height--xl-full    {
    min-block-size: 100vh;
  }
}
@media (max-width: 991px) {
  .height--l-20 , .ct-section.height--l-20    {
    min-block-size: 20vh;
  }
  .height--l-30 , .ct-section.height--l-30    {
    min-block-size: 30vh;
  }
  .height--l-40 , .ct-section.height--l-40    {
    min-block-size: 40vh;
  }
  .height--l-50 , .ct-section.height--l-50    {
    min-block-size: 50vh;
  }
  .height--l-60 , .ct-section.height--l-60    {
    min-block-size: 60vh;
  }
  .height--l-70 , .ct-section.height--l-70    {
    min-block-size: 70vh;
  }
  .height--l-80 , .ct-section.height--l-80    {
    min-block-size: 80vh;
  }
  .height--l-90 , .ct-section.height--l-90    {
    min-block-size: 90vh;
  }
  .height--l-100 , .ct-section.height--l-100    {
    min-block-size: 100vh;
  }
  .height--l-full , .ct-section.height--l-full    {
    min-block-size: 100vh;
  }
}
@media (max-width: 767px) {
  .height--m-20 , .ct-section.height--m-20    {
    min-block-size: 20vh;
  }
  .height--m-30 , .ct-section.height--m-30    {
    min-block-size: 30vh;
  }
  .height--m-40 , .ct-section.height--m-40    {
    min-block-size: 40vh;
  }
  .height--m-50 , .ct-section.height--m-50    {
    min-block-size: 50vh;
  }
  .height--m-60 , .ct-section.height--m-60    {
    min-block-size: 60vh;
  }
  .height--m-70 , .ct-section.height--m-70    {
    min-block-size: 70vh;
  }
  .height--m-80 , .ct-section.height--m-80    {
    min-block-size: 80vh;
  }
  .height--m-90 , .ct-section.height--m-90    {
    min-block-size: 90vh;
  }
  .height--m-100 , .ct-section.height--m-100    {
    min-block-size: 100vh;
  }
  .height--m-full , .ct-section.height--m-full    {
    min-block-size: 100vh;
  }
}
@media (max-width: 479px) {
  .height--s-20 , .ct-section.height--s-20    {
    min-block-size: 20vh;
  }
  .height--s-30 , .ct-section.height--s-30    {
    min-block-size: 30vh;
  }
  .height--s-40 , .ct-section.height--s-40    {
    min-block-size: 40vh;
  }
  .height--s-50 , .ct-section.height--s-50    {
    min-block-size: 50vh;
  }
  .height--s-60 , .ct-section.height--s-60    {
    min-block-size: 60vh;
  }
  .height--s-70 , .ct-section.height--s-70    {
    min-block-size: 70vh;
  }
  .height--s-80 , .ct-section.height--s-80    {
    min-block-size: 80vh;
  }
  .height--s-90 , .ct-section.height--s-90    {
    min-block-size: 90vh;
  }
  .height--s-100 , .ct-section.height--s-100    {
    min-block-size: 100vh;
  }
  .height--s-full , .ct-section.height--s-full    {
    min-block-size: 100vh;
  }
}
@media (max-width: 359px) {
  .height--xs-20 , .ct-section.height--xs-20    {
    min-block-size: 20vh;
  }
  .height--xs-30 , .ct-section.height--xs-30    {
    min-block-size: 30vh;
  }
  .height--xs-40 , .ct-section.height--xs-40    {
    min-block-size: 40vh;
  }
  .height--xs-50 , .ct-section.height--xs-50    {
    min-block-size: 50vh;
  }
  .height--xs-60 , .ct-section.height--xs-60    {
    min-block-size: 60vh;
  }
  .height--xs-70 , .ct-section.height--xs-70    {
    min-block-size: 70vh;
  }
  .height--xs-80 , .ct-section.height--xs-80    {
    min-block-size: 80vh;
  }
  .height--xs-90 , .ct-section.height--xs-90    {
    min-block-size: 90vh;
  }
  .height--xs-100 , .ct-section.height--xs-100    {
    min-block-size: 100vh;
  }
  .height--xs-full , .ct-section.height--xs-full    {
    min-block-size: 100vh;
  }
}
.max-height--20 , .ct-section.max-height--20    {
  max-block-size: 20vh;
}
.max-height--30 , .ct-section.max-height--30    {
  max-block-size: 30vh;
}
.max-height--40 , .ct-section.max-height--40    {
  max-block-size: 40vh;
}
.max-height--50 , .ct-section.max-height--50    {
  max-block-size: 50vh;
}
.max-height--60 , .ct-section.max-height--60    {
  max-block-size: 60vh;
}
.max-height--70 , .ct-section.max-height--70    {
  max-block-size: 70vh;
}
.max-height--80 , .ct-section.max-height--80    {
  max-block-size: 80vh;
}
.max-height--90 , .ct-section.max-height--90    {
  max-block-size: 90vh;
}
.max-height--100 , .ct-section.max-height--100    {
  max-block-size: 100vh;
}
.max-height--full , .ct-section.max-height--full    {
  max-block-size: 100vh;
}
@media (max-width: 1439px) {
  .max-height--xxl-20 , .ct-section.max-height--xxl-20    {
    max-block-size: 20vh;
  }
  .max-height--xxl-30 , .ct-section.max-height--xxl-30    {
    max-block-size: 30vh;
  }
  .max-height--xxl-40 , .ct-section.max-height--xxl-40    {
    max-block-size: 40vh;
  }
  .max-height--xxl-50 , .ct-section.max-height--xxl-50    {
    max-block-size: 50vh;
  }
  .max-height--xxl-60 , .ct-section.max-height--xxl-60    {
    max-block-size: 60vh;
  }
  .max-height--xxl-70 , .ct-section.max-height--xxl-70    {
    max-block-size: 70vh;
  }
  .max-height--xxl-80 , .ct-section.max-height--xxl-80    {
    max-block-size: 80vh;
  }
  .max-height--xxl-90 , .ct-section.max-height--xxl-90    {
    max-block-size: 90vh;
  }
  .max-height--xxl-100 , .ct-section.max-height--xxl-100    {
    max-block-size: 100vh;
  }
  .max-height--xxl-full , .ct-section.max-height--xxl-full    {
    max-block-size: 100vh;
  }
}
@media (max-width: 1279px) {
  .max-height--xl-20 , .ct-section.max-height--xl-20    {
    max-block-size: 20vh;
  }
  .max-height--xl-30 , .ct-section.max-height--xl-30    {
    max-block-size: 30vh;
  }
  .max-height--xl-40 , .ct-section.max-height--xl-40    {
    max-block-size: 40vh;
  }
  .max-height--xl-50 , .ct-section.max-height--xl-50    {
    max-block-size: 50vh;
  }
  .max-height--xl-60 , .ct-section.max-height--xl-60    {
    max-block-size: 60vh;
  }
  .max-height--xl-70 , .ct-section.max-height--xl-70    {
    max-block-size: 70vh;
  }
  .max-height--xl-80 , .ct-section.max-height--xl-80    {
    max-block-size: 80vh;
  }
  .max-height--xl-90 , .ct-section.max-height--xl-90    {
    max-block-size: 90vh;
  }
  .max-height--xl-100 , .ct-section.max-height--xl-100    {
    max-block-size: 100vh;
  }
  .max-height--xl-full , .ct-section.max-height--xl-full    {
    max-block-size: 100vh;
  }
}
@media (max-width: 991px) {
  .max-height--l-20 , .ct-section.max-height--l-20    {
    max-block-size: 20vh;
  }
  .max-height--l-30 , .ct-section.max-height--l-30    {
    max-block-size: 30vh;
  }
  .max-height--l-40 , .ct-section.max-height--l-40    {
    max-block-size: 40vh;
  }
  .max-height--l-50 , .ct-section.max-height--l-50    {
    max-block-size: 50vh;
  }
  .max-height--l-60 , .ct-section.max-height--l-60    {
    max-block-size: 60vh;
  }
  .max-height--l-70 , .ct-section.max-height--l-70    {
    max-block-size: 70vh;
  }
  .max-height--l-80 , .ct-section.max-height--l-80    {
    max-block-size: 80vh;
  }
  .max-height--l-90 , .ct-section.max-height--l-90    {
    max-block-size: 90vh;
  }
  .max-height--l-100 , .ct-section.max-height--l-100    {
    max-block-size: 100vh;
  }
  .max-height--l-full , .ct-section.max-height--l-full    {
    max-block-size: 100vh;
  }
}
@media (max-width: 767px) {
  .max-height--m-20 , .ct-section.max-height--m-20    {
    max-block-size: 20vh;
  }
  .max-height--m-30 , .ct-section.max-height--m-30    {
    max-block-size: 30vh;
  }
  .max-height--m-40 , .ct-section.max-height--m-40    {
    max-block-size: 40vh;
  }
  .max-height--m-50 , .ct-section.max-height--m-50    {
    max-block-size: 50vh;
  }
  .max-height--m-60 , .ct-section.max-height--m-60    {
    max-block-size: 60vh;
  }
  .max-height--m-70 , .ct-section.max-height--m-70    {
    max-block-size: 70vh;
  }
  .max-height--m-80 , .ct-section.max-height--m-80    {
    max-block-size: 80vh;
  }
  .max-height--m-90 , .ct-section.max-height--m-90    {
    max-block-size: 90vh;
  }
  .max-height--m-100 , .ct-section.max-height--m-100    {
    max-block-size: 100vh;
  }
  .max-height--m-full , .ct-section.max-height--m-full    {
    max-block-size: 100vh;
  }
}
@media (max-width: 479px) {
  .max-height--s-20 , .ct-section.max-height--s-20    {
    max-block-size: 20vh;
  }
  .max-height--s-30 , .ct-section.max-height--s-30    {
    max-block-size: 30vh;
  }
  .max-height--s-40 , .ct-section.max-height--s-40    {
    max-block-size: 40vh;
  }
  .max-height--s-50 , .ct-section.max-height--s-50    {
    max-block-size: 50vh;
  }
  .max-height--s-60 , .ct-section.max-height--s-60    {
    max-block-size: 60vh;
  }
  .max-height--s-70 , .ct-section.max-height--s-70    {
    max-block-size: 70vh;
  }
  .max-height--s-80 , .ct-section.max-height--s-80    {
    max-block-size: 80vh;
  }
  .max-height--s-90 , .ct-section.max-height--s-90    {
    max-block-size: 90vh;
  }
  .max-height--s-100 , .ct-section.max-height--s-100    {
    max-block-size: 100vh;
  }
  .max-height--s-full , .ct-section.max-height--s-full    {
    max-block-size: 100vh;
  }
}
@media (max-width: 359px) {
  .max-height--xs-20 , .ct-section.max-height--xs-20    {
    max-block-size: 20vh;
  }
  .max-height--xs-30 , .ct-section.max-height--xs-30    {
    max-block-size: 30vh;
  }
  .max-height--xs-40 , .ct-section.max-height--xs-40    {
    max-block-size: 40vh;
  }
  .max-height--xs-50 , .ct-section.max-height--xs-50    {
    max-block-size: 50vh;
  }
  .max-height--xs-60 , .ct-section.max-height--xs-60    {
    max-block-size: 60vh;
  }
  .max-height--xs-70 , .ct-section.max-height--xs-70    {
    max-block-size: 70vh;
  }
  .max-height--xs-80 , .ct-section.max-height--xs-80    {
    max-block-size: 80vh;
  }
  .max-height--xs-90 , .ct-section.max-height--xs-90    {
    max-block-size: 90vh;
  }
  .max-height--xs-100 , .ct-section.max-height--xs-100    {
    max-block-size: 100vh;
  }
  .max-height--xs-full , .ct-section.max-height--xs-full    {
    max-block-size: 100vh;
  }
}
.pad-section--xs , .pad-section--xs .ct-section-inner-wrap {
  padding: 1.896rem 2.4rem;
  padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.pad-section--s , .pad-section--s .ct-section-inner-wrap {
  padding: 2.133rem 2.4rem;
  padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.pad-section--m , .pad-section--m .ct-section-inner-wrap {
  padding: 2.4rem 2.4rem;
  padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.pad-section--l , .pad-section--l .ct-section-inner-wrap {
  padding: 2.7rem 2.4rem;
  padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.pad-section--xl , .pad-section--xl .ct-section-inner-wrap {
  padding: 3.038rem 2.4rem;
  padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.pad-section--xxl , .pad-section--xxl .ct-section-inner-wrap {
  padding: 3.42rem 2.4rem;
  padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  padding: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
.pad-section--none , .pad-section--none .ct-section-inner-wrap {
  padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
}
@media (max-width: 1439px) {
  .pad-section--xxl-xs , .pad-section--xxl-xs .ct-section-inner-wrap {
    padding: 1.896rem 2.4rem;
    padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xxl-none , .pad-section--xxl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xxl , .pad--none--xxl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xxl-s , .pad-section--xxl-s .ct-section-inner-wrap {
    padding: 2.133rem 2.4rem;
    padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xxl-none , .pad-section--xxl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xxl , .pad--none--xxl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xxl-m , .pad-section--xxl-m .ct-section-inner-wrap {
    padding: 2.4rem 2.4rem;
    padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xxl-none , .pad-section--xxl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xxl , .pad--none--xxl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xxl-l , .pad-section--xxl-l .ct-section-inner-wrap {
    padding: 2.7rem 2.4rem;
    padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xxl-none , .pad-section--xxl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xxl , .pad--none--xxl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xxl-xl , .pad-section--xxl-xl .ct-section-inner-wrap {
    padding: 3.038rem 2.4rem;
    padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xxl-none , .pad-section--xxl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xxl , .pad--none--xxl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xxl-xxl , .pad-section--xxl-xxl .ct-section-inner-wrap {
    padding: 3.42rem 2.4rem;
    padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xxl-none , .pad-section--xxl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xxl , .pad--none--xxl .ct-section-inner-wrap {
    padding: 0 !important;
  }
}
@media (max-width: 1279px) {
  .pad-section--xl-xs , .pad-section--xl-xs .ct-section-inner-wrap {
    padding: 1.896rem 2.4rem;
    padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xl-none , .pad-section--xl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xl , .pad--none--xl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xl-s , .pad-section--xl-s .ct-section-inner-wrap {
    padding: 2.133rem 2.4rem;
    padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xl-none , .pad-section--xl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xl , .pad--none--xl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xl-m , .pad-section--xl-m .ct-section-inner-wrap {
    padding: 2.4rem 2.4rem;
    padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xl-none , .pad-section--xl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xl , .pad--none--xl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xl-l , .pad-section--xl-l .ct-section-inner-wrap {
    padding: 2.7rem 2.4rem;
    padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xl-none , .pad-section--xl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xl , .pad--none--xl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xl-xl , .pad-section--xl-xl .ct-section-inner-wrap {
    padding: 3.038rem 2.4rem;
    padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xl-none , .pad-section--xl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xl , .pad--none--xl .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xl-xxl , .pad-section--xl-xxl .ct-section-inner-wrap {
    padding: 3.42rem 2.4rem;
    padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xl-none , .pad-section--xl-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xl , .pad--none--xl .ct-section-inner-wrap {
    padding: 0 !important;
  }
}
@media (max-width: 991px) {
  .pad-section--l-xs , .pad-section--l-xs .ct-section-inner-wrap {
    padding: 1.896rem 2.4rem;
    padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--l-none , .pad-section--l-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--l , .pad--none--l .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--l-s , .pad-section--l-s .ct-section-inner-wrap {
    padding: 2.133rem 2.4rem;
    padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--l-none , .pad-section--l-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--l , .pad--none--l .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--l-m , .pad-section--l-m .ct-section-inner-wrap {
    padding: 2.4rem 2.4rem;
    padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--l-none , .pad-section--l-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--l , .pad--none--l .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--l-l , .pad-section--l-l .ct-section-inner-wrap {
    padding: 2.7rem 2.4rem;
    padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--l-none , .pad-section--l-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--l , .pad--none--l .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--l-xl , .pad-section--l-xl .ct-section-inner-wrap {
    padding: 3.038rem 2.4rem;
    padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--l-none , .pad-section--l-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--l , .pad--none--l .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--l-xxl , .pad-section--l-xxl .ct-section-inner-wrap {
    padding: 3.42rem 2.4rem;
    padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--l-none , .pad-section--l-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--l , .pad--none--l .ct-section-inner-wrap {
    padding: 0 !important;
  }
}
@media (max-width: 767px) {
  .pad-section--m-xs , .pad-section--m-xs .ct-section-inner-wrap {
    padding: 1.896rem 2.4rem;
    padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--m-none , .pad-section--m-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--m , .pad--none--m .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--m-s , .pad-section--m-s .ct-section-inner-wrap {
    padding: 2.133rem 2.4rem;
    padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--m-none , .pad-section--m-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--m , .pad--none--m .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--m-m , .pad-section--m-m .ct-section-inner-wrap {
    padding: 2.4rem 2.4rem;
    padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--m-none , .pad-section--m-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--m , .pad--none--m .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--m-l , .pad-section--m-l .ct-section-inner-wrap {
    padding: 2.7rem 2.4rem;
    padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--m-none , .pad-section--m-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--m , .pad--none--m .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--m-xl , .pad-section--m-xl .ct-section-inner-wrap {
    padding: 3.038rem 2.4rem;
    padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--m-none , .pad-section--m-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--m , .pad--none--m .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--m-xxl , .pad-section--m-xxl .ct-section-inner-wrap {
    padding: 3.42rem 2.4rem;
    padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--m-none , .pad-section--m-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--m , .pad--none--m .ct-section-inner-wrap {
    padding: 0 !important;
  }
}
@media (max-width: 479px) {
  .pad-section--s-xs , .pad-section--s-xs .ct-section-inner-wrap {
    padding: 1.896rem 2.4rem;
    padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--s-none , .pad-section--s-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--s , .pad--none--s .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--s-s , .pad-section--s-s .ct-section-inner-wrap {
    padding: 2.133rem 2.4rem;
    padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--s-none , .pad-section--s-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--s , .pad--none--s .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--s-m , .pad-section--s-m .ct-section-inner-wrap {
    padding: 2.4rem 2.4rem;
    padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--s-none , .pad-section--s-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--s , .pad--none--s .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--s-l , .pad-section--s-l .ct-section-inner-wrap {
    padding: 2.7rem 2.4rem;
    padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--s-none , .pad-section--s-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--s , .pad--none--s .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--s-xl , .pad-section--s-xl .ct-section-inner-wrap {
    padding: 3.038rem 2.4rem;
    padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--s-none , .pad-section--s-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--s , .pad--none--s .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--s-xxl , .pad-section--s-xxl .ct-section-inner-wrap {
    padding: 3.42rem 2.4rem;
    padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--s-none , .pad-section--s-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--s , .pad--none--s .ct-section-inner-wrap {
    padding: 0 !important;
  }
}
@media (max-width: 359px) {
  .pad-section--xs-xs , .pad-section--xs-xs .ct-section-inner-wrap {
    padding: 1.896rem 2.4rem;
    padding: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xs-none , .pad-section--xs-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xs , .pad--none--xs .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xs-s , .pad-section--xs-s .ct-section-inner-wrap {
    padding: 2.133rem 2.4rem;
    padding: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xs-none , .pad-section--xs-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xs , .pad--none--xs .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xs-m , .pad-section--xs-m .ct-section-inner-wrap {
    padding: 2.4rem 2.4rem;
    padding: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xs-none , .pad-section--xs-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xs , .pad--none--xs .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xs-l , .pad-section--xs-l .ct-section-inner-wrap {
    padding: 2.7rem 2.4rem;
    padding: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xs-none , .pad-section--xs-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xs , .pad--none--xs .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xs-xl , .pad-section--xs-xl .ct-section-inner-wrap {
    padding: 3.038rem 2.4rem;
    padding: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xs-none , .pad-section--xs-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xs , .pad--none--xs .ct-section-inner-wrap {
    padding: 0 !important;
  }
  .pad-section--xs-xxl , .pad-section--xs-xxl .ct-section-inner-wrap {
    padding: 3.42rem 2.4rem;
    padding: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112))) calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    padding: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem) clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad-section--xs-none , .pad-section--xs-none .ct-section-inner-wrap {
    padding: 0 clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
  .pad--none--xs , .pad--none--xs .ct-section-inner-wrap {
    padding: 0 !important;
  }
}
.pad--none .ct-section-inner-wrap {
  padding: 0 !important;
}
section.fr-hero-padding {
  padding-block: var(--fr-hero-padding);
}
.pad-header--xs {
  padding: 1.896rem 0;
  padding: calc(1.0666666667rem + (0.2666666667 * ((100vw - 32rem) / 112))) 0;
  padding: clamp(1.0666666667rem, calc(0.2380952381vw + 0.9904761905rem), 1.3333333333rem) 0;
}
.pad-header--s {
  padding: 2.133rem 0;
  padding: calc(1.6rem + (0.4 * ((100vw - 32rem) / 112))) 0;
  padding: clamp(1.6rem, calc(0.3571428571vw + 1.4857142857rem), 2rem) 0;
}
.pad-header--m {
  padding: 2.4rem 0;
  padding: calc(2.4rem + (0.6 * ((100vw - 32rem) / 112))) 0;
  padding: clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem) 0;
}
.pad-header--l {
  padding: 2.7rem 0;
  padding: calc(3.1992rem + (1.3008 * ((100vw - 32rem) / 112))) 0;
  padding: clamp(3.1992rem, calc(1.1614285714vw + 2.8275428571rem), 4.5rem) 0;
}
.pad-header--xl {
  padding: 3.038rem 0;
  padding: calc(4.2645336rem + (2.4854664 * ((100vw - 32rem) / 112))) 0;
  padding: clamp(4.2645336rem, calc(2.2191664286vw + 3.5544003429rem), 6.75rem) 0;
}
.pad-header--xxl {
  padding: 3.42rem 0;
  padding: calc(5.6846232888rem + (4.4403767112 * ((100vw - 32rem) / 112))) 0;
  padding: clamp(5.6846232888rem, calc(3.9646220636vw + 4.4159442285rem), 10.125rem) 0;
}
.width--vp-max {
  inline-size: 100%;
  max-inline-size: calc((144 - 3 * 2) * 1rem);
  margin-inline: auto;
}
:root {
  --vp-max: calc((144 - 3 * 2) * 1rem);
}
.display--inline {
  display: inline;
}
.display--block {
  display: block;
}
.display--contents {
  display: contents;
}
.display--inline-block {
  display: inline-block;
}
.display--inline-flex {
  display: inline-flex;
}
.display--list-item {
  display: list-item;
}
.display--none {
  display: none;
}
@media (max-width: 1439px) {
  .display--xxl-block {
    display: block;
  }
  .display--xxl-none {
    display: none;
  }
}
@media (max-width: 1279px) {
  .display--xl-block {
    display: block;
  }
  .display--xl-none {
    display: none;
  }
}
@media (max-width: 991px) {
  .display--l-block {
    display: block;
  }
  .display--l-none {
    display: none;
  }
}
@media (max-width: 767px) {
  .display--m-block {
    display: block;
  }
  .display--m-none {
    display: none;
  }
}
@media (max-width: 479px) {
  .display--s-block {
    display: block;
  }
  .display--s-none {
    display: none;
  }
}
@media (max-width: 359px) {
  .display--xs-block {
    display: block;
  }
  .display--xs-none {
    display: none;
  }
}
