.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: var(--border-radius-lg);
    background-color: var(--color-white);
    justify-content: center;
    margin-top: 2rem;
    padding: var(--spacing-md);
}

.container > h3 {
    font-size: 16px;
    font-weight: 600;
}

.container > p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: var(--spacing-md);
}

.container > ul > li {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: var(--spacing-md);
}
