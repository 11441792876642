/* .splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../assets/images/back.svg') no-repeat center center fixed;
  background-size: cover;
  color: black;
  text-align: center;
} */

.splash-content {
  text-align: center;
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.splash-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.splash-subtitle {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.splash-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
}

.splash-button:hover {
  background-color: #0056b3;
}
