.submitButton {
   background-color: var(--color-primary);
    color: var(--color-white);
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: var(--font-size-md);
    border: none;
    text-align: center;
    border-radius: var(--border-radius-lg);
    cursor: pointer;
    transition: background-color var(--transition-medium);
    width: auto;
  }
  
  .submitButton:hover {
    background-color: var(--color-primary--light);
  }
  
  .submitButton:disabled {
    background-color: var(--color-text-light);
    cursor: not-allowed;
  }
  