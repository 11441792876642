.container {
    width: -webkit-fill-available;
    height: max-content;
    display: flex;
    gap: var(--space-m);
    margin-bottom: var(--space-m);
    flex-direction: column;
}
.builderContent {
    padding: var(--space-m);
    border-radius: var(--border-radius-lg);
    background-color: var(--white);
}
.row {
    display: flex;
}
.wrapper {
    height: 100%;
    border-radius: var(--border-radius-lg);
    background-color: var(--white);
}

.title {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--space-xs);
    margin-top: 0;
}