.container {
    background-color: var(--color-primary--light);
    border-radius: var(--border-radius-lg); 
    overflow-y: scroll;
    width: 0;
    height: calc(100vh - var(--spacing-lg));
    transition: ease 0.4s;
    margin: var(--spacing-md) 0;
}

.container.open {
    transition: ease 0.4s;
    width: fit-content;
    height: calc(100vh - var(--spacing-lg));
    margin: var(--spacing-md) ;
}

.container::-webkit-scrollbar {
    display: none;
}