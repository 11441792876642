.container {
    margin: var(--spacing-md);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-lg);
    background-color: var(--color-white);
}

.h4 {
    font-size: var(--font-size-md);
    color: var(--color-primary);
    margin-bottom: var(--spacing-md);
    font-weight: 500;
    margin-top: 0;
}


.progressContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.progressCircle {
  width: 100px;
  height: 100px;
}

.circleBg {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 2.8;
}

.circle {
  fill: none;
  stroke: #00c2b2;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}

.progressText {
  fill: #333;
  font-size: 0.5em;
  text-anchor: middle;
}

.infos {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.infoValue,
.infoValueCompleted {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.infoValue {
  border: solid 2px var(--color-primary);
}

.infoValueCompleted {
  background-color: var(--color-success);
}

.infoText {
  color: #333;
}