.button {
    display: flex;
    align-items: center;
    background-color: #ff7600;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transform: scale(0.8);
  }
  
  .button:hover {
    background-color: #e66a00;
  }
  
  .icon {
    margin-right: 8px;
  }
  