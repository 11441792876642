.title {
    font-weight: 500;
    font-size: var(--text-m);
}

.container {
    padding: var(--space-m);
}

.buttons {
    margin-top: var(--space-m);
    gap: 5px;
    display: flex;
}