.deleteButton {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  
  .deleteButton svg {
  width: 20px;
  height: 20px;
}

.deleteButton svg:hover {
  width: 20px;
  height: 20px;
  filter: brightness(0.5);
}