/* Container for the loader */
.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure loader is on top */
}

/* Spinner animation */
.spinner {
  width: 80px;
  height: 80px;
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left-color: #3498db; /* Spinner color */
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
