.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    width: 80%;
    max-width: 900px;
    max-height: 90%;
    overflow-y: auto;
  }
  .modal::-webkit-scrollbar{
    display: none;
  }
  
  .modalContent {
    margin-bottom: 20px;
  }
  
  .modalActions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  
  .btn {
    border: none;
    border-bottom-left-radius: 40%;
    cursor: pointer;
    transition: ease 0.2s;
  }
  
  .btnClose {
    background-color: #f44336;
    color: white;
    padding: 10px;
    height: 38px;
    width: min-content;
    font-size: 18px;
  }
  
  .btnExport {
    background-color: #ff9800;
    color: white;
    width: 100%;
  }
  