.dash-sidebar, .dash-layout {
    display: flex;
}

.dash-layout {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.logo {
    margin-top: var(--space-xs);
}

.dash-content {
    padding: var(--space-xs);
    width:100%;
}


