.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-md);
 
  }
  
  .label {
    margin-bottom: var(--spacing-xs);
    font-size: var(--font-size-md);
    color: var(--color-text);
    
  }
  
  .form-group > input[type="file" i] {
    padding: var(--spacing-sm);
    border: 1px solid transparent;
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-md);
    color: var(--color-text);
    background-color: var(--color-primary--light);
    transition: border-color var(--transition-fast);
  }
  
  .input:focus {
    border-color: var(--color-primary);
    outline: none;
  }
  