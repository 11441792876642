.bomDetail {
    margin: 20px 0;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    border-radius: var(--border-radius-lg);
    overflow: hidden;
  }
  
  .table thead {
    background-color: var(--color-secondary);
    color: var(--color-white);
    overflow: hidden;
  }
  
  .table th, .table td {
    padding: 10px;
    
    border: 1px solid #ddd;
    text-align: left;
    
    font-weight: 400!important;
  }
  
  .table th {
    font-weight: bold;
    
  }
  
  .categoryCell {
    font-weight: bold;
    vertical-align: top;
    background-color: #f9f9f9;
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .btnClose {
    background-color: #f44336;
    color: white;
  }
  
  .btnExport {
    background-color: #ff9800;
    color: white;
  }
  