/* components/inputs/AccountTypeRadio.module.css */
.radioLabel {
    display: inline-flex;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 25px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .wrapper> span {
    margin: 0;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .wrapper {
      flex-direction: column;
    }
  }
  .radioLabel.checked {
    border-color: #00b894; /* Active border color */
    background-color: #e8f7f4; /* Active background color */
  }
  
  .radioLabel.checked .labelText {
    color: #00b894; /* Active text color */
  }
  
  .radioLabel.checked .icon {
    color: #00b894; /* Active icon color */
  }
  
  .radioInput {
    display: none;
  }
  
  .icon {
    margin-right: 10px;
    font-size: 24px;
    color: #ccc; /* Default icon color */
  }
  
  .labelText {
    font-size: 16px;
    color: #333; /* Default text color */
  }
  