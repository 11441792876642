.team {
  border-bottom: 0.5px solid rgba(222, 222, 222, 1);
  transition: all 0.3s;
}

.team:hover {
  background-color: rgba(245, 245, 245, 1);
}

.team:last-child {
  border-bottom: none;
}

.name {
  font-size: 14px;
  color: var(--color-text);
  cursor: pointer;
  transition: ease 0.2s;
}

.name:hover {
  font-weight: 600;
}

.expertise, .exp_year {
  color: rgba(131, 146, 171, 1);
  font-size: 12px;
  font-weight: 700;
}

.actions {
  display: flex;
  gap: var(--spacing-sm);
}

.container {
  padding: var(--space-xs);
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
}

.load {
  padding: var(--space-xs);
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 10px;
  text-align: left;
}

.table th {
  color: var(--color-text-medium);
  font-size: 14px;
  font-weight: 700;
}

.empty {
  text-align: center!important;
  padding: var(--space-l) 0!important;
  font-size: 16px;
  font-weight: 600;
}