.container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    overflow-y: scroll;    
       margin: 0 0 10rem 0;
}
.container.open {
    width: fit-content;
}
.container.closed {
    width: 0;
}
.container::-webkit-scrollbar {
    display: none;
}
.container::-webkit-scrollbar {
 display: none;
}