.sidebar {
   width: 350px;
    background-color: white;
    padding: var(--space-m);
    height: calc(100vh - 100px);
    box-sizing: border-box;
    border-radius: var(--border-radius-lg);
    margin: 0 var(--spacing-sm);
    overflow: hidden;
  }
  .elementsList {
    margin-top: var(--space-m);
    overflow-y: scroll;
    height: 90%;
  }
 
  
  .elementGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .elementGroup button {
    padding: 10px;
    font-size: 16px;
    background-color: var(--color-primary);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .elementGroup button:hover {
    background-color: var(--color-primary--medium);
  }
  
  .title {
    margin-top: 0;
  }

  