.portfolioBuilder {
    padding: var(--space-xs);
  }
  
  .buttonGroup {
    margin-bottom: 20px;
    display: flex;
    gap: var(--space-xs);
  }
  
  .builderContent {
    display: flex;
  }
  
  .elementsList {
    flex: 1;
    margin-right: 20px;
  }
  
  .elementForm {
    flex: 1;
  }
  
  .saveButton {
    margin-top: 20px;
  }
  