.right-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.container {
    margin-bottom: var(--space-m);
}


.wrapper-menu {
  margin-right: var(--space-m);
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.wrapper-menu.open {
  transform: rotate(-45deg);  
}

.line-menu {
  background-color: var(--color-primary);
  border-radius: 5px;
  width: 100%;
  height: 4px;
}

.line-menu.half {
  width: 50%;
}

.line-menu.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right;
}

.open .line-menu.start {
  transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left;
}

.open .line-menu.end {
  transform: rotate(-90deg) translateX(-3px);
}