.form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .input,
  .select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .addButton {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .addButton:hover {
    background-color: #0056b3;
  }
  