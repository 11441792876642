
  .teamMember:hover {
    background-color: var(--color-primary--light);
  }
  .teamMember > div {
    display: flex;
    gap: var(--spacing-sm);
  }
  .editButton, .deleteButton {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .editButton {
    background-color: #007bff;
    color: white;
  }
  
  .deleteButton {
    background-color: #dc3545;
    color: white;
  }
  
  .editButton:hover {
    background-color: #0056b3;
  }
  
  .deleteButton:hover {
    background-color: #c82333;
  }
  .teamMember {
    border-bottom: 0.5px solid rgba(222, 222, 222, 1);
  
    font-size: 14px;
    transition: all 0.3s;
  }
.teamMember:last-child{
  border-bottom:unset!important ;
}
.title{
    font-size: 12px;
    color: var(--color-text);
      cursor: pointer;
      transition: ease 0.2s;
}
.title:hover{
  font-weight: 600;
}
.cost, .time-line, .creation-date  {
    color: rgba(131, 146, 171, 1);
    font-size: 12px;
    font-weight: 700;
}


.actions {
    display: flex;
    gap: var(--spacing-sm);
}
