.inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-md);
  }
  
  .label {
    margin-bottom: var(--spacing-xs);
    font-size: var(--font-size-md);
    color: var(--color-text);
  }
  
  .select {
    padding: var(--spacing-sm);
    border: 1px solid transparent;
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-sm);
    font-weight: 600;
    color: var(--color-text);
    background-color: var(--color-primary--light);
    transition: border-color var(--transition-fast);
  }
  
  .select:focus {
    border-color: var(--color-primary);
    outline: none;
  }
  .errorMessage {
    font-size: 12px;
    color: red;
  }