
  .authLeft {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-lg);
  }
  
  .logo {
    max-width: 100%;
    height: auto;
  }
  
  .authRight {
    flex: 2;
    padding: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: var(--border-radius-md);
  }
  
  h2 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-md);
    color: var(--color-text);
  }
  
  .formGroup {
    margin-bottom: var(--spacing-md);
  }
  
  .formGroup label {
    display: block;
    font-size: var(--font-size-sm);
    margin-bottom: var(--spacing-xs);
    color: var(--color-text-light);
  }
  
  .formGroup input {
    width: 100%;
    padding: var(--spacing-sm);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-md);
  }
  
  .formOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-md);
  }
  
  .forgotPassword {
    font-size: var(--font-size-sm);
    color: var(--color-primary);
  }
  
  .authButton {
    width: 100%;
    padding: var(--spacing-md);
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: var(--border-radius-xl);
    font-size: var(--font-size-md);
    cursor: pointer;
  }
  
  .authFooter {
    margin-top: var(--spacing-md);
    text-align: center;
  }
  
  .authFooter p {
    font-size: var(--font-size-sm);
    color: var(--color-text-light);
  }
  
  .socialButtons {
    display: flex;
    justify-content: space-around;
    margin-top: var(--spacing-md);
  }
  
  .googleButton,
  .facebookButton {
    flex: 1;
    margin: 0 var(--spacing-xs);
    padding: var(--spacing-sm);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-sm);
    background-color: white;
    cursor: pointer;
  }
  
  .googleButton {
    color: var(--color-primary);
  }
  
  .facebookButton {
    color: var(--color-tertiary);
  }
  .authFooter > p > a {
    text-decoration: none;
    color: var(--color-primary);
  }