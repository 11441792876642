.toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding:  calc(var(--space-xs) * 0.6) var(--space-xs);
    width: 150px;
    border-radius: var(--border-radius-md);
    height: fit-content;
    transition: background-color 0.3s;
    font-size: var(--text-s);
}

.toggle:hover {
    background-color: var(--color-primary--medium);
}
.wrapper{
    display: flex;
    align-items: center;
    justify-items: center;
}
.wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-sm);
    flex-wrap: nowrap;
}

.editTitle {
    color: var(--color-primary);
    cursor: pointer;
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
    border: solid 1px var(--color-primary);
    transition: ease 0.3s;
}

.editTitle:hover {
    color: var(--color-white);
    background-color: var(--color-primary);
}