html {
  font-size: 62.5%;
  background-color: var(--coloe-light);
}
:root {
    /* Colors */
    --color-primary: rgba(255, 127, 0, 1);
    --color-primary--medium: rgba(255, 179, 103, 1);
    --color-primary--light: rgba(255, 230, 206, 1);
    --color-secondary: rgba(103, 116, 142, 1);
    --color-tertiary: #e74c3c;
    --color-background: #ecf0f1;
    --color-background-dark: #bdc3c7;
    --color-text: rgba(37, 47, 64, 1);
    --color-text-medium:rgba(131, 146, 171, 1);
    --color-text-light: rgba(87, 89, 92, 1);
    --color-border: #dcdcdc;
    --color-success: #2ecc71;
    --color-error: #e74c3c;
    --color-white: #fff;
    --color-warning: #f39c12;
    --color-info: #3498db;
    --coloe-light: rgba(246, 246, 246, 1);
    /* Spacing */
    --spacing-xxs: 2px;
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 48px;
    --spacing-xxxl: 64px;
  
    /* Font Sizes */
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;
    --font-size-lg: 20px;
    --font-size-xl: 24px;
    --font-size-xxl: 32px;
    --font-size-xxxl: 40px;
  
    /* Font Weights */
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-bold: 700;
    --font-weight-bolder: 800;
  
    /* Line Heights */
    --line-height-xs: 1.1;
    --line-height-sm: 1.2;
    --line-height-md: 1.5;
    --line-height-lg: 1.75;
    --line-height-xl: 2;
  
    /* Border Radius */
    --border-radius-xs: 2px;
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 16px;
    --border-radius-xl: 24px;
  
    /* Borders */
    --border-width-xs: 0.5px;
    --border-width-sm: 1px;
    --border-width-md: 2px;
    --border-width-lg: 4px;
    --border-width-xl: 6px;
    --border-color: var(--color-border);
  
    /* Shadows */
    --shadow-xs: 0 1px 2px rgba(0, 0, 0, 0.1);
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
    --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.15);
    --shadow-lg: 0 6px 12px rgba(0, 0, 0, 0.2);
    --shadow-xl: 0 8px 16px rgba(0, 0, 0, 0.25);
  
    /* Transition */
    --transition-fast: 0.2s ease-in-out;
    --transition-medium: 0.3s ease-in-out;
    --transition-slow: 0.5s ease-in-out;
  
    /* Z-index */
    --z-index-modal: 1000;
    --z-index-dropdown: 1000;
    --z-index-toast: 1500;
    --z-index-overlay: 2000;
    --z-index-fixed: 3000;
  
    /* Layout */
    --max-width-container: 1200px;
    --padding-container: 16px;
    --margin-container: 16px;
    --grid-gutter: 16px;
  
    /* Grid */
    --grid-columns: 12;
    --grid-column-gap: var(--spacing-md);
    --grid-row-gap: var(--spacing-lg);
  
    /* Typography */
    --heading-font-family: 'Arial', sans-serif;
    --body-font-family: 'Roboto', sans-serif;
    --heading-font-weight: var(--font-weight-bold);
    --body-font-weight: var(--font-weight-normal);
    --text-align-left: left;
    --text-align-center: center;
    --text-align-right: right;
  
    /* Miscellaneous */
    --link-color: var(--color-primary);
    --link-hover-color: var(--color-secondary);
    --button-font-family: inherit;
    --button-font-weight: var(--font-weight-bold);
    --button-padding: var(--spacing-sm) var(--spacing-md);
  }
  
  @supports (font-size: calc(1vw + 2vw)) {
    :root {
      --text-xs: calc(1.1rem + (0 * ((100vw - 32rem) / 112)));
      --text-s: calc(1.2rem + (0 * ((100vw - 32rem) / 112)));
      --text-m: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
      --text-l: calc(1.92rem + (0.4794 * ((100vw - 32rem) / 112)));
      --text-xl: calc(2.304rem + (0.8944002 * ((100vw - 32rem) / 112)));
      --text-xxl: calc(2.7648rem + (1.4986674666 * ((100vw - 32rem) / 112)));
      --h6: calc(1.1rem + (0 * ((100vw - 32rem) / 112)));
      --h5: calc(1.2rem + (0 * ((100vw - 32rem) / 112)));
      --h4: calc(1.6rem + (0.2 * ((100vw - 32rem) / 112)));
      --h3: calc(1.92rem + (0.4794 * ((100vw - 32rem) / 112)));
      --h2: calc(2.304rem + (0.8944002 * ((100vw - 32rem) / 112)));
      --h1: calc(2.7648rem + (1.4986674666 * ((100vw - 32rem) / 112)));
    }
    @media (min-width: 1279px) {
      :root {
        --text-xs: 1.1rem;
        --text-s: 1.2rem;
        --text-m: 1.8rem;
        --text-l: 2.3994rem;
        --text-xl: 3.1984002rem;
        --text-xxl: 4.2634674666rem;
        --h6: 1.1rem;
        --h5: 1.2rem;
        --h4: 1.8rem;
        --h3: 2.3994rem;
        --h2: 3.1984002rem;
        --h1: 4.2634674666rem;
      }
    }
  }
  @supports (font-size: clamp(1vw, 2vw, 3vw)) {
    :root {
      --text-xs: clamp(1.1rem, calc(0vw + 1.1rem), 1.1rem);
      --text-s: clamp(1.2rem, calc(0vw + 1.2rem), 1.2rem);
      --text-m: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
      --text-l: clamp(1.92rem, calc(0.4280357143vw + 1.7830285714rem), 2.3994rem);
      --text-xl: clamp(2.304rem, calc(0.7985716071vw + 2.0484570857rem), 3.1984002rem);
      --text-xxl: clamp(2.7648rem, calc(1.3380959523vw + 2.3366092953rem), 4.2634674666rem);
      --h6: clamp(1.1rem, calc(0vw + 1.1rem), 1.1rem);
      --h5: clamp(1.2rem, calc(0vw + 1.2rem), 1.2rem);
      --h4: clamp(1.6rem, calc(0.1785714286vw + 1.5428571429rem), 1.8rem);
      --h3: clamp(1.92rem, calc(0.4280357143vw + 1.7830285714rem), 2.3994rem);
      --h2: clamp(2.304rem, calc(0.7985716071vw + 2.0484570857rem), 3.1984002rem);
      --h1: clamp(2.7648rem, calc(1.3380959523vw + 2.3366092953rem), 4.2634674666rem);
    }
  }
  @supports (padding: calc(1vw + 2vw)) {
    :root {
      --space-xs: calc(1.0666666667rem + (0.2666666667 * ((100vw - 32rem) / 112)));
      --space-s: calc(1.6rem + (0.4 * ((100vw - 32rem) / 112)));
      --space-m: calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
      --space-l: calc(3.1992rem + (1.3008 * ((100vw - 32rem) / 112)));
      --space-xl: calc(4.2645336rem + (2.4854664 * ((100vw - 32rem) / 112)));
      --space-xxl: calc(5.6846232888rem + (4.4403767112 * ((100vw - 32rem) / 112)));
      --section-space-xs: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112)));
      --section-space-s: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112)));
      --section-space-m: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112)));
      --section-space-l: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112)));
      --section-space-xl: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112)));
      --section-space-xxl: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112)));
      --section-padding-x: calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    }
    @media (min-width: 1279px) {
      :root {
        --space-xs: 1.3333333333rem;
        --space-s: 2rem;
        --space-m: 3rem;
        --space-l: 4.5rem;
        --space-xl: 6.75rem;
        --space-xxl: 10.125rem;
        --section-space-xs: 5.3333333333rem;
        --section-space-s: 8rem;
        --section-space-m: 12rem;
        --section-space-l: 18rem;
        --section-space-xl: 27rem;
        --section-space-xxl: 40.5rem;
        --section-padding-x: 3rem;
      }
    }
  }

  :root{
    --action: #000000;
    --action-hover: black;
    --action-ultra-light: #f2f2f2;
    --action-light: #d9d9d9;
    --action-medium: gray;
    --action-dark: #404040;
    --action-ultra-dark: #1a1a1a;
    --action-comp: black;
    --action-trans-10: rgba(0, 0, 0, 0.1);
    --action-trans-20: rgba(0, 0, 0, 0.2);
    --action-trans-30: rgba(0, 0, 0, 0.3);
    --action-trans-40: rgba(0, 0, 0, 0.4);
    --action-trans-50: rgba(0, 0, 0, 0.5);
    --action-trans-60: rgba(0, 0, 0, 0.6);
    --action-trans-70: rgba(0, 0, 0, 0.7);
    --action-trans-80: rgba(0, 0, 0, 0.8);
    --action-trans-90: rgba(0, 0, 0, 0.9);
    --action-light-trans-10: rgba(217, 217, 217, 0.1);
    --action-light-trans-20: rgba(217, 217, 217, 0.2);
    --action-light-trans-30: rgba(217, 217, 217, 0.3);
    --action-light-trans-40: rgba(217, 217, 217, 0.4);
    --action-light-trans-50: rgba(217, 217, 217, 0.5);
    --action-light-trans-60: rgba(217, 217, 217, 0.6);
    --action-light-trans-70: rgba(217, 217, 217, 0.7);
    --action-light-trans-80: rgba(217, 217, 217, 0.8);
    --action-light-trans-90: rgba(217, 217, 217, 0.9);
    --action-dark-trans-10: rgba(64, 64, 64, 0.1);
    --action-dark-trans-20: rgba(64, 64, 64, 0.2);
    --action-dark-trans-30: rgba(64, 64, 64, 0.3);
    --action-dark-trans-40: rgba(64, 64, 64, 0.4);
    --action-dark-trans-50: rgba(64, 64, 64, 0.5);
    --action-dark-trans-60: rgba(64, 64, 64, 0.6);
    --action-dark-trans-70: rgba(64, 64, 64, 0.7);
    --action-dark-trans-80: rgba(64, 64, 64, 0.8);
    --action-dark-trans-90: rgba(64, 64, 64, 0.9);
    --action-ultra-dark-trans-10: rgba(26, 26, 26, 0.1);
    --action-ultra-dark-trans-20: rgba(26, 26, 26, 0.2);
    --action-ultra-dark-trans-30: rgba(26, 26, 26, 0.3);
    --action-ultra-dark-trans-40: rgba(26, 26, 26, 0.4);
    --action-ultra-dark-trans-50: rgba(26, 26, 26, 0.5);
    --action-ultra-dark-trans-60: rgba(26, 26, 26, 0.6);
    --action-ultra-dark-trans-70: rgba(26, 26, 26, 0.7);
    --action-ultra-dark-trans-80: rgba(26, 26, 26, 0.8);
    --action-ultra-dark-trans-90: rgba(26, 26, 26, 0.9);
    --action-hex: #000000;
    --action-hsl: 0 0% 0%;
    --action-rgb: 0 0 0;
    --action-r: 0;
    --action-g: 0;
    --action-b: 0;
    --action-h: 0;
    --action-s: 0%;
    --action-l: 0%;
    --action-hover-h: 0;
    --action-hover-s: 0%;
    --action-hover-l: 1.15;
    --action-ultra-light-h: 0;
    --action-ultra-light-s: 0%;
    --action-ultra-light-l: 95%;
    --action-light-h: 0;
    --action-light-s: 0%;
    --action-light-l: 85%;
    --action-medium-h: 0;
    --action-medium-s: 0%;
    --action-medium-l: 50%;
    --action-dark-h: 0;
    --action-dark-s: 0%;
    --action-dark-l: 25%;
    --action-ultra-dark-h: 0;
    --action-ultra-dark-s: 0%;
    --action-ultra-dark-l: 10%;
    --action-comp-h: 180;
    --primary: #2d3b79;
    --primary-hover: #34458d;
    --primary-ultra-light: #eceff8;
    --primary-light: #c7ceea;
    --primary-medium: #455aba;
    --primary-dark: #222d5d;
    --primary-ultra-dark: #0e1225;
    --primary-comp: #7b6d2d;
    --primary-trans-10: rgba(45, 59, 121, 0.1);
    --primary-trans-20: rgba(45, 59, 121, 0.2);
    --primary-trans-30: rgba(45, 59, 121, 0.3);
    --primary-trans-40: rgba(45, 59, 121, 0.4);
    --primary-trans-50: rgba(45, 59, 121, 0.5);
    --primary-trans-60: rgba(45, 59, 121, 0.6);
    --primary-trans-70: rgba(45, 59, 121, 0.7);
    --primary-trans-80: rgba(45, 59, 121, 0.8);
    --primary-trans-90: rgba(45, 59, 121, 0.9);
    --primary-light-trans-10: rgba(199, 206, 234, 0.1);
    --primary-light-trans-20: rgba(199, 206, 234, 0.2);
    --primary-light-trans-30: rgba(199, 206, 234, 0.3);
    --primary-light-trans-40: rgba(199, 206, 234, 0.4);
    --primary-light-trans-50: rgba(199, 206, 234, 0.5);
    --primary-light-trans-60: rgba(199, 206, 234, 0.6);
    --primary-light-trans-70: rgba(199, 206, 234, 0.7);
    --primary-light-trans-80: rgba(199, 206, 234, 0.8);
    --primary-light-trans-90: rgba(199, 206, 234, 0.9);
    --primary-dark-trans-10: rgba(34, 45, 93, 0.1);
    --primary-dark-trans-20: rgba(34, 45, 93, 0.2);
    --primary-dark-trans-30: rgba(34, 45, 93, 0.3);
    --primary-dark-trans-40: rgba(34, 45, 93, 0.4);
    --primary-dark-trans-50: rgba(34, 45, 93, 0.5);
    --primary-dark-trans-60: rgba(34, 45, 93, 0.6);
    --primary-dark-trans-70: rgba(34, 45, 93, 0.7);
    --primary-dark-trans-80: rgba(34, 45, 93, 0.8);
    --primary-dark-trans-90: rgba(34, 45, 93, 0.9);
    --primary-ultra-dark-trans-10: rgba(14, 18, 37, 0.1);
    --primary-ultra-dark-trans-20: rgba(14, 18, 37, 0.2);
    --primary-ultra-dark-trans-30: rgba(14, 18, 37, 0.3);
    --primary-ultra-dark-trans-40: rgba(14, 18, 37, 0.4);
    --primary-ultra-dark-trans-50: rgba(14, 18, 37, 0.5);
    --primary-ultra-dark-trans-60: rgba(14, 18, 37, 0.6);
    --primary-ultra-dark-trans-70: rgba(14, 18, 37, 0.7);
    --primary-ultra-dark-trans-80: rgba(14, 18, 37, 0.8);
    --primary-ultra-dark-trans-90: rgba(14, 18, 37, 0.9);
    --primary-hex: #2d3b79;
    --primary-hsl: 229 46% 33%;
    --primary-rgb: 45 59 121;
    --primary-r: 45;
    --primary-g: 59;
    --primary-b: 121;
    --primary-h: 229;
    --primary-s: 46%;
    --primary-l: 33%;
    --primary-hover-h: 229;
    --primary-hover-s: 46%;
    --primary-hover-l: 1.15;
    --primary-ultra-light-h: 229;
    --primary-ultra-light-s: 46%;
    --primary-ultra-light-l: 95%;
    --primary-light-h: 229;
    --primary-light-s: 46%;
    --primary-light-l: 85%;
    --primary-medium-h: 229;
    --primary-medium-s: 46%;
    --primary-medium-l: 50%;
    --primary-dark-h: 229;
    --primary-dark-s: 46%;
    --primary-dark-l: 25%;
    --primary-ultra-dark-h: 229;
    --primary-ultra-dark-s: 46%;
    --primary-ultra-dark-l: 10%;
    --primary-comp-h: 409;
    --secondary: #858689;
    --secondary-hover: #999a9d;
    --secondary-ultra-light: #f2f2f3;
    --secondary-light: #d8d8da;
    --secondary-medium: #7d7e82;
    --secondary-dark: #3e3f41;
    --secondary-ultra-dark: #19191a;
    --secondary-comp: #8a8885;
    --secondary-trans-10: rgba(133, 134, 137, 0.1);
    --secondary-trans-20: rgba(133, 134, 137, 0.2);
    --secondary-trans-30: rgba(133, 134, 137, 0.3);
    --secondary-trans-40: rgba(133, 134, 137, 0.4);
    --secondary-trans-50: rgba(133, 134, 137, 0.5);
    --secondary-trans-60: rgba(133, 134, 137, 0.6);
    --secondary-trans-70: rgba(133, 134, 137, 0.7);
    --secondary-trans-80: rgba(133, 134, 137, 0.8);
    --secondary-trans-90: rgba(133, 134, 137, 0.9);
    --secondary-light-trans-10: rgba(216, 216, 218, 0.1);
    --secondary-light-trans-20: rgba(216, 216, 218, 0.2);
    --secondary-light-trans-30: rgba(216, 216, 218, 0.3);
    --secondary-light-trans-40: rgba(216, 216, 218, 0.4);
    --secondary-light-trans-50: rgba(216, 216, 218, 0.5);
    --secondary-light-trans-60: rgba(216, 216, 218, 0.6);
    --secondary-light-trans-70: rgba(216, 216, 218, 0.7);
    --secondary-light-trans-80: rgba(216, 216, 218, 0.8);
    --secondary-light-trans-90: rgba(216, 216, 218, 0.9);
    --secondary-dark-trans-10: rgba(62, 63, 65, 0.1);
    --secondary-dark-trans-20: rgba(62, 63, 65, 0.2);
    --secondary-dark-trans-30: rgba(62, 63, 65, 0.3);
    --secondary-dark-trans-40: rgba(62, 63, 65, 0.4);
    --secondary-dark-trans-50: rgba(62, 63, 65, 0.5);
    --secondary-dark-trans-60: rgba(62, 63, 65, 0.6);
    --secondary-dark-trans-70: rgba(62, 63, 65, 0.7);
    --secondary-dark-trans-80: rgba(62, 63, 65, 0.8);
    --secondary-dark-trans-90: rgba(62, 63, 65, 0.9);
    --secondary-ultra-dark-trans-10: rgba(25, 25, 26, 0.1);
    --secondary-ultra-dark-trans-20: rgba(25, 25, 26, 0.2);
    --secondary-ultra-dark-trans-30: rgba(25, 25, 26, 0.3);
    --secondary-ultra-dark-trans-40: rgba(25, 25, 26, 0.4);
    --secondary-ultra-dark-trans-50: rgba(25, 25, 26, 0.5);
    --secondary-ultra-dark-trans-60: rgba(25, 25, 26, 0.6);
    --secondary-ultra-dark-trans-70: rgba(25, 25, 26, 0.7);
    --secondary-ultra-dark-trans-80: rgba(25, 25, 26, 0.8);
    --secondary-ultra-dark-trans-90: rgba(25, 25, 26, 0.9);
    --secondary-hex: #858689;
    --secondary-hsl: 225 2% 53%;
    --secondary-rgb: 133 134 137;
    --secondary-r: 133;
    --secondary-g: 134;
    --secondary-b: 137;
    --secondary-h: 225;
    --secondary-s: 2%;
    --secondary-l: 53%;
    --secondary-hover-h: 225;
    --secondary-hover-s: 2%;
    --secondary-hover-l: 1.15;
    --secondary-ultra-light-h: 225;
    --secondary-ultra-light-s: 2%;
    --secondary-ultra-light-l: 95%;
    --secondary-light-h: 225;
    --secondary-light-s: 2%;
    --secondary-light-l: 85%;
    --secondary-medium-h: 225;
    --secondary-medium-s: 2%;
    --secondary-medium-l: 50%;
    --secondary-dark-h: 225;
    --secondary-dark-s: 2%;
    --secondary-dark-l: 25%;
    --secondary-ultra-dark-h: 225;
    --secondary-ultra-dark-s: 2%;
    --secondary-ultra-dark-l: 10%;
    --secondary-comp-h: 405;
    --accent: #d80621;
    --accent-hover: #f90929;
    --accent-ultra-light: #fee6e9;
    --accent-light: #fdb4be;
    --accent-medium: #f90627;
    --accent-dark: #7c0313;
    --accent-ultra-dark: #320108;
    --accent-comp: #06dbbe;
    --accent-trans-10: rgba(216, 6, 33, 0.1);
    --accent-trans-20: rgba(216, 6, 33, 0.2);
    --accent-trans-30: rgba(216, 6, 33, 0.3);
    --accent-trans-40: rgba(216, 6, 33, 0.4);
    --accent-trans-50: rgba(216, 6, 33, 0.5);
    --accent-trans-60: rgba(216, 6, 33, 0.6);
    --accent-trans-70: rgba(216, 6, 33, 0.7);
    --accent-trans-80: rgba(216, 6, 33, 0.8);
    --accent-trans-90: rgba(216, 6, 33, 0.9);
    --accent-light-trans-10: rgba(253, 180, 190, 0.1);
    --accent-light-trans-20: rgba(253, 180, 190, 0.2);
    --accent-light-trans-30: rgba(253, 180, 190, 0.3);
    --accent-light-trans-40: rgba(253, 180, 190, 0.4);
    --accent-light-trans-50: rgba(253, 180, 190, 0.5);
    --accent-light-trans-60: rgba(253, 180, 190, 0.6);
    --accent-light-trans-70: rgba(253, 180, 190, 0.7);
    --accent-light-trans-80: rgba(253, 180, 190, 0.8);
    --accent-light-trans-90: rgba(253, 180, 190, 0.9);
    --accent-dark-trans-10: rgba(124, 3, 19, 0.1);
    --accent-dark-trans-20: rgba(124, 3, 19, 0.2);
    --accent-dark-trans-30: rgba(124, 3, 19, 0.3);
    --accent-dark-trans-40: rgba(124, 3, 19, 0.4);
    --accent-dark-trans-50: rgba(124, 3, 19, 0.5);
    --accent-dark-trans-60: rgba(124, 3, 19, 0.6);
    --accent-dark-trans-70: rgba(124, 3, 19, 0.7);
    --accent-dark-trans-80: rgba(124, 3, 19, 0.8);
    --accent-dark-trans-90: rgba(124, 3, 19, 0.9);
    --accent-ultra-dark-trans-10: rgba(50, 1, 8, 0.1);
    --accent-ultra-dark-trans-20: rgba(50, 1, 8, 0.2);
    --accent-ultra-dark-trans-30: rgba(50, 1, 8, 0.3);
    --accent-ultra-dark-trans-40: rgba(50, 1, 8, 0.4);
    --accent-ultra-dark-trans-50: rgba(50, 1, 8, 0.5);
    --accent-ultra-dark-trans-60: rgba(50, 1, 8, 0.6);
    --accent-ultra-dark-trans-70: rgba(50, 1, 8, 0.7);
    --accent-ultra-dark-trans-80: rgba(50, 1, 8, 0.8);
    --accent-ultra-dark-trans-90: rgba(50, 1, 8, 0.9);
    --accent-hex: #d80621;
    --accent-hsl: 352 95% 44%;
    --accent-rgb: 216 6 33;
    --accent-r: 216;
    --accent-g: 6;
    --accent-b: 33;
    --accent-h: 352;
    --accent-s: 95%;
    --accent-l: 44%;
    --accent-hover-h: 352;
    --accent-hover-s: 95%;
    --accent-hover-l: 1.15;
    --accent-ultra-light-h: 352;
    --accent-ultra-light-s: 95%;
    --accent-ultra-light-l: 95%;
    --accent-light-h: 352;
    --accent-light-s: 95%;
    --accent-light-l: 85%;
    --accent-medium-h: 352;
    --accent-medium-s: 95%;
    --accent-medium-l: 50%;
    --accent-dark-h: 352;
    --accent-dark-s: 95%;
    --accent-dark-l: 25%;
    --accent-ultra-dark-h: 352;
    --accent-ultra-dark-s: 95%;
    --accent-ultra-dark-l: 10%;
    --accent-comp-h: 532;
    --base: #292929;
    --base-hover: #2f2f2f;
    --base-ultra-light: #f2f2f2;
    --base-light: #d9d9d9;
    --base-medium: gray;
    --base-dark: #404040;
    --base-ultra-dark: #1a1a1a;
    --base-comp: #292929;
    --base-trans-10: rgba(41, 41, 41, 0.1);
    --base-trans-20: rgba(41, 41, 41, 0.2);
    --base-trans-30: rgba(41, 41, 41, 0.3);
    --base-trans-40: rgba(41, 41, 41, 0.4);
    --base-trans-50: rgba(41, 41, 41, 0.5);
    --base-trans-60: rgba(41, 41, 41, 0.6);
    --base-trans-70: rgba(41, 41, 41, 0.7);
    --base-trans-80: rgba(41, 41, 41, 0.8);
    --base-trans-90: rgba(41, 41, 41, 0.9);
    --base-light-trans-10: rgba(217, 217, 217, 0.1);
    --base-light-trans-20: rgba(217, 217, 217, 0.2);
    --base-light-trans-30: rgba(217, 217, 217, 0.3);
    --base-light-trans-40: rgba(217, 217, 217, 0.4);
    --base-light-trans-50: rgba(217, 217, 217, 0.5);
    --base-light-trans-60: rgba(217, 217, 217, 0.6);
    --base-light-trans-70: rgba(217, 217, 217, 0.7);
    --base-light-trans-80: rgba(217, 217, 217, 0.8);
    --base-light-trans-90: rgba(217, 217, 217, 0.9);
    --base-dark-trans-10: rgba(64, 64, 64, 0.1);
    --base-dark-trans-20: rgba(64, 64, 64, 0.2);
    --base-dark-trans-30: rgba(64, 64, 64, 0.3);
    --base-dark-trans-40: rgba(64, 64, 64, 0.4);
    --base-dark-trans-50: rgba(64, 64, 64, 0.5);
    --base-dark-trans-60: rgba(64, 64, 64, 0.6);
    --base-dark-trans-70: rgba(64, 64, 64, 0.7);
    --base-dark-trans-80: rgba(64, 64, 64, 0.8);
    --base-dark-trans-90: rgba(64, 64, 64, 0.9);
    --base-ultra-dark-trans-10: rgba(26, 26, 26, 0.1);
    --base-ultra-dark-trans-20: rgba(26, 26, 26, 0.2);
    --base-ultra-dark-trans-30: rgba(26, 26, 26, 0.3);
    --base-ultra-dark-trans-40: rgba(26, 26, 26, 0.4);
    --base-ultra-dark-trans-50: rgba(26, 26, 26, 0.5);
    --base-ultra-dark-trans-60: rgba(26, 26, 26, 0.6);
    --base-ultra-dark-trans-70: rgba(26, 26, 26, 0.7);
    --base-ultra-dark-trans-80: rgba(26, 26, 26, 0.8);
    --base-ultra-dark-trans-90: rgba(26, 26, 26, 0.9);
    --base-hex: #292929;
    --base-hsl: 0 0% 16%;
    --base-rgb: 41 41 41;
    --base-r: 41;
    --base-g: 41;
    --base-b: 41;
    --base-h: 0;
    --base-s: 0%;
    --base-l: 16%;
    --base-hover-h: 0;
    --base-hover-s: 0%;
    --base-hover-l: 1.15;
    --base-ultra-light-h: 0;
    --base-ultra-light-s: 0%;
    --base-ultra-light-l: 95%;
    --base-light-h: 0;
    --base-light-s: 0%;
    --base-light-l: 85%;
    --base-medium-h: 0;
    --base-medium-s: 0%;
    --base-medium-l: 50%;
    --base-dark-h: 0;
    --base-dark-s: 0%;
    --base-dark-l: 25%;
    --base-ultra-dark-h: 0;
    --base-ultra-dark-s: 0%;
    --base-ultra-dark-l: 10%;
    --base-comp-h: 180;
    --shade: #000000;
    --shade-hover: black;
    --shade-ultra-light: #f2f2f2;
    --shade-light: #d9d9d9;
    --shade-medium: gray;
    --shade-dark: #404040;
    --shade-ultra-dark: #1a1a1a;
    --shade-comp: black;
    --shade-trans-10: rgba(0, 0, 0, 0.1);
    --shade-trans-20: rgba(0, 0, 0, 0.2);
    --shade-trans-30: rgba(0, 0, 0, 0.3);
    --shade-trans-40: rgba(0, 0, 0, 0.4);
    --shade-trans-50: rgba(0, 0, 0, 0.5);
    --shade-trans-60: rgba(0, 0, 0, 0.6);
    --shade-trans-70: rgba(0, 0, 0, 0.7);
    --shade-trans-80: rgba(0, 0, 0, 0.8);
    --shade-trans-90: rgba(0, 0, 0, 0.9);
    --shade-light-trans-10: rgba(217, 217, 217, 0.1);
    --shade-light-trans-20: rgba(217, 217, 217, 0.2);
    --shade-light-trans-30: rgba(217, 217, 217, 0.3);
    --shade-light-trans-40: rgba(217, 217, 217, 0.4);
    --shade-light-trans-50: rgba(217, 217, 217, 0.5);
    --shade-light-trans-60: rgba(217, 217, 217, 0.6);
    --shade-light-trans-70: rgba(217, 217, 217, 0.7);
    --shade-light-trans-80: rgba(217, 217, 217, 0.8);
    --shade-light-trans-90: rgba(217, 217, 217, 0.9);
    --shade-dark-trans-10: rgba(64, 64, 64, 0.1);
    --shade-dark-trans-20: rgba(64, 64, 64, 0.2);
    --shade-dark-trans-30: rgba(64, 64, 64, 0.3);
    --shade-dark-trans-40: rgba(64, 64, 64, 0.4);
    --shade-dark-trans-50: rgba(64, 64, 64, 0.5);
    --shade-dark-trans-60: rgba(64, 64, 64, 0.6);
    --shade-dark-trans-70: rgba(64, 64, 64, 0.7);
    --shade-dark-trans-80: rgba(64, 64, 64, 0.8);
    --shade-dark-trans-90: rgba(64, 64, 64, 0.9);
    --shade-ultra-dark-trans-10: rgba(26, 26, 26, 0.1);
    --shade-ultra-dark-trans-20: rgba(26, 26, 26, 0.2);
    --shade-ultra-dark-trans-30: rgba(26, 26, 26, 0.3);
    --shade-ultra-dark-trans-40: rgba(26, 26, 26, 0.4);
    --shade-ultra-dark-trans-50: rgba(26, 26, 26, 0.5);
    --shade-ultra-dark-trans-60: rgba(26, 26, 26, 0.6);
    --shade-ultra-dark-trans-70: rgba(26, 26, 26, 0.7);
    --shade-ultra-dark-trans-80: rgba(26, 26, 26, 0.8);
    --shade-ultra-dark-trans-90: rgba(26, 26, 26, 0.9);
    --shade-hex: #000000;
    --shade-hsl: 0 0% 0%;
    --shade-rgb: 0 0 0;
    --shade-r: 0;
    --shade-g: 0;
    --shade-b: 0;
    --shade-h: 0;
    --shade-s: 0%;
    --shade-l: 0%;
    --shade-hover-h: 0;
    --shade-hover-s: 0%;
    --shade-hover-l: 1.15;
    --shade-ultra-light-h: 0;
    --shade-ultra-light-s: 0%;
    --shade-ultra-light-l: 95%;
    --shade-light-h: 0;
    --shade-light-s: 0%;
    --shade-light-l: 85%;
    --shade-medium-h: 0;
    --shade-medium-s: 0%;
    --shade-medium-l: 50%;
    --shade-dark-h: 0;
    --shade-dark-s: 0%;
    --shade-dark-l: 25%;
    --shade-ultra-dark-h: 0;
    --shade-ultra-dark-s: 0%;
    --shade-ultra-dark-l: 10%;
    --shade-comp-h: 180;
    --white: #fff;
    --black: #000;
    --black-trans-10: rgba(0, 0, 0, 0.1);
    --black-trans-20: rgba(0, 0, 0, 0.2);
    --black-trans-30: rgba(0, 0, 0, 0.3);
    --black-trans-40: rgba(0, 0, 0, 0.4);
    --black-trans-50: rgba(0, 0, 0, 0.5);
    --black-trans-60: rgba(0, 0, 0, 0.6);
    --black-trans-70: rgba(0, 0, 0, 0.7);
    --black-trans-80: rgba(0, 0, 0, 0.8);
    --black-trans-90: rgba(0, 0, 0, 0.9);
    --white-trans-10: rgba(255, 255, 255, 0.1);
    --white-trans-20: rgba(255, 255, 255, 0.2);
    --white-trans-30: rgba(255, 255, 255, 0.3);
    --white-trans-40: rgba(255, 255, 255, 0.4);
    --white-trans-50: rgba(255, 255, 255, 0.5);
    --white-trans-60: rgba(255, 255, 255, 0.6);
    --white-trans-70: rgba(255, 255, 255, 0.7);
    --white-trans-80: rgba(255, 255, 255, 0.8);
    --white-trans-90: rgba(255, 255, 255, 0.9);
    --shade-white: #fff;
    --shade-black: #000;
    --white: #fff;
    --black: #000;
    --success: #29A745;
    --success-light: #c1f0cc;
    --success-dark: #0f3e19;
    --success-hover: #2fc24f;
    --danger: #DC3545;
    --danger-light: #f4bec3;
    --danger-dark: #410b11;
    --danger-hover: #e35a67;
    --warning: #FFC10A;
    --warning-light: #ffecb3;
    --warning-dark: #4d3900;
    --warning-hover: #ffcc32;
    --info: #18A2B8;
    --info-light: #bbeef6;
    --info-dark: #093c44;
    --info-hover: #1cbcd5;
    --text-xs: 1.3rem;
    --text-s: 1.4rem;
    --text-m: 1.8rem;
    --text-l: 2rem;
    --text-xl: 2.3rem;
    --text-xxl: 2.6rem;
    --root-font-size: 62.5%;
    --h6: 1.3rem;
    --h5: 1.4rem;
    --h4: 1.8rem;
    --h3: 2rem;
    --h2: 2.3rem;
    --h1: 2.6rem;
    --space-xs: 1.896rem;
    --space-s: 2.133rem;
    --space-m: 2.4rem;
    --space-l: 2.7rem;
    --space-xl: 3.038rem;
    --space-xxl: 3.42rem;
    --section-space-xs: 3.84rem;
    --section-space-s: 4.8rem;
    --section-space-m: 6rem;
    --section-space-l: 7.5rem;
    --section-space-xl: 9.375rem;
    --section-space-xxl: 11.719rem;
    --width-xs: calc((144 * 0.1) * 1rem);
    --width-s: calc((144 * 0.2) * 1rem);
    --width-m: calc((144 * 0.4) * 1rem);
    --width-l: calc((144 * 0.6) * 1rem);
    --width-xl: calc((144 * 0.8) * 1rem);
    --width-xxl: calc((144 * 0.9) * 1rem);
    --width-vp-max: 144rem;
    --width-content: 144rem;
    --width-50: calc((144 / 2) * 1rem);
    --width-full: 100%;
    --content-width: var(--width-content);
    --content-width-safe: min(var(--width-content), calc(100% - var(--section-padding-x) * 2));
    --radius-xs: 0.4444444444rem;
    --radius-s: 0.6666666667rem;
    --radius-m: 1rem;
    --radius-l: 1.5rem;
    --radius-xl: 2.25rem;
    --radius-xxl: 3.375rem;
    --radius-50: 50%;
    --radius-circle: 50%;
    --grid-1: repeat(1, minmax(0, 1fr));
    --grid-2: repeat(2, minmax(0, 1fr));
    --grid-3: repeat(3, minmax(0, 1fr));
    --grid-4: repeat(4, minmax(0, 1fr));
    --grid-5: repeat(5, minmax(0, 1fr));
    --grid-6: repeat(6, minmax(0, 1fr));
    --grid-7: repeat(7, minmax(0, 1fr));
    --grid-8: repeat(8, minmax(0, 1fr));
    --grid-9: repeat(9, minmax(0, 1fr));
    --grid-10: repeat(10, minmax(0, 1fr));
    --grid-11: repeat(11, minmax(0, 1fr));
    --grid-12: repeat(12, minmax(0, 1fr));
    --grid-1-2: minmax(0, 1fr) minmax(0, 2fr);
    --grid-1-3: minmax(0, 1fr) minmax(0, 3fr);
    --grid-2-1: minmax(0, 2fr) minmax(0, 1fr);
    --grid-2-3: minmax(0, 2fr) minmax(0, 3fr);
    --grid-3-1: minmax(0, 3fr) minmax(0, 1fr);
    --grid-3-2: minmax(0, 3fr) minmax(0, 2fr);
    --grid-auto-2: repeat(auto-fit, minmax(min(46.1538461538rem, 100vw - 6rem), 1fr));
    --grid-auto-3: repeat(auto-fit, minmax(min(34.5864661654rem, 100vw - 6rem), 1fr));
    --grid-auto-4: repeat(auto-fit, minmax(min(27.6553106212rem, 100vw - 6rem), 1fr));
    --grid-auto-5: repeat(auto-fit, minmax(min(23.0383973289rem, 100vw - 6rem), 1fr));
    --grid-auto-6: repeat(auto-fit, minmax(min(19.7424892704rem, 100vw - 6rem), 1fr));
    --content-gap: var(--space-m);
    --fr-content-gap: var(--space-m);
    --container-gap: var(--space-xl);
    --fr-container-gap: var(--space-xl);
    --grid-gap: var(--space-m);
    --fr-grid-gap: var(--space-m);
    --section-padding-x: 2.4rem;
    --section-gutter: 2.4rem;
    --btn-border-size: 0.25rem;
    --outline-btn-border-size: 0.25rem;
    --btn-pad-y: 0.75em;
    --btn-pad-x: 1.5em;
    --btn-radius: 0;
    --btn-text-transform: none;
    --btn-text-style: normal;
    --btn-letter-spacing: 0;
    --btn-line-height: 1;
    --btn-text-decoration: none;
    --btn-text-color: var(--action-ultra-light);
    --btn-hover-text-color: var(--action-ultra-light);
    --btn-weight: 400;
    --btn-width: 20rem;
    --success-hex: #29A745;
    --success-rgb: 41 167 69;
    --success-hsl: 133 61% 41%;
    --success-h: 133;
    --success-s: 61%;
    --success-l: 41%;
    --success-r: 41;
    --success-g: 167;
    --success-b: 69;
    --danger-hex: #DC3545;
    --danger-rgb: 220 53 69;
    --danger-hsl: 354 70% 54%;
    --danger-h: 354;
    --danger-s: 70%;
    --danger-l: 54%;
    --danger-r: 220;
    --danger-g: 53;
    --danger-b: 69;
    --warning-hex: #FFC10A;
    --warning-rgb: 255 193 10;
    --warning-hsl: 45 100% 52%;
    --warning-h: 45;
    --warning-s: 100%;
    --warning-l: 52%;
    --warning-r: 255;
    --warning-g: 193;
    --warning-b: 10;
    --info-hex: #18A2B8;
    --info-rgb: 24 162 184;
    --info-hsl: 188 77% 41%;
    --info-h: 188;
    --info-s: 77%;
    --info-l: 41%;
    --info-r: 24;
    --info-g: 162;
    --info-b: 184;
    --header-height: 0rem;
    --offset: 0rem;
    --paragraph-spacing: 1em;
    --heading-spacing: 1.5em;
    --focus-color: var(--action);
    --focus-width: 0.2rem;
    --focus-offset: 0.25em;
    --h1-lh: 1.2;
    --h2-lh: 1.2;
    --h3-lh: 1.2;
    --h4-lh: 1.2;
    --h5-lh: 1.2;
    --h6-lh: 1.2;
    --h1-length: ;
    --h2-length: ;
    --h3-length: ;
    --h4-length: ;
    --h5-length: ;
    --h6-length: ;
    --text-xxl-lh: 1.5;
    --text-xl-lh: 1.5;
    --text-l-lh: 1.5;
    --text-m-lh: 1.5;
    --text-s-lh: 1.5;
    --text-xs-lh: 1.5;
    --text-xxl-length: ;
    --text-xl-length: ;
    --text-l-length: ;
    --text-m-length: ;
    --text-s-length: ;
    --text-xs-length: ;
    --box-shadow-m: 0 4px 4px -10px rgba(0, 0, 0, 0.04), 0 13px 13px -10px rgba(0, 0, 0, 0.06), 0 40px 60px -10px rgba(0, 0, 0, 0.08);
    --box-shadow-l: 0 2.7px 3.6px -5px rgba(0, 0, 0, 0.04), 0 7.5px 10px -5px rgba(0, 0, 0, 0.06), 0 18px 24.1px -5px rgba(0, 0, 0, 0.08), 0 60px 80px -5px rgba(0, 0, 0, 0.1);
    --box-shadow-xl: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px -5px rgba(0, 0, 0, 0.04), 0 12.5px 10px -5px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px -5px rgba(0, 0, 0, 0.08), 0 41.8px 33.4px -5px rgba(0, 0, 0, 0.1), 0 80px 80px -5px rgba(0, 0, 0, 0.105);
   
  }