.AddButton {
    display: table-cell;
    background-color: var(--color-white);
     color: var(--color-primary);
     padding: var(--spacing-sm) calc(var(--space-xxl) * 1.8);
     font-size: var(--font-size-md);
     border: solid 1px var(--color-primary);
     text-align: center;
     border-radius: var(--border-radius-lg);
     cursor: pointer;
     transition: background-color var(--transition-medium);
     width: max-content;
     font-weight: 500;
   }
   
   .AddButton:hover {
    background-color: var(--color-primary);
     border: solid 1px transparent;
     color: var(--color-white);
   }
   
   .AddButton:disabled {
     background-color: var(--color-text-light);
     cursor: not-allowed;
   }


   @media only screen and (max-width: 768px) {
    .AddButton{
    padding: 1rem calc(var(--space-xs));}
   }
   