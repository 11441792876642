.container {
    display: contents;
}
.load {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container > div {
    background-color: var(--color-white);
    padding: var(--spacing-xs) var(--spacing-md);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    height: 100%;
    overflow-y: scroll;
}
.container > div::-webkit-scrollbar{
    display: none;
}

.name {
    font-weight: 700;
    font-size: 18px;
    margin-top: var(--space-xl);
}

@media only screen and (max-width: 768px) {
    .picRow{
        text-align: center;
    }
}

.uploadLabel{
    color:red;
    font-weight: 500;
    font-size: 14px;
    margin-top: var(--space-xxs);
    cursor: pointer;
}
.save {
    margin-top: var(--space-xl);
}

.accountTypeWrapepr {
    width: fit-content;
}

@media only screen and (min-width: 768px) {
    .save > span {
        display: flex;
        justify-content: flex-end;
    }
    
}