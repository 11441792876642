@import url(../../styles/utility.css);
.auth-layout {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .auth-content {
    position: relative;
    z-index: 2;
    border-radius: var(--border-radius-md);
    background-color: var(--color-white);
    transition: ease-in-out var(--transition-medium);
  box-shadow: var(--shadow-md);
  }
  