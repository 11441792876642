.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: var(--border-radius-lg);
    background-color: var(--color-white);
    justify-content: center;
    margin-top: 2rem;
    padding: var(--spacing-md);
    margin-bottom: calc(var(--space-xxl) * 2.5);
}

.save {
    cursor: pointer;
}
.save:hover {
    color: var(--color-primary--medium);
}
.container > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.container > div > h1 {
    font-size: 16px;
    font-weight: 600;
}

.container > p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: var(--spacing-md);
}
.edit {
    display: flex;
    color: var(--color-primary);
    font-size: 14px;
    gap: var(--spacing-sm);
}
.load {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: var(--space-xxl);
    width: 100%;
}

.proposalInput {
    width: 100%;
    height: 100%;
    min-height: 300px;
    border: none;
    font-family: monospace;
}