.name {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: var(--space-xs);
}

.container {
  padding: var(--space-m) 0;
}

h2 {
  margin-bottom: 20px;
}

.form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input, .select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius-md);
  width: 200px;
  background-color: var(--color-white);
}

.addButton, .saveButton {
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius-md);
  background-color: var(--color-primary);
  color:  var(--color-white);
  cursor: pointer;
  transition: background-color 0.3s;
}

.addButton:hover, .saveButton:hover {
  background-color: var(--color-primary);
}

.teamList {
  margin-top: 20px;
}

.teamMember {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
