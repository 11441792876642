.project-sidebar, .project-layout {
    display: flex;
}

.project-layout {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.project-content {
    padding: var(--space-xs);
    overflow-y: scroll;
    width:100%;
}

.project-content::-webkit-scrollbar {
    display: none;
}