.project {
    border-bottom: 0.5px solid rgba(222, 222, 222, 1);
  
    transition: all 0.3s;
  }
  .project:hover {
    background-color: rgba(245, 245, 245, 1);
  }
  
  .project:last-child {
    border-bottom: none;
  }
  

.title{
    font-size: 14px;
    color: var(--color-text);
      cursor: pointer;
      transition: ease 0.2s;
}
.title:hover{
  font-weight: 600;
}
.cost, .time-line, .creation-date  {
    color: rgba(131, 146, 171, 1);
    font-size: 12px;
    font-weight: 700;
}


.actions {
    display: flex;
    gap: var(--spacing-sm);
}
