.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .scoreContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  
  


.circleBg {
    stroke: #e6e6e6;
    /* transform: rotate(-90deg); */
    transform-origin: 50% 50%;
  }
  
  .circle {
    stroke: #ffa726;
    /* transform: rotate(-90deg); */
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.5s ease;
  }
  
  .scoreText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .label {
    font-size: 10px;
    color: #666;
  }
  
  .score {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }