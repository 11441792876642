.container {
        padding: 20px;
    border: 1px dashed var(--color-primary);
    border-style: dashed;
    display: table-cell;
    border-radius: 8px;
    width: 100%;
    margin: auto;
    margin-bottom: var(--space-m);
    text-align: center;
  }
  
  .title-bold {
    margin-bottom: 10px;
    font-size: 16px;
    color: var(--text-color);
  }
  .title {
    margin-bottom: 20px;
    font-size: 12px;
    color: #666;
  }
  
  .fileInput {
    display: block;
    margin: 0 auto 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .uploadButton {
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: var(--border-radius-lg);
    transition: all 0.3s;
    cursor: pointer;
    border: solid 1px transparent;
  }
  
  .uploadButton:hover {
    background-color: var(--color-primary--light);
    color: black;
    border: solid 1px var(--color-primary);
  }
  