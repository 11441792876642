.menuItem {
    display: flex;
    align-items: top;
    width: auto;
    padding: var(--spacing-md) var(--spacing-md);
    text-decoration: none;
    color: var(--color-text-light);
    font-weight: 600;
    gap: 4px;
    border-radius: 16px;
    transition: color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
                background-color  0.2s ease-out,
                border 0.8s ease-in-out 0.6s;
                align-items: center;
}
.menuItem > span {
    display: contents;
    
}
.menuItem > span >svg{
    padding: var(--spacing-xs);
    transition: ease 0.3s;
    border-radius: var(--border-radius-md);
}
.active {
    color: var(--color-text);
    background-color: var(--white);
    width: auto;
    padding: var(--spacing-md) var(--spacing-md);
    align-items: center;
}
.active > span{
    display: contents;
}
.active > span > svg  {
    background-color: var(--color-primary--medium);
    padding: var(--spacing-xs);
    border-radius: var(--border-radius-md); ;
    
}
.active > span > svg > path {
    background-color: var(--color-primary);
    stroke: var(--white)!important;
}
.active.menuItem:first-child > span > svg > path {
    fill: var(--white)!important;
    stroke: var(--white)!important;
}

.menuItem:first-child:hover > span > svg  {
    background-color: var(--color-primary--medium);
    padding: var(--spacing-xs);
    border-radius: var(--border-radius-md); 
}
.menuItem:hover > span > svg > path {
    background-color: var(--color-primary);
    stroke: var(--white)!important;
}
.menuItem:hover > span > svg {
    background-color: var(--color-primary--medium);
    padding: var(--spacing-xs);
    border-radius: var(--border-radius-md); 
}
.menuItem:hover {
    color: var(--color-text);
    border: solid 1px var(--accent-color);
    background-color: var(--white);
    width: auto;
    align-items: center;
}
.icon {
    margin-right: 4px; 
}

.title {
    font-weight: 400;
}


