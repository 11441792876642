.elementProperties {
    padding: 16px;
    border-radius: 8px;
    background: #fff;
  }
  
  .elementProperties h3 {
    margin-top: 0;
  }
  
  .propertyGroup {
    margin-bottom: 16px;
  }
  
  .propertyGroup label {
    display: block;
    margin-bottom: 8px;
  }
  
  .propertyGroup input, .propertyGroup select {
    width: -webkit-fill-available;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: var(--color-primary--medium);
  }
  